import React, { Component } from "react";
import { Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import "./styles/styles.css";
import "./styles/signin.css";
import { userService } from "../services/user.service";
import logoImage from "./images/thisheart_group.svg";
import signInImage from "./images/signin.jpg";
import arrowRight from "./images/arrow-right.svg";
import arrowUpRight from "./images/arrow-up-right.svg";
import { Helmet } from "react-helmet";
import { encrypt_url } from "../helpers/encrypt-url";

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      submitted: false,
      loading: false,
      loginFailed: false,
      errorMessage: "",
      statusCode: "",
      btnActive: false,
      encryptedString: encrypt_url(),
      errorEmail:'',
      errorPassword:''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem('reg_email')
    localStorage.removeItem('reg_process')

    if (localStorage.getItem("user") &&
      localStorage.getItem("twofa") &&
      localStorage.getItem("select_package") !== "yes") {
      this.props.history.push(`/dashboard/${this.state.encryptedString}`);
    }
    window.scrollTo(0, 0);
  }

  handleChange(e) {
    const { name, value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(value && name=="email"){
      this.setState({
        errorEmail:''
      })
    }
    if(value && name=="password"){
      this.setState({
        errorPassword:''
      })
    }


    this.setState(prevState => {
      const updatedState = { ...prevState, [name]: value, submitted: false };
      const isEmailValid = emailRegex.test(updatedState.email);
      const isPasswordValid = updatedState.password.length >= 8;

      updatedState.btnActive = isEmailValid && isPasswordValid;

      return updatedState;
    });
  }

 

  handleSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;
     
    if (email.length<1) {
      this.setState({
        errorEmail:'Email is required'
      })
      return
    }

    if (password.length<8) { 
      this.setState({
        errorPassword:'Password is required'
      })
      return
    } 
 
    this.setState({
      submitted: true,
      loginFailed: false,
      statusCode: "",
      errorMessage: "",
      btnActive: false,
    });

   

    if (password.length < 8) {
      return;
    }

    if (email && password) {
      this.setState({ loading: true });
      userService.login(email, password, this);
    }
    console.log("user submit the login request");
  }
 

  userValidation = () =>{
    console.log('email::', 'email not found')
    if (this.state.submitted && !this.state.email) {
      return <div className="help-block-signin">Email is required</div>;
    }
  }

  passwordValidation() {
    if (this.state.submitted && !this.state.password) {
      return <div className="help-block-signin">Password is required</div>;
    } else if (this.state.submitted && this.state.password.length < 8) {
      return (
        <div className="help-block-signin">
          Sorry, that didn’t work. Please try again
        </div>
      );
    }
    return <div className="help-block-signin" />;
  }

  otherValidation = () => {
    if (
      this.state.submitted &&
      this.state.loginFailed &&
      this.state.statusCode === 401
    ) {
      return <div className="help-block-signin">{this.state.errorMessage}</div>;
    } else if (
      this.state.submitted &&
      this.state.loginFailed &&
      this.state.statusCode === 422
    ) {
      return <div className="help-block-signin">{this.state.errorMessage}</div>;
    }

    return <div className="help-block-signin" />;
  };

  redirectImgLink = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="container-fluid">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-Login</title>
          <link rel="login" href="/login" />
        </Helmet>
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-3 logo mt-1">
              <img
                src={logoImage}
                onClick={this.redirectImgLink}
                alt="logo"
                className="img-responsive"
                style={{ cursor: "pointer" }}
              ></img>
            </div>
 
          </div>{/**** end top row ****/}

          {/**** signin part ****/}
          <div className="row align-items-center"  >
            <div className="col-md-2"></div>
            <div className="col-md-8 col-md-pull-3">
              <div className="row overflow-hidden" style={{borderRadius:'15px',overFlow:'hidden'}}>
                  <div className="col-lg-6 p-0 bg-signinImage">
                  <img
                    src={signInImage}
                    className="img-fluid mx-auto d-block v-100 tab-hide"
                    alt="signin"
                    style={{height:'100%'}}
                  />
                </div>
                <div className="col-lg-6 bg-thisheart p-4 tab-mt-3">
                  <Form onSubmit={this.handleSubmit} method="post">
                  <h3 className="mt-2 sm-hide">HELLO,</h3>
                  <h3><strong>WELCOME!</strong></h3>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <label>Email Address</label>
                    </div>
                    <div className="col">
                      <Input  className="form-control border border-dark rounded-lg" type="email" name="email" id="username" placeholder="User Email" onChange={this.handleChange} autoComplete="on" />
                      
                        <div className="text-danger mt-1 small font-weight-bold">{this.state.errorEmail}</div>
                        <div className="help-block-signin">
                          {this.state.errorMessage &&
                            this.state.errorMessage.user_type
                            ? this.state.errorMessage.user_type
                            : ""}
                       
                        {/* {this.userValidation()} */}
                        {/* {this.passwordValidation()} */}
 
                      </div>
                    </div> {/** end col **/}

                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label>Password</label>
                    </div>
                    <div className="col">
                      <Input className="form-control border border-dark rounded-lg"  type="password"  name="password" id="password" placeholder="Password"  onChange={this.handleChange} autoComplete="on"  />
                      <div className="text-danger mt-1 small font-weight-bold">{this.state.errorPassword}</div>
                      <div>
                        <Link className="pas-forgot" to="/reset/pass">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </div>{/** end row**/}
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <button
                          className="btn btn-darked btn-block"
                          
                          type="submit"
                          onClick={this.handleLogin}
                        >
                        Log in
                        {this.state.loading && (
                          <i className="fa fa-spinner fa-pulse ml-2">&nbsp;</i>
                        )}
                        {!this.state.loading && (
                          <img src={arrowUpRight} className="ml-2"></img>
                        )}
                        
                      </button>
                    </div>
                  </div>{/** end row**/}
                  <p className="text-center mt-3">OR</p>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <Link className="create-accnt btn btn-outline text-center text-dark border-secondary text-decoration-none" to="/register">
                        Create an account
                        <img src={arrowRight} className="ml-2"></img>
                      </Link>
                    </div>
                  </div>{/** end row**/}
                  </Form>
                </div>{/** end col-md-6 **/}
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>

   
        <p></p>
        
      </div>
    );
  }
}

export default SignIn;
