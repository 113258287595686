import React, { Component, useState, useEffect  } from "react";
import lineOfColours from "./images/line_of_colours.png";
import mediaView from "./images/imagePlaceHolder.png";
import { FaCircle, FaPlay, FaMusic, FaPlus, FaMinus } from "react-icons/fa";
import videoView from "./images/videoPlaceHolder.png";
import audioView from "./images/audioPlaceHolder.png";
import letterView from "./images/letter_view.png";
import loaderImg from "./images/loader.gif";
import loaderImage from "./images/loaderImage.gif";
import zeroState from "../pages/images/memzero.png";
import btnDownload from "../pages/images/download.png";
import loadVideoPlay from "../pages/images/loadVideoPlay.png";
import { ToastsStore } from "react-toasts";
import LeftSidebar from "./../components/LeftSidebar";
import { connect } from 'react-redux'
import { setUploadFile } from '../redux/uploadFile/uploadFile.actions'
import UploadProgress from '../components/UploadProgress/UploadProgress'
import {
  FaSearch, FaEye,
  FaFacebook,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "./styles/memories.css";
import "../components/asterisk.css";
import { memoriesService } from "../services/memories.service";
import { Helmet } from "react-helmet";

import apiService from "./../services/api.service";
import socialService from "./../services/social.service";
import Header from "./../components/Header";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import FacebookAuth from "react-facebook-auth";

// import imageCompression from "browser-image-compression";
// import { authHeader } from "../helpers/auth-header";
// import Jimp from "@jimp/core";
// import Resizer from "react-image-file-resizer";

import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Modal, ModalBody, ModalHeader, ModalFooter, Media, Col, Row, Input, Label, Form, FormGroup, Table } from "reactstrap";
import classnames from "classnames";
import YouTube from 'react-youtube';
import "./styles/dashboardStyle.min.css"
// import VideoThumbnail from 'react-video-thumbnail';  

pdfMake.vfs = pdfFonts.pdfMake.vfs;
var docDefinition = {
  info: {
    title: "ThisHeart@Team",
    author: "ThisHeart",
    subject: "ThisHeart Letter",
    keywords: "keywords for document",
  },
  content: "",
};
const apiUrl = apiService.apiUrl;
const imgPath = apiService.apiUrl;
const userAgent = window.navigator.vendor;
let browserCSS = {width:'100%'}

if(userAgent=="Apple Computer, Inc."){
  browserCSS = {width:'30% !important'}
}

class Memories extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.toggle = this.toggle.bind(this);
    this.toggleTab = this.toggleTab.bind(this);

    this.togglePhotoButton = this.togglePhotoButton.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.toggleDeleteImg = this.toggleDeleteImg.bind(this);
    this.deleteImages = this.deleteImages.bind(this);

    this.toggleVideoButton = this.toggleVideoButton.bind(this);
    this.handleVideoUpload = this.handleVideoUpload.bind(this);
    this.toggleDeleteVideo = this.toggleDeleteVideo.bind(this);
    this.deleteVideos = this.deleteVideos.bind(this);

    this.toggleLetterButton = this.toggleLetterButton.bind(this);
    this.toggleLetterViewButton = this.toggleLetterViewButton.bind(this);
    this.toggleLetterEditButton = this.toggleLetterEditButton.bind(this);
    this.toggleDeleteLetter = this.toggleDeleteLetter.bind(this);
    this.deleteLetter = this.deleteLetter.bind(this);

    this.toggleRecordButton = this.toggleRecordButton.bind(this);
    this.toggleDeleteRecord = this.toggleDeleteRecord.bind(this);
    this.deleteRecords = this.deleteRecords.bind(this);

    this.handleDateImage = this.handleDateImage.bind(this);
    this.fetchFacebook = this.fetchFacebook.bind(this);
    this.fetchInstagram = this.fetchInstagram.bind(this);
    this.fetchTwitter = this.fetchTwitter.bind(this);

    this.state = {
      activeTab: "1",
      dropdownOpen: false,
      modalphoto: false,
      modalletter: false,
      modalUrlVideo: false,
      modalUrlAudio: false,
      urlLinkVideo: false,
      modalVideoPlayer: false,
      modalVideoPlayerType: "",
      modalVideoPlayerUrl: "",
      modalVideoPlayerVideoFile: "",
      urlLinkAudio: false,

      loadingImages: false,
      loadingVideos: false,
      loadingRecords: false,
      loadingSocialData: false,
      uploadingData: false,
      uploadBtnSts: "false",
      btnActive: false,

      validInvalidMessage: "",
      titleMessage: "",
      imageName: "",
      videoName: "",
      recordName: "",
      // selectedImage: [],
      selectedImage: null,
      selectedImageMultiple: [],
      selectedVideo: [],
      uploadVideoFiles: [],
      selectedRecord: [],

      submitted: false,
      loading: false,
      loadingLetters: false,

      errorMessage: "",
      errorSubject: "",
      errorSubjectEdit: "",
      errorDescription: "",
      errorDescriptionEdit: "",
      errorFileVideo: "",
      errorFileAudio: "",
      statusCode: "",
      subject: "",
      description: "",
      updateIndex: {
        in: "",
      },

      imageLists: [],
      imgPathModal: '',
      imageOpen: false,
      videoLists: [],
      showModal: false,
      currentVideo: null,
      linkVideoList: [],
      recordLists: [],
      linkRecordList: [],
      urlLink: "",
      urlValidItem: "",
      errorUrl: "",
      letterListLocal: [],
      letterListTemp: [],

      editorState: EditorState.createEmpty(),
      editorEditState: "",
      socialImageLists: [],
      socialVideoList: [],
      deleteID: 0,

      imgPath: 0,
      imagePathSocial: "",
      containerWithOverflow: false,
      effect: "blur",
      showLowResImages: true,
      threshold: 100,
      userObj: JSON.parse(user),
      userType: localStorage.getItem("user_type"),
      socialImageError: "",
      editIndex: "",
      editID: "",
      editSubject: "",
      editDescription: "",
      hasMoreImg: true,
      loadingImageCounter: 12,
      hasMoreVideo: true,
      loadingVideoCounter: 4,
      hasMoreLetter: true,
      loadingLetterCounter: 4,
      hasMoreRecord: true,
      loadingRecordCounter: 4,
      activeLetterSearch: "",
      filesType: "",
      user_id: "",
      beneficiary_id: "",
      primary_user_id: "",

      imageLists: [], // your initial image list
      screenWidth: window.innerWidth,
      maxImagesToShow: window.innerWidth < 992 ? 2 : 4,

      videoLists: [], // your initial video list
      maxVideosToShow: window.innerWidth < 992 ? 2 : 4,
      showModal: false,
      currentVideo: null,

      recordLists: [], // your initial audio list
      maxAudiosToShow: window.innerWidth < 992 ? 2 : 4,

      letterLists: [], // your initial Letter list
      maxLetterToShow: window.innerWidth < 992 ? 2 : 4,
      selectedFiles: '',
      imageTitle: '',
      videoTitle: '',
      recordTitle: ''
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }

    this.setState({
      user_id: this.state.userObj.data.user_id,
      beneficiary_id: "",
      primary_user_id: this.state.userObj.data.primary_user_id
    })
 
    // let actionSocial = this.props.match.params.actions;

    const query = new URLSearchParams(this.props.location.search);
    const actions = query.get("actions");
    if (actions === "social") {
      this.setState({
        activeTab: "5",
      });
      this.toggleTab("5");
    }
    if (actions === "image") {
      this.setState({
        activeTab: "1",
      });
      this.toggleTab("1");
    }
    if (actions === "video") {
      this.setState({
        activeTab: "2",
      });
      this.toggleTab("2");
    }
    if (actions === "letters") {
      this.setState({
        activeTab: "4",
      });
      this.toggleTab("4");
    }
    if (actions === "record") {
      this.setState({
        activeTab: "5",
      });
      this.toggleTab("5");
    }

    // memoriesService.getImageData(userId, this);
    // memoriesService.getVideoData(userId, this);
    memoriesService.getLetterData(userId, this);
    // memoriesService.getRecordData(userId, this);
    memoriesService.getAllMemoriesData(this);

    //socialService.viewPhotos(this);
  }

  MyFacebookButton = ({ onClick }) => (
    <button
      onClick={onClick}
      className="m-0 btn-block fb-btn"
      style={{
        border: "0px",
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: '#a40cdb',
        color: 'white',
        borderRadius: "8px",
        width: 'auto',
        height: '32px',

      }}
    >
      <FaFacebook className="mr-2" />
      Login facebook
    </button>
  );

  authenticate = (response) => {
    socialService.getFbPhoto(this, response);
  };

  handleError = (error) => {
    this.setState({ error });
  };

  fetchFacebook = () => {
    socialService.initFB(this);
  };

  fetchInstagram = () => {
    this.setState({
      loadingSocialData: true,
    });
    socialService.fetchInstagram(this);
  };

  fetchTwitter = () => {
    this.setState({
      loadingSocialData: true,
    });
    socialService.fetchTwitter(this);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  urlToggleVideo = () => {
    this.setState((prevState) => ({
      urlLinkVideo: !prevState.urlLinkVideo,
    }));
  };

  toggleVideoPlayer = (videoFile) => {
    let videoFilePath = ""
    if (videoFile.urlcheck === "videoLink") {
      let fileWatch = videoFile.filename ? videoFile.filename.split("watch?v=") : null;
      let fileEmbed = videoFile.filename ? videoFile.filename.split("embed/") : null;
      let fileYoutu = videoFile.filename ? videoFile.filename.split("youtu.be/") : null;
      let fileShorts = videoFile.filename ? videoFile.filename.split("shorts/") : null;

      if (fileWatch && fileWatch[1]) {
        videoFilePath = fileWatch[1];
      }
      if (fileEmbed && fileEmbed[1]) {
        videoFilePath = fileEmbed[1];
      }
      if (fileYoutu && fileYoutu[1]) {
        videoFilePath = fileYoutu[1];
      }
      if (fileShorts && fileShorts[1]) {
        videoFilePath = fileShorts[1];
      }
    } else {
      videoFilePath = videoFile.filename
    }

    this.setState((prevState) => ({
      modalVideoPlayer: !prevState.modalVideoPlayer,
      modalVideoPlayerType: videoFile.urlcheck,
      modalVideoPlayerUrl: videoFilePath,
      modalVideoPlayerVideoFile: videoFile,
    }));
  };

  validateUrl = (e) => {
    let url = e.target.value;
    //var re = /^(?:http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    let youtubeUrl = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w]+\?v=|embed\/|v\/)?)([\w]+)(\S+)?$/;

    if (!youtubeUrl.test(url)) {
      return false;
    } else {
      return true;
    }
  };

  onChangeUrlVideo = (e) => {
    if (this.validateUrl(e)) {
      this.setState({
        urlLink: e.target.value,
        errorUrl: "",
      });
    } else {
      this.setState({
        errorUrl: "Please enter valid url!",
      });
    }
    if (e.target.value === "") {
      this.setState({
        errorUrl: "",
      });
    }

  };

  togglePhotoButton() {
    this.setState((prevState) => ({
      modalphoto: !prevState.modalphoto,
      uploadingData: false,
      validInvalidMessage: null,
      uploadBtnSts: "false",
      imageName: "",
    }));
  }

  toggleProfilePhoto = () => {
    this.setState((prevState) => ({
      profilePhoto: !prevState.profilePhoto,
    }));
  };

  toggleVideoButton() {
    this.setState((prevState) => ({
      modalvideo: !prevState.modalvideo,
      uploadingData: false,
      validInvalidMessage: null,
      uploadBtnSts: "false",
      videoName: "",
    }));
  }

  toggleLetterButton() {
    this.setState((prevState) => ({
      modalletter: !prevState.modalletter,
    }));
  }

  toggleLetterViewButton() {
    this.setState((prevState) => ({
      preview: !prevState.preview,
    }));
  }

  toggleLetterEditButton() {
    this.setState((prevState) => ({
      editview: !prevState.editview,
      btnActive: false,
    }));
  }

  toggleRecordButton() {
    this.setState((prevState) => ({
      modalrecord: !prevState.modalrecord,
      uploadingData: false,
      validInvalidMessage: null,
      uploadBtnSts: "false",
      recordName: "",
    }));
  }

  toggleDeleteImg(deleteID) {
    this.setState((prevState) => ({
      deleteImg: !prevState.deleteImg,
      deleteID: deleteID,
    }));
  }

  toggleDeleteMemoryImg = () => {
    this.setState({ deleteImg: !this.state.deleteImg });

  };

  handleDeleteClick = (image) => {
    this.setState({ deleteImg: true, imageToDelete: image });
  };

  imageIdDisplay = (image) => {
    this.setState({ imageId: image.id });
    return image.id;
  };

  deleteMemoriesImages = () => {
    const { imageToDelete } = this.state;
    if (imageToDelete) {
      this.deleteImage(imageToDelete.id).then(() => {
        console.log("Image deleted successfully " + imageToDelete.id);
        this.setState({
          imageLists: this.state.imageLists.filter(img => img.id !== imageToDelete.id),
          deleteImg: false,
          imageToDelete: null,
        });
      });
    }
  };

  toggleDeleteVideo(deleteID) {
    this.setState((prevState) => ({
      deleteVid: !prevState.deleteVid,
      deleteID: deleteID,
    }));
  }

  toggleDeleteLetter(deleteID) {
    this.setState((prevState) => ({
      deleteLetter: !prevState.deleteLetter,
      deleteID: deleteID,
    }));
  }

  toggleDeleteRecord(deleteID) {
    this.setState((prevState) => ({
      deleteRecord: !prevState.deleteRecord,
      deleteID: deleteID,
    }));
  }

  toggleDeleteImgSocial = (deleteID) => {
    this.setState((prevState) => ({
      socialImgDel: !prevState.socialImgDel,
      deleteID: deleteID,
    }));
  };

  handleShowDialog = (imgObj) => {
    this.setState({
      imageOpen: !this.state.imageOpen,
      imgPath: apiUrl + "/" + imgObj.filename,
    });
  };

  handleSocialShowDialog = (imgObj) => {
    this.setState({
      imageOpen: !this.state.imageOpen,
      imgPath: imgObj.image_url,
    });
  };

  popImageView = () => {
    return (
      <div className="img-effect">
        <img src={this.state.imgPath} className="imageView" alt=""></img>
      </div>
    );
  };

  finished_rendering = () => {
    var spinner = document.getElementById("spinner");
    spinner.removeAttribute("style");
    spinner.removeChild(spinner.childNodes[0]);
  };

  hiddenSocialBtn = () => {
    if (this.state.userType === "bn") {
      return false;
    } else {
      return (
        <Row className="social-buttons-row row align-items-center">
          <FacebookAuth
            className="btn"
            appId="349211774629753"
            callback={this.authenticate}
            component={this.MyFacebookButton}
            isMobile={false}
            rerequest={true}
            xfbml={true}
            fields="id,name,email,albums{name,count,photos{name,name_tags,images}},photos"
            scope="user_photos,public_profile"
          />
          <Button
            className="btn"
            onClick={this.fetchInstagram}
            style={{
              backgroundColor: '#f6dc20',
              borderRadius: "8px",
              marginBottom: "5px",
              border: "0px",
            }}
          >
            <FaInstagram className="mr-2" />
            Fetch Instagram
          </Button>
          <Button
            className="btn"
            onClick={this.fetchTwitter}
            style={{
              backgroundColor: '#f0f0f0',
              borderRadius: "8px",
              marginTop: "0px",
              marginLeft: "0px",
              marginBottom: "0px",
              border: "0px",
            }}
          >
            <FaTwitter className="mr-2" />
            Fetch Twitter
          </Button>
        </Row>
      );
    }
  };

  // Function to reload the page
  reloadPage = () => {
    window.location.reload();
  };

  // memories-image
  showViewMorePhotos = () => {

      return (
        <Button href="/memories-images" className="d-flex btn bg-white text-dark view-more-btn" onClick={this.reloadPage}>
          View More <i className="fas fa-eye d-md-block d-none ml-1 my-auto"></i>
        </Button>
      );
    
  };

  showAddNewBtnPhoto = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="d-flex btn add-btn-mem add-btn" onClick={this.togglePhotoButton}>
          Add Photo <i className="fas fa-plus-circle d-md-block d-none my-auto ml-1"></i>
        </Button>
      );
    }
  };
  // memories-videos
  showViewMoreVideos = () => {

      return (
        <Button href="/memories-videos" className="d-flex btn bg-white text-dark view-more-btn" onClick={this.reloadPage}>
          View More <i className="fas fa-eye d-md-block d-none ml-1 my-auto"></i>
        </Button>
      );
    
  };

  addNewVideo = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="d-flex btn add-btn-mem add-btn" onClick={this.toggleVideoButton}>
          Add Video <i className="fas fa-plus-circle d-md-block d-none my-auto ml-1"></i>
        </Button>
      );
    }
  };
  //memories-audios
  showViewMoreAudios = () => {

      return (
        <Button href="/memories-audios" className="d-flex  btn bg-white text-dark view-more-btn" onClick={this.reloadPage}>
          View More <i className="fas fa-eye d-md-block d-none ml-1 my-auto"></i>
        </Button>
      );
    
  };

  addNewAudioRecord = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="d-flex btn add-btn-mem add-btn" onClick={this.toggleRecordButton}>
          Add Recordings <i className="fas fa-plus-circle d-md-block d-none my-auto ml-1"></i>
        </Button>
      );
    }
  };

  // memories-letters
  showViewMoreLetters = () => {

      return (
        <Button href="/memories-letters" className="d-flex btn bg-white text-dark view-more-btn" onClick={this.reloadPage}>
          View More <i className="fas fa-eye d-md-block d-none ml-1 my-auto"></i>
        </Button>
      );
    
  };

  addNewLetters = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="d-flex btn add-btn-mem add-btn" onClick={this.toggleLetterButton}>
          Add Letters <i className="fas fa-plus-circle d-md-block d-none my-auto ml-1"></i>
        </Button>
      );
    }
  };

  showAddNewBtnVideo = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn-add-videos" onClick={this.toggleVideoButton}>
          +Add New Videos
        </Button>
      );
    }
  };

  showAddNewBtnLetter = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button
          className="btn-add-new-letter"
          onClick={this.toggleLetterButton}
        >
          +Add New Letter
        </Button>
      );
    }
  };

  showAddNewBtnRecord = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn-add-new" onClick={this.toggleRecordButton}>
          +Add New Record
        </Button>
      );
    }
  };

  /*****************************************   Memories Page Image Option Start   *****************************/

  loadMoreImageContent = () => {
    setTimeout(() => {
      if (this.state.loadingImageCounter < this.state.imageLists.length) {
        let imageCounter = this.state.loadingImageCounter + 4;
        this.setState({ loadingImageCounter: imageCounter });
      }
      if (this.state.loadingImageCounter >= this.state.imageLists.length) {
        this.setState({ hasMoreImg: false });
      }
    }, 1000);
  };

  onDemandContentImages = () => {
    return (
      <div className="col-md-12 col-sm-12 left-image">
        <InfiniteScroll
          dataLength={this.state.loadingImageCounter}
          next={this.loadMoreImageContent.bind(this)}
          hasMore={this.state.hasMoreImg}
          className="row"
        >
          {this.showImages()}
        </InfiniteScroll>
      </div>
    );
  };

  showImages = (scrollPosition) => {
    let domObject = [];
    let tempImageList = [];
    let imgCounter = 0;

    this.state.imageLists.map((item) => {
      if (imgCounter <= this.state.loadingImageCounter) {
        tempImageList.push(item);
      }
      imgCounter = imgCounter + 1;

    });

    if (tempImageList.length !== 0) {
      if (this.state.userType === "bn") {
        domObject = tempImageList.map((item, i) => (
          <div key={i} className="col-md-3 col-sm-6 col-lg-3 image-grid">
            <LazyLoadImage
              alt={item.filename}
              className="image-set img-responsive img-thumbnail"
              key={i}
              scrollPosition={scrollPosition}
              src={imgPath + "/" + item.thumbnail_url}
              placeholderSrc={loaderImage}
              threshold={this.state.threshold}
              style={{ borderRadius: '15px' }}
            >
              <div className="middle">
                <button
                  //className="viewImage"
                  className={
                    this.state.userType === "bn" ? "bn-imagepos" : "viewImage"
                  }
                  onClick={this.handleShowDialog.bind(this, item)}
                >
                  <FaEye />
                </button>
              </div>
            </LazyLoadImage>
          </div>
        ));
      } else {
        domObject = tempImageList.map((item, i) => (
          <div key={i} className="col-md-3 col-sm-6 col-lg-3 image-grid">
            <LazyLoadImage
              alt={item.filename}
              className="img-responsive image-set border"
              //effect={this.state.effect}
              key={i}
              scrollPosition={scrollPosition}
              src={imgPath + "/" + item.thumbnail_url}
              placeholderSrc={loaderImage}
              threshold={this.state.threshold}
              style={{ borderRadius: '15px' }}
            />
            <div className="middle">
              {this.state.primary_user_id == item.user_id ? ('') :
                (<button
                  className="textimages"
                  onClick={this.toggleDeleteImg.bind(this, item.id)}
                >
                  X
                </button>)}

              <button
                className={
                  this.state.userType === "bn" ? "bn-imagepos" : "viewImage"
                }
                onClick={this.handleShowDialog.bind(this, item)}
              >
                <FaEye />
              </button>

            </div>
          </div>
        ));
      }
    } else {
      if (this.state.loadingImages) {
        domObject = <p className="empty-msg-mem"> Loading Image Data... </p>;
      } else {
        domObject = (
          <div className="container">
            <div className="row ">
              <img
                className="img-fluid mx-auto d-block"
                src={zeroState}
                alt="love-pat"
                style={{ marginTop: "37px" }}
              />
            </div>
          </div>
        );
      }
    }

    return domObject;
  };

  selectImagesMultiple = (event) => {
    let selectimages = [];
    let validInvalidMessage = "";
    for (var i = 0; i < event.target.files.length; i++) {
      selectimages[i] = event.target.files.item(i);
      const fileSize = selectimages[i].size / 1024;
      if (fileSize > 10240) {
        validInvalidMessage = `Invalid file selected or maximum size of file selected`;
        this.setState({
          validInvalidMessage,
        });
        this.setState({
          uploadBtnSts: "false",
        });
        return 0;
      }
      console.log("file size: ::: ", selectimages[i].size / 1024);
    }

    selectimages = selectimages.filter((image) =>
      image.name.match(/\.(JPG|jpg|jpeg|png|gif)$/)
    );

    // let selectedImage = selectimages[0];
    let selectedImage = selectimages;
    let imageName = selectedImage[0] && selectedImage[0].name.split(".")[0];
    this.setState({
      selectedImage: selectedImage,
      selectedImageMultiple: event.target.files,
      imageName: imageName,
    });
    if (selectimages.length > 0 && selectimages.length < 21) {
      validInvalidMessage = `${selectimages.length} valid images selected`;
      this.setState({
        validInvalidMessage,
      });
      this.setState({
        uploadBtnSts: "true",
      });
    } else {
      validInvalidMessage = `Invalid file selected or maximum quantity of file selected`;
      this.setState({
        validInvalidMessage,
      });
      this.setState({
        uploadBtnSts: "false",
      });
    }
  };

  selectImages = (event) => {
    let selectimages = [];
    let validInvalidMessage = "";
    for (var i = 0; i < event.target.files.length; i++) {
      selectimages[i] = event.target.files.item(i);
      console.log("file size: ::: ", selectimages[i].size);
    }

    selectimages = selectimages.filter((image) =>
      image.name.match(/\.(JPG|jpg|jpeg|png|gif)$/)
    );

    let selectedImage = selectimages[0];
    let imageName = selectedImage.name.split(".")[0];
    this.setState({
      selectedImage,
      imageName: imageName,
    });
    if (selectimages.length > 0) {
      validInvalidMessage = `${selectimages.length} valid images selected`;
      this.setState({
        validInvalidMessage,
      });
      this.setState({
        uploadBtnSts: "true",
      });
    } else {
      validInvalidMessage = `Invalid file selected`;
      this.setState({
        validInvalidMessage,
      });
      this.setState({
        uploadBtnSts: "false",
      });
    }
  };

  handleImageUpload = (e) => {
    e.preventDefault();
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }

    let imageName = this.state.imageName;
    let imageDescription = this.state.imageName;
    // let selectedFile = this.state.selectedImage;
    let selectedImageMultiple = this.state.selectedImageMultiple;

    let self = this;


    memoriesService.storeImageData(
      userId,
      imageName,
      imageDescription,

      selectedImageMultiple,

      self
    );


    this.setState({
      uploadingData: true,
      imageName: "",
    });
    this.loadMoreImageContent();
  };

  handleAttachVideoFile = e => {
    // could do some validation for the attached file here
    const filesType = "video"
    this.setState({
      filesType,
      selectedFiles: e.target.files
    })

    if (!e.target.files || e.target.files.length === 0) {
      this.setState({
        validInvalidMessage: "No file selected. Please select a video file."
      });
      return 0;
    }
    let selectVideo = [];
    let validInvalidMessage = "";
    for (var i = 0; i < e.target.files.length; i++) {
      selectVideo[i] = e.target.files.item(i);
      const fileSize = selectVideo[i].size / 1024;
      if (fileSize > 102400) {
        validInvalidMessage = `Invalid file selected or maximum size of file selected`;
        this.setState({
          validInvalidMessage,
        });
        this.setState({
          uploadBtnSts: "false",
        });
        return 0;
      }
    }
    if (!e.target.files[0]) {
      return 0;
    }

    var ext = /^.+\.([^.]+)$/.exec(e.target.files[0].name);
    let extn = ext == null ? "" : ext[1];
    console.log('extn', extn)
    if (extn === "mp4" || extn === "avi" || extn === "mov" || extn === "wmv") {
      this.setState({
        validInvalidMessage: ""
      })
    } else {
      this.setState({
        validInvalidMessage: "File type isn't allowed"
      })
      return 0;
    }

    //this.props.setUploadFile(e.target.files, filesType)
    //e.target.value = '' // to clear the current file
    //this.toggleVideoButton()
  }

  handleAttachVideoFileDownload = e => {
    // could do some validation for the attached file here
    const filesType = "video"
    this.setState({
      filesType
    })
    console.log('video files:::-', this.state.selectedFiles)

    if (this.state.videoTitle.length < 1) {
      this.setState({
        videoTitleError: "Please enter a video title."
      });
      return 0;
    }

    if (!this.state.selectedFiles || this.state.videoTitle.length < 1 || this.state.selectedFiles.length === 0) {
      this.setState({
        validInvalidMessage: "No file selected. Please select a video file."
      });
      return 0;
    }

    let selectVideo = [];
    let validInvalidMessage = "";
    for (var i = 0; i < this.state.selectedFiles.length; i++) {
      selectVideo[i] = this.state.selectedFiles.item(i);
      const fileSize = selectVideo[i].size / 1024;
      if (fileSize > 102400) {
        validInvalidMessage = `Invalid file selected or maximum size of file selected`;
        this.setState({
          validInvalidMessage,
        });
        this.setState({
          uploadBtnSts: "false",
        });
        return 0;
      }
    }
    if (!this.state.selectedFiles[0]) {
      return 0;
    }

    var ext = /^.+\.([^.]+)$/.exec(this.state.selectedFiles[0].name);
    let extn = ext == null ? "" : ext[1];
    console.log('extn', extn)
    if (extn === "mp4" || extn === "avi" || extn === "mov" || extn === "wmv") {
      this.setState({
        validInvalidMessage: ""
      })
    } else {
      this.setState({
        validInvalidMessage: "File type isn't allowed"
      })
      return 0;
    }

    this.props.setUploadFile(this.state.selectedFiles, filesType)
    //e.target.value = '' // to clear the current file
    this.setState({
      selectedFiles: ''
    })
    this.toggleVideoButton()
  }


  handleAttachImageFile = e => {

    const imageFile = `
    input[type="file"]::file-selector-button {
      padding: 0.5em 0.8em;
      border-radius: 0.2em;
      background-color: #ffd700;
      transition: 1s;
      border: none;
    }
    input[type="file"]::file-selector-button:hover {
      background-color: #81ecec;
    }`
    const maxFilesAllowed = 20; // Maximum number of files allowed
    const filesType = "image";
    this.setState({
      filesType,
      selectedFiles: e.target.files
    });

    if (!e.target.files || !this.state.imageTitle) {
      return;
    }

    if (e.target.files.length > maxFilesAllowed) {
      // If more than 20 files are selected, set a message and return
      this.setState({
        validInvalidMessage: "More than 20 files are not allowed to be attached",
        uploadBtnSts: "false", // Assuming this disables the upload button
      });
      return; // Stop further processing
    }

    let selectimages = [];
    let validInvalidMessage = "";
    for (var i = 0; i < e.target.files.length; i++) {
      selectimages[i] = e.target.files.item(i);
      const fileSize = selectimages[i].size / 1024;
      if (fileSize > 5240) {
        validInvalidMessage = `Invalid file selected or maximum size of file selected`;
        this.setState({
          validInvalidMessage,
          uploadBtnSts: "false",
        });
        return; // Exit if any file is over the size limit
      }
      // console.log("file size: ::: ", selectimages[i].size / 1024);
    }

    var ext = /^.+\.([^.]+)$/.exec(e.target.files[0].name);
    let extn = ext == null ? "" : ext[1];
    if (extn === "jpg" || extn === "jpeg" || extn === "png" || extn === "gif") {
      this.setState({
        validInvalidMessage: ""
      });
    } else {
      this.setState({
        validInvalidMessage: "File type isn't allowed"
      });
      return;
    }

    //this.props.setUploadFile(e.target.files, filesType);
    //e.target.value = ''; // to clear the current file selection
    //this.togglePhotoButton(); // Assuming this resets UI elements for the next operation
  };

  handleAttachImageFileUpload = e => {

    const imageFile = `
    input[type="file"]::file-selector-button {
      padding: 0.5em 0.8em;
      border-radius: 0.2em;
      background-color: #ffd700;
      transition: 1s;
      border: none;
    }
    input[type="file"]::file-selector-button:hover {
      background-color: #81ecec;
    }`
    const maxFilesAllowed = 20; // Maximum number of files allowed
    const filesType = "image";
    this.setState({
      filesType
    });

    console.log('files selected::', this.state.selectedFiles)

    if (!this.state.selectedFiles || !this.state.imageTitle) {
      return;
    }

    if (this.state.selectedFiles.length > maxFilesAllowed) {
      // If more than 20 files are selected, set a message and return
      this.setState({
        validInvalidMessage: "More than 20 files are not allowed to be attached",
        uploadBtnSts: "false", // Assuming this disables the upload button
      });
      return; // Stop further processing
    }

    let selectimages = [];
    let validInvalidMessage = "";
    for (var i = 0; i < this.state.selectedFiles.length; i++) {
      selectimages[i] = this.state.selectedFiles.item(i);
      const fileSize = selectimages[i].size / 1024;
      if (fileSize > 5240) {
        validInvalidMessage = `Invalid file selected or maximum size of file selected`;
        this.setState({
          validInvalidMessage,
          uploadBtnSts: "false",
        });
        return; // Exit if any file is over the size limit
      }

    }

    var ext = /^.+\.([^.]+)$/.exec(this.state.selectedFiles[0].name);
    let extn = ext == null ? "" : ext[1];
    if (extn === "jpg" || extn === "jpeg" || extn === "png" || extn === "gif") {
      this.setState({
        validInvalidMessage: ""
      });
    } else {
      this.setState({
        validInvalidMessage: "File type isn't allowed"
      });
      return;
    }

    this.props.setUploadFile(this.state.selectedFiles, filesType);
    this.setState({
      selectedFiles: '',
      //imageTitle: ''
    }); // to clear the current file selection
    //e.target.value = ''; // to clear the current file selection
    this.togglePhotoButton(); // Assuming this resets UI elements for the next operation
  };




  handleAttachAudioFile = e => {
    const filesType = "audio";
    this.setState({
      filesType,
      selectedFiles: e.target.files
    });

    if (!e.target.files) {
      return;
    }

    let selectAudio = [];
    let validInvalidMessage = "";
    for (var i = 0; i < e.target.files.length; i++) {
      selectAudio[i] = e.target.files.item(i);
      const fileSize = selectAudio[i].size / 1024;

      // Validate file size
      if (fileSize > 5240) {
        validInvalidMessage = `Invalid file selected or maximum size of file selected`;
        this.setState({
          validInvalidMessage,
          uploadBtnSts: false
        });
        return 0;
      }

      // Validate file extension
      var ext = /^.+\.([^.]+)$/.exec(e.target.files[i].name);
      let extn = ext == null ? "" : ext[1].toLowerCase();
      if (extn !== "mp3" && extn !== "wav") {
        validInvalidMessage = "File type isn't allowed. Please select MP3 or WAV files only.";
        this.setState({
          validInvalidMessage,
          uploadBtnSts: false
        });
        return 0;
      }
    }

    // If all files are valid, clear any error messages
    this.setState({
      validInvalidMessage: "",
      uploadBtnSts: true
    });
  }



  handleAttachAudioFileDownload = e => {
    const filesType = "audio";
    this.setState({
      filesType
    });

    // Validate the record title
    if (this.state.recordTitle.length < 1) {
      this.setState({

        recordTitleError: 'Please enter a record title',
        uploadBtnSts: false
      });

      return;
    }

    // Ensure there are files selected
    if (!this.state.selectedFiles) {
      return;
    }

    let selectAudio = [];
    let validInvalidMessage = "";
    for (var i = 0; i < this.state.selectedFiles.length; i++) {
      selectAudio[i] = this.state.selectedFiles.item(i);
      const fileSize = selectAudio[i].size / 1024;

      // Validate file size
      if (fileSize > 5240) {
        validInvalidMessage = `Invalid file selected or maximum size of file selected`;
        this.setState({
          validInvalidMessage,
          uploadBtnSts: false
        });
        return 0;
      }
    }

    // Validate file extension
    var ext = /^.+\.([^.]+)$/.exec(this.state.selectedFiles[0].name);
    let extn = ext == null ? "" : ext[1].toLowerCase();
    if (extn !== "mp3" && extn !== "wav") {
      this.setState({
        validInvalidMessage: "File type isn't allowed. Please select MP3 or WAV files only.",
        uploadBtnSts: false
      });
      return 0;
    }

    // If everything is valid, allow upload
    this.setState({
      validInvalidMessage: "",
      uploadBtnSts: true
    });

    this.props.setUploadFile(this.state.selectedFiles, filesType);

    // Clear selected files after upload
    this.setState({ selectedFiles: null });
    this.toggleRecordButton();
  }




  fileUploadThreadHtml = () => {
    // add style to the file upload button
    const fileUploadStyle = `
    input[type="file"]::file-selector-button {
    padding: 0.5em 0.8em;
    border-radius: 0.2em;
    background-color: #ffd700;
    transition: 1s;
    border: none;
    text-align: center;
    
  }
  // input[type="file"]::file-selector-button:hover {
  //   background-color: #ffd700;
  // }
`;
    return (
      <div>
        <style>{fileUploadStyle}</style>
        <input type="file" accept="video/*" multiple onChange={this.handleAttachVideoFile} />
      </div>
    )
  }

  imageFileUpload = () => {
    // add style to the file upload button
    const imageFile = `
      input[type="file"]::file-selector-button {
        padding: 0.5em 0.8em;
        border-radius: 0.2em;
        background-color: #ffd700;
        transition: 1s;
        border: none;
      }
      input[type="file"]::file-selector-button:hover {
        background-color: #81ecec;
      }
    `;
    return (
      <div>
        <style>{imageFile}</style>
        <input id="pht_select" type="file" name="filename" required multiple accept="image/*" onChange={this.handleAttachImageFile} />
      </div>
    )
  }

  deleteImages() {
    const tempImgID = this.state.deleteID;
    let successDel = memoriesService.deleteImageData(tempImgID);

    if (successDel) {
      const imageLists = this.state.imageLists.filter(
        (img) => img.id !== tempImgID
      );
      this.setState({
        imageLists,
      });
      this.setState({
        deleteImg: false,
      });
    } else {
      ToastsStore.warning("Sorry! Image is not deleted.");
    }
  }

  /****************************  Memories Page Social TabPane Data  ****************************/

  handleDateImage = (dateImg) => {
    // let userId = this.state.userObj.data.user_id;

    return dateImg.map((imgLine, i) => (
      <td key={i} className="image-grid-soc">
        {
          <LazyLoadImage
            alt={imgLine.filename}
            className="image-set-soc"
            effect={this.state.effect}
            scrollPosition={this.state.scrollPosition}
            src={imgLine.image_url}
            threshold={this.state.threshold}
          />
        }
        <div className="middle-soc">
          <button
            className="viewimage-soc"
            onClick={this.handleSocialShowDialog.bind(this, imgLine)}
          >
            <FaEye />
          </button>
          <button
            className="textimages-soc"
            onClick={this.toggleDeleteImgSocial.bind(this, imgLine.id)}
          >
            X
          </button>
        </div>
      </td>
    ));
  };

  loadingSocialImg = () => {
    return <img className="loader-img" alt="loaderImg" src={loaderImg} />;
  };

  socialImageList = () => {
    if (this.state.socialImageLists.length > 0) {
      let dateFilteredImage = this.state.socialImageLists;
      // this.setState({ socialImageError: "" });
      return (
        <React.Fragment>
          <div
            style={{
              textAlign: "center",
              fontWeight: "700",
              fontSize: "14",
              color: "#5c5c5c",
            }}
          >
            <h4 className="text-middle">
              <span> </span>
            </h4>
          </div>
          <Table>
            <tbody>
              <tr>{this.handleDateImage(dateFilteredImage)}</tr>
            </tbody>
          </Table>
        </React.Fragment>
      );
    } else {
      return <div className="empty-msg-mem">No images to display</div>;
    }
  };

  socialImageDelete = (imgId) => {
    this.setState({ loadingSocialData: true });
    let successDel = memoriesService.deleteSocialImageData(this, imgId);
    if (successDel) {
      let filterImgList = this.state.socialImageLists.filter((socialImage) => {
        if (socialImage.id !== imgId) {
          return true;
        }
      });

      this.setState({
        socialImageLists: filterImgList,
        socialImgDel: false,
        loadingSocialData: false,
      });
    } else {
      window.alert("Delete failed");
    }
  };

  handleDateVideo = (dateVideo) => {
    let videoPath = apiUrl;

    let filteredDateVideo = this.state.videoLists.filter(
      (item) => item.date === dateVideo
    );

    return filteredDateVideo.map((videoItem, i) => (
      <td key={i} className="vid-grid-soc">
        {
          <video width="290" height="200" controls poster={loadVideoPlay}>
            <source src={videoPath + "/" + videoItem.filename} />
          </video>
        }
        <h2>{videoItem.id}</h2>
      </td>
    ));
  };

  socialVideoList = () => {
    if (this.state.videoLists.length > 0) {
      let dateFilteredVideo = this.state.videoLists.filter(
        (ele, ind) =>
          ind ===
          this.state.videoLists.findIndex((elem) => elem.date === ele.date)
      );

      return dateFilteredVideo.map((dateVideo, i) => (
        <React.Fragment key={i}>
          <div
            style={{
              textAlign: "center",
              fontWeight: "700",
              fontSize: "14",
              color: "#5c5c5c",
            }}
          >
            <h4 className="text-middle">
              <span> {dateVideo.date} </span>
            </h4>
          </div>
          <Table>
            <tbody>
              <tr>{this.handleDateVideo(dateVideo.date)}</tr>
            </tbody>
          </Table>
        </React.Fragment>
      ));
    }
  };

  /****************************  Memories Page Video Option Start   ***************************/
  loadMoreVideoContent = () => {
    setTimeout(() => {
      if (this.state.loadingVideoCounter < this.state.videoLists.length) {
        let videoCounter = this.state.loadingVideoCounter + 2;

        this.setState({ loadingVideoCounter: videoCounter });
      }
      if (this.state.loadingVideoCounter >= this.state.videoLists.length) {
        this.setState({ hasMoreVideo: false });
      }
    }, 1000);
  };

  isImageUrl = (url) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  };
  isNewImageUrl = (url) => {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  setViewToImages = () => {
    this.setState({ currentView: 'images' });
  };

  setImageViewMethod = (image) => {
    const apiUrl = apiService.apiUrl;
    this.setState({
      selectedImage: image,
      imgPathModal: `${apiUrl}/${image.filename}`,
      imageOpen: true,
    });
  }
  setViewToVideos = () => {
    this.setState({ currentView: 'videos' });
  };
  setOpenToVideos = (video) => {
    this.setState({
      showModal: true,
      currentVideo: video,
    });
  }
  handleCloseModal = () => {
    this.setState({
      showModal: false,
      currentVideo: null,
    });
  }
  setViewToAudios = () => {
    this.setState({ currentView: 'audios' });
  };

  setViewToLetters = () => {
    this.setState({ currentView: 'letters' });
  };

  handleImagePopUp = () => {
    this.setState((prevState) => ({
      imageOpen: !prevState.imageOpen,
    }));
  }

  renderSocialWithPlaceholders = () => {
    const { imageLists, maxImagesToShow } = this.state;
    const reversedImageLists = [...imageLists].reverse();
    const validImages = reversedImageLists.filter(image => this.isNewImageUrl(image.filename));
    const imagesToShow = validImages.slice(0, maxImagesToShow);
    const placeholdersCount = Math.max(maxImagesToShow - reversedImageLists.length, 0);

    return (
      <div className="mem-grid" id="mediaView" onClick={this.scrollToCarouselViewer}>
        {reversedImageLists.slice(0, maxImagesToShow).map((image, index) => (
          <div className="image-container-mem" key={index}>
            <img className="img-fluid" src={`${apiUrl}/${image.filename}`} alt="Memory" />
          </div>
        ))}
        {[...Array(placeholdersCount)].map((_, index) => (
          <div className="image-container-mem" key={`placeholder-${index}`}>
            <img className="img-fluid" src={mediaView} alt="Placeholder" />
          </div>
        ))}
      </div>
    );
  };

  // renderSocialWithPlaceholders = () => {
  //   const { imageLists, maxImagesToShow } = this.state;
  //   const reversedImageLists = [...imageLists].reverse(); // Reverse the imageLists array
  //   const validImages = reversedImageLists.filter(image => this.isNewImageUrl(image.filename));
  //   const imagesToShow = validImages.slice(0, maxImagesToShow);
  //   const placeholdersCount = Math.max(maxImagesToShow - reversedImageLists.length, 0);
  //   const customImageBorderRadiusStyle = {
  //     borderRadius: '0px 25px'
  //   };

  //   return (
  //     <div className="row img-Vdo-height" id="mediaView" onClick={this.scrollToCarouselViewer}>
  //       {reversedImageLists.slice(0, maxImagesToShow).map((image, index) => (
  //         <div className="col-lg-3 col-6 image-container" key={index}>
  //           <img src={`${apiUrl}/${image.filename}`} style={customImageBorderRadiusStyle} alt="Memory" />
  //           {/* <div className="hover-buttons">
  //           <button onClick={() => this.setImageViewMethod(image)}>View</button>
  //           <button onClick={() => this.handleDeleteClick(image)}>Delete</button>
  //         </div> */}
  //         </div>
  //       ))}
  //       {[...Array(placeholdersCount)].map((_, index) => (
  //         <div className="col-lg-3 col-6 image-container" key={`placeholder-${index}`}>
  //           <img src={mediaView} style={customImageBorderRadiusStyle} alt="Placeholder" />
  //           {/* <div className="hover-buttons">
  //           <button onClick={this.setImageViewMethod}>View</button>
  //         </div> */}
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  handleResize = () => {
    this.setState({
      screenWidth: window.innerWidth,
      maxImagesToShow: window.innerWidth < 768 ? 2 : 4,
      maxVideosToShow: window.innerWidth < 768 ? 2 : 4,

    });
  };

  isNewImageUrl = (filename) => {
    // Your logic to check if it's a new image URL
  };


  setOpenToVideos = (video) => {
    this.setState({ currentVideo: video, showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, currentVideo: null });
  };



  truncateText = (htmlContent, wordLimit) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    const textContent = div.textContent || div.innerText || "";
    const words = textContent.split(' ');

    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(' ')}...`;
    } else {
      return textContent;
    }
  };

  renderLettersWithPlaceholders = () => {
    const { letterLists, maxLetterToShow } = this.state;
    const reversedLetterLists = [...letterLists].reverse(); // Reverse the letterLists array
    const itemsToShow = reversedLetterLists.slice(0, maxLetterToShow);
    const placeholdersCount = Math.max(maxLetterToShow - itemsToShow.length, 0);
    const itemStyle = {
      height: '200px',
    };
    const itemStyleMobile = {
      ...itemStyle,
      flex: '1 0 calc(100% - 20px)',  // Adjust width for mobile devices
      height: '190px',
    };

    return (
      <div className="mem-grid" id="mediaView" onClick={this.scrollToCarouselViewer}>
        {itemsToShow.map((letter, index) => (
          <div key={index} className="image-container-mem d-flex align-items-center" style={itemStyle} onClick={this.setViewToLetters}>
            <div className="w-100 d-flex flex-column justify-content-center">
              <div className="h5 text-center" dangerouslySetInnerHTML={{ __html: this.truncateText(letter.subTxt, 2) }}></div>
              <div className="text-center" dangerouslySetInnerHTML={{ __html: this.truncateText(letter.desTxt, 3) }} />
            </div>
          </div>
        ))}
        {[...Array(placeholdersCount)].map((_, index) => (
          <div key={`placeholder-${index}`} className="image-container-mem d-flex align-items-center" onClick={this.setViewToLetters} style={itemStyle}>
            <img src={letterView} className="letter-h-w" alt="Placeholder" style={itemStyle} />
          </div>
        ))}
      </div>
    );
  };

  // renderLettersWithPlaceholders = () => {
  //   const { letterLists, maxLetterToShow } = this.state;
  //   const reversedLetterLists = [...letterLists].reverse(); // Reverse the letterLists array
  //   const itemsToShow = reversedLetterLists.slice(0, maxLetterToShow);
  //   const placeholdersCount = Math.max(maxLetterToShow - itemsToShow.length, 0);
  //   const itemStyle = {
  //     borderTopLeftRadius: '0',  // adjust the radius as needed
  //     borderTopRightRadius: '25px', // adjust the radius as needed
  //     borderBottomLeftRadius: '25px',
  //     borderBottomRightRadius: '0',
  //     margin: '5px',  // Add margin for spacing
  //     flex: '1 0 calc(25% - 10px)', // Adjust the width as needed
  //   };
  //   const itemStyleMobile = {
  //     ...itemStyle,
  //     flex: '1 0 calc(100% - 20px)',  // Adjust width for mobile devices
  //     height: '190px',
  //   };

  //   return (
  //     <div className="row col-xs-12 col-sm-12 col-md-12 d-flex  justify-content-center letter-h-w" id="mediaView" onClick={this.scrollToCarouselViewer}>
  //       {itemsToShow.map((letter, index) => (
  //         <div key={index} className="shadow col-lg-3 col-6 media-preview letter-padding-img" style={itemStyle} onClick={this.setViewToLetters}>
  //           <h5 dangerouslySetInnerHTML={{ __html: this.truncateText(letter.subTxt, 2) }}></h5>
  //           <div dangerouslySetInnerHTML={{ __html: this.truncateText(letter.desTxt, 3) }} />
  //         </div>
  //       ))}
  //       {[...Array(placeholdersCount)].map((_, index) => (
  //         <div key={`placeholder-${index}`} className="col-lg-3 col-6 placeholder" onClick={this.setViewToLetters} style={itemStyle}>
  //           <img src={letterView} className="letter-h-w" alt="Placeholder" style={itemStyle} />
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };






  errorImageFunction = () => {
    return "this.onerror=null; this.src='/public/images/video_placeholder_full.png';"
  }


  renderVideosWithPlaceholders = () => {
    const { videoLists, maxVideosToShow, showModal, currentVideo } = this.state;

    const reversedVideoLists = videoLists.length > 4 ? [...videoLists].reverse().slice(0, 4) : [...videoLists].reverse(); // Reverse the videoLists array
    const placeholdersCount = Math.max(maxVideosToShow - reversedVideoLists.length, 0);

    const customBorderRadiusStyle = {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '30px',
      borderBottomLeftRadius: '30px',
      borderBottomRightRadius: '0px',
      margin: '0px',
      flex: '1 0 calc(25% - 10px)',
      height: '200px !important'
    };

    return (
      <div className="mem-grid" id="mediaView" onClick={this.scrollToCarouselViewer}>

        {reversedVideoLists.map((video, index) => {

          const opts = {
            height: '170',
            width: '100%',
            playerVars: {
              autoplay: 0,
              controls: 0,
              disablekb: 1,
              modestbranding: 1,
              rel: 0,
              showinfo: 0
            },
          };

          let videoFilePath = "";

          let fileWatch = video.filename ? video.filename.split("watch?v=") : null;
          let fileEmbed = video.filename ? video.filename.split("embed/") : null;
          let fileYoutu = video.filename ? video.filename.split("youtu.be/") : null;
          let fileYoutS = video.filename ? video.filename.split("shorts/") : null;

          if (fileWatch && fileWatch[1]) {
            videoFilePath = fileWatch[1];
          }
          if (fileEmbed && fileEmbed[1]) {
            videoFilePath = fileEmbed[1];
          }
          if (fileYoutu && fileYoutu[1]) {
            videoFilePath = fileYoutu[1];
          }
          if (fileYoutS && fileYoutS[1]) {
            videoFilePath = fileYoutS[1];
          }


          return video.urlcheck == "videoLink" ?

            (
              <div key={index} className="video-container col-lg-3 col-sm-12 col-xs-12 col-md-6 mb-3">
                <div className="video-thumbnail memories-video-container" style={customBorderRadiusStyle}>

                  <YouTube videoId={videoFilePath} opts={opts} onReady={this._onReady} />

                </div>
                <div className="px-2 font-weight-bold text-muted"> {video.title ? video.title : ''}  </div>
              </div>
            ) : (
              <div key={index} className="video-container col-lg-3 col-sm-12 col-xs-12 col-md-6 mb-3">
                <div className="video-thumbnail memories-video-container" style={customBorderRadiusStyle}>
                  <img src={`${apiUrl}/${video.thumbnail_url}`} alt={video.title ? video.title : ''} onError={this.errorImageFunction} style={{ width: '100%', height: '200px' }} />
                  <div className="01 position-absolute px-2 font-weight-bold text-white" style={{ top: '15px', left: '10px' }}>
                    {video.title ? video.title : ''}
                  </div>

                  <div className="play-icon-overlay">
                    <FaPlay className="play-icon" />
                  </div>
                </div>
              </div>
            )

        }
        )}

        {[...Array(placeholdersCount)].map((_, index) => (

          <div key={`placeholder-${index}`} className="image-container-mem">
            <img src={videoView} alt="Video Placeholder" className="video-border-redius" />
            <div className="play-icon-overlay">
              {/* <FaPlay className="play-icon" /> */}
            </div>

          </div>
        ))}
        <Modal isOpen={showModal} toggle={this.handleCloseModal} centered>
          <ModalHeader toggle={this.handleCloseModal}>Video Viewer</ModalHeader>
          <ModalBody style={{ maxHeight: '50%', overflowY: 'auto' }}>
            {currentVideo && (
              <video width="100%" height="auto" controls>
                <source src={`${apiUrl}/${currentVideo.filename}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleCloseModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  renderMoreVideoButton = () => {
    return this.state.videoLists.length > 4 ?
      (<div className="d-flex justify-content-center align-items-center"><Button href="/memories-videos" className=" btn bg-white text-dark view-more-btn" onClick={this.reloadPage} style={{ width: '150px' }}>
        <span>View More</span> <span><i className="fas fa-eye  "></i></span>
      </Button></div>) : (<div></div>);
  }

  renderAudiosWithPlaceholders = () => {
    const { recordLists, maxAudiosToShow } = this.state;
    const reversedRecordLists = [...recordLists].reverse(); // Reverse the recordLists array

    // Filter for valid audio URLs
    const validAudios = reversedRecordLists
      .filter(audio => audio && audio.filename) // Ensure audio and audio.filename exist
      .filter(audio => /\.(mp3|wav|ogg|m4a)$/i.test(audio.filename)); // Check if filename is a valid audio type

    const audioItemsToShow = validAudios.slice(0, maxAudiosToShow); // Get up to the max number of valid audio files
    const placeholdersCount = Math.max(maxAudiosToShow - audioItemsToShow.length, 0); // Calculate the number of placeholders needed

    const audioItemStyle = {
      height: '200px',
    };


    return (
      <div className="mem-grid" id="mediaView" onClick={this.scrollToCarouselViewer}>
        {audioItemsToShow.map((audio, index) => (
          <div key={index} className="image-container-mem d-flex align-items-center" onClick={() => this.setViewToAudios(audio)} style={audioItemStyle}>
            <div className="w-100 d-flex justify-content-center align-items-center px-1" style={{ flexDirection: 'column' }}>
              <div style={{width:"inherit"}}>
                <p className="audio-title" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: this.truncateText(audio.title, 1) }}></p>
              </div>
              <div className="d-flex justify-content-center align-items-center w-100"  >
                <audio style={{width:userAgent=="Apple Computer, Inc."?'25%':'100%'}} controls>
                  <source src={`${apiUrl}/${audio.filename}`} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
          </div>
        ))}
        {[...Array(placeholdersCount)].map((_, index) => (
          <div key={`placeholder-${index}`} className="image-container-mem justify-content-center align-items-center d-flex" onClick={this.setViewToAudios} style={audioItemStyle}>
            <img className="img-fluid" src={audioView} alt="Audio Placeholder" />
          </div>
        ))}
      </div>
    );
  };

  renderImagesWithPlaceholders = () => {
    const { imageLists, maxImagesToShow } = this.state;
    const reversedImageLists = [...imageLists].reverse();
    const validImages = reversedImageLists.filter(image => this.isNewImageUrl(image.filename));
    const imagesToShow = validImages.slice(0, maxImagesToShow);
    const placeholdersCount = Math.max(maxImagesToShow - reversedImageLists.length, 0);

    return (
      <div className="mem-grid" id="mediaView" onClick={this.scrollToCarouselViewer}>
        {reversedImageLists.slice(0, maxImagesToShow).map((image, index) => (
          <div className="image-container-mem" key={index}>
            <img className="img-fluid" src={`${apiUrl}/${image.filename}`} alt="Memory" />
          </div>
        ))}
        {[...Array(placeholdersCount)].map((_, index) => (
          <div className="image-container-mem" key={`placeholder-${index}`}>
            <img className="img-fluid" src={mediaView} alt="Placeholder" />
          </div>
        ))}
      </div>
    );
  };

  onDemandContentVideo = () => {
    return (
      <div>
        <InfiniteScroll
          dataLength={this.state.loadingVideoCounter}
          next={this.loadMoreVideoContent.bind(this)}
          hasMore={this.state.hasMoreVideo}
        >
          {this.showVideos()}
        </InfiniteScroll>
      </div>
    );
  };

  showVideos() {
    let videoPath = apiUrl;

    let videoLists = [];
    let tempVideoList = [];
    let videoCounter = 0;

    this.state.videoLists.map((item) => {
      if (videoCounter <= this.state.loadingVideoCounter) {
        tempVideoList.push(item);

      }
      videoCounter = videoCounter + 1;
    });

    if (tempVideoList.length !== 0) {
      if (this.state.userType === "bn") {
        let videoTmpList = [];
        tempVideoList.map((videoitem, i) => {
          if (videoitem.urlcheck === "videoFile") {
            let videoFile = (
              <div key={i} className="video-Grid" id={`video-${videoitem.id}`}>
                <img src={videoPath + "/" + videoitem.thumbnail_url} onClick={this.toggleVideoPlayer.bind(this, videoitem)} width="300" height="240" alt="Play video nows" style={{ borderRadius: '15px' }} />

              </div>
            );
            videoTmpList.push(videoFile);
          } else if (videoitem.urlcheck === "videoLink") {
            const opts = {
              height: '240',
              width: '300',
              playerVars: {
                autoplay: 1,
              },
            };

            let videoLink = (
              <div key={i} className="video-Grid" id={`video-${videoitem.id}`} style={{ borderRadius: '15px' }}>
                <img src="/images/youtube_placeholder.jpg" onClick={this.toggleVideoPlayer.bind(this, videoitem)} width="300" height="240" alt="Play video now" style={{ borderRadius: '15px' }} />
              </div>
            );
            videoTmpList.push(videoLink);
          }

          videoLists = videoTmpList;
        });

      } else {

        let videoTmpList = [];
        tempVideoList.map((videoitem, i) => {
          if (videoitem && videoitem.urlcheck === "videoFile") {
            let videoFile = (
              <div key={i} className="video-Grid card" id={`video-${videoitem.id}`} style={{ borderRadius: '15px', boxShadow: '10px' }} >

                <img src={videoPath + "/" + videoitem.thumbnail_url} onClick={this.toggleVideoPlayer.bind(this, videoitem)} width="300" height="240" alt="Play video now" />
                <div className="video-corner">
                  {this.state.primary_user_id == videoitem.user_id ? ('') :
                    (<button
                      className="textvideos deleteVideo"
                      onClick={this.toggleDeleteVideo.bind(this, videoitem.id)}
                    >
                      X
                    </button>)}
                </div>

              </div>

            );
            videoTmpList.push(videoFile);
          } else if (videoitem && videoitem.urlcheck === "videoLink") {
            const opts = {
              height: '240',
              width: '300',
              playerVars: {
                autoplay: 1,
              },
            };

            let videoLink = (
              <div key={i} className="video-Grid" id={`video-${videoitem.id}`} style={{ borderRadius: '15px' }}>

                <img src="/images/youtube_placeholder.jpg" onClick={this.toggleVideoPlayer.bind(this, videoitem)} width="300" height="240" alt="Play video now" style={{ borderRadius: '15px' }} />
                <div className="video-corner">
                  {this.state.primary_user_id == videoitem.user_id ? ('') :
                    (<button
                      className="textvideos"
                      onClick={this.toggleDeleteVideo.bind(this, videoitem.id)}
                    >
                      X
                    </button>)}
                </div>
              </div>
            );
            videoTmpList.push(videoLink);
          }

          videoLists = videoTmpList;
        });
      }
    } else {
      if (this.state.loadingVideos) {
        videoLists = <p className="empty-msg-mem"> Loading Video Data... </p>;
      } else {
        videoLists = (
          <div className="container">
            <div className="row " style={{ marginLeft: "6px" }}>
              <img
                src={zeroState}
                className="img-fluid mx-auto d-block"
                alt="love-pat"
                style={{ marginTop: "37px" }}
              />
            </div>
          </div>
        );
      }
    }
    return videoLists;
  }

  _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  selectVideos = (event) => {
    let selectvideos = [];
    let validInvalidMessage = "";
    this.setState({
      uploadVideoFiles: event.target.files,
    });
    for (var i = 0; i < event.target.files.length; i++) {
      selectvideos[i] = event.target.files.item(i);

      // if(selectvideos[i].size>10498677){
      if (selectvideos[i].size > 10498677) {
        this.setState({
          errorFileVideo: "Video file size is over of limit",
        });
        return 0;
      } else {
        this.setState({
          errorFileVideo: "",
        });
      }
    }
    selectvideos = selectvideos.filter((video) =>
      video.name.match(/\.(mp4|ogg|webm|mov|avi|wmv|ogv|mpeg|ogm|mpg|m4v)$/)
    );

    // if (selectvideos.maxSizeMB() > 10485760) {

    // }
    let selectedVideo = selectvideos[0];
    let videoName = selectedVideo.name.split(".")[0];
    this.setState({
      selectedVideo,
      videoName: videoName,
    });

    if (selectvideos.length > 0) {
      validInvalidMessage = `${selectvideos.length} valid videos selected`;
      this.setState({
        validInvalidMessage,
      });
      this.setState({
        uploadBtnSts: "true",
      });
    } else {
      validInvalidMessage = `Invalid file selected`;
      this.setState({
        validInvalidMessage,
      });
      this.setState({
        uploadBtnSts: "false",
      });
    }
  };

  urlMatch = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "error";
    }
  };

  saveVideosLink = () => {
    let urlLink = document.getElementById("urlpath").value;
    urlLink = urlLink.replace("watch?v=", "youtu.be/", "embed/");
    let youtubeVideoID = urlLink.split("watch?v=")
    if (!youtubeVideoID[1]) {
      youtubeVideoID = ""
      youtubeVideoID = urlLink.split("youtu.be/")
    }

    if (!youtubeVideoID[1]) {
      youtubeVideoID = ""
      youtubeVideoID = urlLink.split("embed/")
    }
    let urlTitle = document.getElementById("urltitle").value;

    if (urlLink.length < 1) {
      ToastsStore.warning(" Add the required field");
      return false;
    }

    if (urlTitle.length < 1) {
      ToastsStore.warning(" Add the required field");
      return false;
    }
    const urlTypecheck = "videoLink";

    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }
    let selectedVideo = this.state.selectedVideo;

    let videoList = this.state.videoLists;
    // let videoName = this.state.videoName;
    if (this.state.errorFileVideo) {
      return false;
    }

    memoriesService.storeVideoData(
      userId,
      urlTypecheck,
      urlLink,
      urlTitle,
      selectedVideo,
      videoList,
      this
    );
    this.setState({
      uploadingData: true,
    });

    this.setState({ videoLists: this.state.linkVideoList });
    this.loadMoreVideoContent();
  };

  selectVideosMultiple = (event) => {
    let selectvideos = [];
    let validInvalidMessage = "";

    for (var i = 0; i < event.target.files.length; i++) {
      selectvideos[i] = event.target.files.item(i);
      // if(selectvideos[i].size>10498677){
      if (selectvideos[i].size > 10498677) {
        this.setState({
          errorFileVideo: "Video file size is over of limit",
        });
        return 0;
      } else {
        this.setState({
          errorFileVideo: "",
        });
      }
    }
    selectvideos = selectvideos.filter((video) =>
      video.name.match(/\.(mp4|ogg|webm|mpeg)$/)
    );

    // video.name.match(/\.(mp4|ogg|webm|mov|avi|wmv|ogv|mpeg|ogm|mpg|m4v|3gp)$/)

    let selectedVideo = selectvideos;
    if (selectvideos[0] && selectvideos[0].name.split(".")[0]) {
      let videoName = selectvideos[0].name.split(".")[0];
      this.setState({
        selectedVideo,
        videoName: videoName,
      });
    } else {
      validInvalidMessage = `Invalid file selected`;
      this.setState({
        validInvalidMessage,
        uploadBtnSts: "false",
      });
    }

    if (selectvideos.length > 0) {
      validInvalidMessage = `${selectvideos.length} valid videos selected`;
      this.setState({
        validInvalidMessage,
        uploadBtnSts: "true",
      });
    } else {
      validInvalidMessage = `Invalid file selected`;
      this.setState({
        validInvalidMessage,
        uploadBtnSts: "false",
      });
    }
  };

  handleVideoUpload() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }
    let selectedVideo = this.state.selectedVideo;

    let videoList = this.state.videoLists;
    let videoName = this.state.videoName;
    if (this.state.errorFileVideo) {
      return false;
    }
    const urlTypecheck = "videoFile";
    const urlTitle = "";
    memoriesService.storeVideoData(
      userId,
      urlTypecheck,
      videoName,
      urlTitle,
      selectedVideo,
      videoList,
      this
    );
    this.setState({
      uploadingData: true,
      videoName: "",
    });
    this.loadMoreVideoContent();
  }

  deleteVideos() {
    const tempVidID = this.state.deleteID;
    let successDel = memoriesService.deleteVideoData(tempVidID);
    if (successDel) {
      const videoDiv = document.getElementById('video-' + tempVidID);
      if (videoDiv) {
        //videoDiv.remove();
      }
      // var videoListsTmp = this.state.videoLists;
      // var index = videoListsTmp.indexOf(tempVidID)
      const videoLists = this.state.videoLists.filter(
        (vid) => vid.id !== tempVidID
      );
      this.setState({
        videoLists,
      });
      this.setState({
        deleteVid: false,
      });
      ToastsStore.success("Video has been deleted successfully!");
    } else {
      ToastsStore.warning("Sorry! Video is not deleted");
    }
  }


  /************************ Letter  HTML String Text Browser  View &&  Delete Option Start ***********************/

  loadMoreLetterContent = () => {
    setTimeout(() => {
      if (this.state.loadingLetterCounter < this.state.letterListTemp.length) {
        let letterCounter = this.state.loadingLetterCounter + 2;
        this.setState({ loadingLetterCounter: letterCounter });
      }
      if (this.state.loadingLetterCounter >= this.state.letterListTemp.length) {
        this.setState({ hasMoreLetter: false });
      }
    }, 1000);
  };

  onDemandContentLetter = () => {
    return (
      <div className="container-fluid">
        <InfiniteScroll
          dataLength={this.state.loadingLetterCounter}
          next={this.loadMoreLetterContent.bind(this)}
          hasMore={this.state.hasMoreLetter}
        >
          {this.showLetters()}
        </InfiniteScroll>
      </div>
    );
  };

  showLetters = () => {
    let listview = [];
    let tempLetterList = [];
    let letterCounter = 0;

    this.state.letterListTemp.map((item) => {
      if (letterCounter <= this.state.loadingLetterCounter) {
        tempLetterList.push(item);
      }
      letterCounter = letterCounter + 1;
    });
    if (tempLetterList.length !== 0) {
      if (this.state.userType === "bn") {
        listview = (
          <div className=" container-fluid">
            <div className="row head-letter">
              <div className="col-md-3 col-sm-12 col-xs-12"> Subject</div>
              <div className="col-md-5 col-sm-12 col-xs-12">Description</div>
              <div className="col-md-4 col-sm-12 col-xs-12 ">Action</div>
            </div>
            <div className="row list-letter">
              {this.state.activeLetterSearch === "active" ? (
                <div className="text-center">
                  <h2> No Letters found </h2>
                  <p>
                    Sorry, we can't find the type of letter you are looking for.
                    Try widening your search to see what’s available.
                  </p>
                </div>
              ) : (
                ""
              )}
              {this.state.activeLetterSearch === "" ? (
                <img
                  src={zeroState}
                  className="img-fluid mx-auto d-block"
                  alt="love-pat-letter"
                  style={{ marginTop: "15px" }}
                />
              ) : (
                ""
              )}
              {tempLetterList.map((letteritem, i) => (
                <React.Fragment key={i}>
                  <div className="col-xs-3 col-sm-3 col-md-3">
                    {letteritem.subTxt}
                  </div>

                  <div className="col-xs-5 col-sm-5 col-md-5">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: letteritem.desTxt,
                      }}
                    />
                  </div>

                  <div className="col-xs-4 col-sm-4 col-md-4">
                    <button
                      className="btn-control-view"
                      onClick={this.letterPopView.bind(this, letteritem)}
                    >
                      View
                    </button>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        listview = (
          <div className=" container-fluid">
            <div className="row head-letter">
              <div className="col-md-3 col-sm-12 col-xs-12"> Subject</div>
              <div className="col-md-5 col-sm-12 col-xs-12">Description</div>
              <div className="col-md-4 col-sm-12 col-xs-12 ">Action</div>
            </div>
            <div className="row list-letter">
              {tempLetterList.map((letteritem, i) => (
                <React.Fragment key={i}>
                  <div className="col-xs-12 col-sm-12 col-md-3 subject">
                    {letteritem.subTxt}
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-5">
                    {letteritem.desTxt
                      .replace(
                        /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
                        " "
                      )
                      .substr(0, 100)}
                    {letteritem.desTxt.length > 105 ? "..." : ""}
                  </div>

                  <div className="col-xs-12 col-sm-3 col-md-1">
                    <button
                      className="btn-control-view"
                      onClick={this.letterPopView.bind(this, letteritem)}
                    >
                      View
                    </button>
                  </div>
                  <div className="col-xs-12 col-sm-3 col-md-1">
                    {
                      this.state.primary_user_id == letteritem.user_id ? (<h4 className="ml-4">-</h4>) : (<button
                        className="btn-control-edit"
                        onClick={this.letterPopEdit.bind(this, letteritem, i)}
                      >
                        Edit
                      </button>)
                    }


                  </div>
                  <div className="col-xs-12 col-sm-3 col-md-1">
                    {
                      this.state.primary_user_id == letteritem.user_id ? (<h4 className="ml-5">-</h4>) : (<button
                        className="btn-control-delete"
                        onClick={this.toggleDeleteLetter.bind(
                          this,
                          letteritem.id
                        )}
                      >
                        Delete
                      </button>)
                    }


                  </div>
                  <div className="col-xs-12 col-sm-3 col-md-1 ">
                    <Media
                      src={btnDownload}
                      alt="Download"
                      object
                      id="myletter"
                      className="btn-download"
                      onClick={this.pdfGenerate.bind(this, letteritem)}
                      style={{ height: "37px" }}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    } else {
      listview = (
        <div className="container">
          <div className="row" style={{ marginLeft: "6px" }}>
            {this.state.activeLetterSearch === "active" ? (
              <div className="text-center">
                <h2> No Letters found </h2>
                <p>
                  Sorry, we can't find the type of letter you are looking for.
                  Try widening your search to see what’s available.
                </p>
              </div>
            ) : (
              ""
            )}
            {this.state.activeLetterSearch === "" ? (
              <img
                src={zeroState}
                className="img-fluid mx-auto d-block"
                alt="love-pat-letter"
                style={{ marginTop: "15px" }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }

    return listview;
  };

  handleLetterChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      submitted: false,
      errorSubject: "",
    });
  };

  handleSubmitLetter = (e) => {
    e.preventDefault();

    const { subject, description } = this.state;

    // Clean the description by removing any unwanted HTML tags
    let descriptions = description.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");

    // Validate the subject
    if (!subject) {
      this.setState({
        errorSubject: "Subject is required",
      });
      return; // Stop further execution if the subject is not provided
    }

    // Validate the description
    if (
      !descriptions ||
      description.trim() === "<p></p>" ||
      description.trim() === "<p>&nbsp;</p>" ||
      description.trim() === "<p>&nbsp;&nbsp;</p>" ||
      description.trim() === "<p>&nbsp;&nbsp;&nbsp;</p>" ||
      description.trim() === '<p style="text-align:left;">&nbsp;</p>' ||
      description.trim() === '<p style="text-align:center;">&nbsp;</p>' ||
      description.trim() === '<p style="text-align:left;">&nbsp;&nbsp;</p>' ||
      description.trim() === '<p style="text-align:center;">&nbsp;&nbsp;</p>' ||
      description.trim() === "<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>"
    ) {
      this.setState({
        errorDescription: "Description is required",
      });
      return; // Stop further execution if the description is not provided
    }

    // Reset any previous errors
    this.setState({
      submitted: true,
      statusCode: "",
      errorMessage: "",
      errorDescription: "",
      errorSubject: "",
    });

    // Proceed to save only if both subject and description are valid
    if (subject && descriptions) {
      this.setState({ loadingLetters: true });
      let temp_Txt = this.state.letterLists;

      let userId = this.state.userObj.data.user_id;
      if (this.state.userType === "bn") {
        userId = this.state.userObj.data.primary_user_id;
      } else {
        userId = this.state.userObj.data.user_id;
      }

      // Save letter data
      memoriesService.storeLetterData(
        userId,
        subject,
        description,
        temp_Txt,
        this
      );

      // Close modal and stop loading indicator after save
      this.setState({
        modalletter: false,
        loadingLetters: false, // Reset loading state
      });
    }

    this.loadMoreLetterContent();
  };


  subjectValidation() {
    return (
      this.state.errorSubject && (
        <div className="alert alert-danger">{this.state.errorSubject}</div>
      )
    );
  }

  descValidation() {
    if (this.state.submitted && !this.state.description) {
      return (
        <div className="help-block-memory text-left" style={{ width: "90%" }}>
          Description is required
        </div>
      );
    } else if (this.state.submitted === true && this.state.statusCode === 422) {
      return (
        <div className="help-block-memory"> {this.state.errorMessage} </div>
      );
    }
  }
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState: editorState,
      description: draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
      errorDescription: "",
    });
  };

  onEditorEditStateChange = (editorEditState) => {
    this.setState({
      editorEditState: EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(""))
      ),
    });
  };

  textEditorPanel = () => {
    let { editorState } = this.state;

    return (
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor custom-editor-height"
        placeholder="Enter description..."
        name="desc"
        onEditorStateChange={this.onEditorStateChange}
        localization={{
          locale: "en",
        }}
        translations={true}
        // localization={localization}
        // localization={{ locale: 'en', translations: editorLabels }}
        required
      />
    );
  };

  //======================================= Letter  Pop up View start  ===============================

  letterPopView = (e) => {
    let subj = this.state.subject;
    let desc = this.state.description;

    subj = e.subTxt;
    desc = e.desTxt;
    this.setState({
      subj,
    });
    this.setState({
      desc,
    });

    this.toggleLetterViewButton();
  };

  //============================== Letter  Pop up View End  =============================

  /*********************** Letter  HTML String Text Broswer View && Delete Option End   **************************/

  /******************* Letter edit/ view window Pop Up Option Start  ***********************/

  subjectEditValidation = () => {
    if (this.state.submitted && !this.state.subject) {
      return <div className="help-block-memory"> Subject cannot be empty</div>;
    } else if (this.state.submitted === true && this.state.statusCode === 422) {
      return (
        <div className="help-block-memory"> {this.state.errorMessage} </div>
      );
    }
  };

  descEditValidation = () => {
    if (this.state.submitted && !this.state.description) {
      return (
        <div className="help-block-memory"> Description cannot be empty</div>
      );
    } else if (this.state.submitted === true && this.state.statusCode === 422) {
      return (
        <div className="help-block-memory"> {this.state.errorMessage} </div>
      );
    }
  };

  letterPopEdit = (e, i) => {
    let subj = "";
    let desc = "";
    subj = e.subTxt;
    desc = e.desTxt;

    this.setState({
      errorDescription: "",
    });
    console.log("descriptions tmp::::", desc);
    if (
      desc.trim() === "<p></p>" ||
      desc.trim() === "" ||
      !desc ||
      desc.trim() === "<p></p>" ||
      desc.trim() === "<p>&nbsp;</p>" ||
      desc.trim() === "<p>&nbsp;&nbsp;</p>" ||
      desc.trim() === "<p>&nbsp;&nbsp;&nbsp;</p>" ||
      desc.trim() === "<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>" ||
      desc.trim() === '<p style="text-align:left;">&nbsp;</p>' ||
      desc.trim() === '<p style="text-align:center;">&nbsp;</p>' ||
      desc.trim() === '<p style="text-align:left;">&nbsp;&nbsp;</p>' ||
      desc.trim() === '<p style="text-align:center;">&nbsp;&nbsp;</p>'
    ) {
      this.setState({
        editIndex: i,
        editID: e.id,
        editSubject: subj,
        editDescription: desc,
        editorEditState: EditorState.createEmpty(),
      });
    } else {
      this.setState({
        editIndex: i,
        editID: e.id,
        editSubject: subj,
        editDescription: desc,
        editorEditState: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(desc))
        ),
      });
    }
    this.toggleLetterEditButton();
  };

  onChangeSubject = (e) => {
    this.setState({
      editSubject: e.target.value,
      btnActive: true,
    });
  };

  onChangeDescription = (editorEditState) => {
    this.setState({
      editorEditState,
      btnActive: true,
      editDescription: draftToHtml(
        convertToRaw(this.state.editorEditState.getCurrentContent())
      ),
    });
  };

  saveChanges = (e) => {
    e.preventDefault();
    let editIndex = this.state.editIndex;
    let editID = this.state.editID;
    let subTxt = this.state.editSubject;
    this.setState({
      editDescription: draftToHtml(
        convertToRaw(this.state.editorEditState.getCurrentContent())
      ),
    });

    let desTxt = this.state.editDescription;

    if (subTxt.trim() === "<p></p>" || subTxt.trim() === "") {
      this.setState({
        errorSubjectEdit: "Letter ubject is required",
      });
      return false;
    }

    console.log("description:::::", desTxt);

    if (
      desTxt.trim() === "<p></p>" ||
      desTxt.trim() === "" ||
      !desTxt ||
      desTxt.trim() === "<p></p>" ||
      desTxt.trim() === "<p>&nbsp;</p>" ||
      desTxt.trim() === "<p>&nbsp;&nbsp;</p>" ||
      desTxt.trim() === "<p>&nbsp;&nbsp;&nbsp;</p>" ||
      desTxt.trim() === '<p style="text-align:left;">&nbsp;</p>' ||
      desTxt.trim() === '<p style="text-align:center;">&nbsp;</p>' ||
      desTxt.trim() === '<p style="text-align:left;">&nbsp;&nbsp;</p>' ||
      desTxt.trim() === '<p style="text-align:center;">&nbsp;&nbsp;</p>' ||
      desTxt.trim() === "<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>"
    ) {
      this.setState({
        errorDescriptionEdit: "Description is required",
      });
      return false;
    }
    this.state.letterListTemp[editIndex] = {
      id: editID,
      subTxt: subTxt,
      desTxt: desTxt,
    };
    this.setState({
      editview: false,
      btnActive: false,
      errorMessage: "",
    });

    memoriesService.updateLetterData(editID, subTxt, desTxt);
  };

  /**************************************    Letter edit/ view window Pop Up Option End  **************** **********************/

  deleteLetter() {
    const tempLetterID = this.state.deleteID;
    let successDel = memoriesService.deleteLetterData(tempLetterID);

    if (successDel) {
      const letterLists = this.state.letterLists.filter(
        (letter) => letter.id !== tempLetterID
      );
      this.setState({
        letterLists: letterLists,
        letterListTemp: letterLists,
      });
      this.setState({
        deleteLetter: false,
      });
    } else {
      window.alert("Delete Failed");
    }
  }

  /******generate pdf******/
  pdfGenerate = (content) => {
    docDefinition.content =
      content.subTxt +
      "\n\t" +
      content.desTxt.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");
    pdfMake.createPdf(docDefinition).download("ThisHeart - " + content.subTxt);
  };

  /****************************    Letter Section Search Bar Start  *********************************/

  handleSearchInputChange = (e) => {
    e.preventDefault();
    const srcText = document.getElementById("search_txt_letter").value;

    let letterListsLocal = [];
    if (srcText !== "") {
      letterListsLocal = this.state.letterLists.filter(
        (item) =>
          item.subTxt.toLowerCase().includes(srcText.toLowerCase()) ||
          item.desTxt.toLowerCase().includes(srcText.toLowerCase())
      );
      this.setState({
        letterListTemp: letterListsLocal,
        activeLetterSearch: "active",
      });
    } else {
      this.setState({
        letterListTemp: this.state.letterLists,
        activeLetterSearch: "",
      });
    }
  };

  /***************************** Letter Section Page Search Bar End  ******************************/

  /***************************    Memories Page Record Option start  ******************************/

  loadMoreRecordContent = () => {
    setTimeout(() => {
      if (this.state.loadingRecordCounter < this.state.recordLists.length) {
        let recordCounter = this.state.loadingRecordCounter + 2;
        this.setState({ loadingRecordCounter: recordCounter });
      }
      if (this.state.loadingRecordCounter >= this.state.recordLists.length) {
        this.setState({ hasMoreRecord: false });
      }
    }, 1000);
  };

  onDemandContentRecord = () => {

    return (
      <div>
        <InfiniteScroll
          dataLength={this.state.loadingRecordCounter}
          next={this.loadMoreRecordContent.bind(this)}
          hasMore={this.state.hasMoreRecord}
          className="top-pad"
        >
          {this.showRecords()}
        </InfiniteScroll>
      </div>
    );
  };

  showRecords = () => {
    let recordPath = apiUrl;

    let audioList = [];
    let tempRecordList = [];
    let recordCounter = 0;

    this.state.recordLists.length &&
      this.state.recordLists.map((item) => {
        if (recordCounter <= this.state.loadingRecordCounter) {
          tempRecordList.push(item);
        }
        recordCounter = recordCounter + 1;
      });

    if (tempRecordList.length !== 0) {
      if (this.state.userType === "bn") {
        audioList = tempRecordList.map((recorditem, i) => (
          <div key={i} className="audio-grid text-center">
            <div className="d-flex justify-content-center align-items-center">
              <audio controls style={browserCSS}>
                <source
                  className="record-set"
                  src={recordPath + "/" + recorditem.filename}
                  type="audio/mp3"
                />
              </audio>
            </div>
            <div className="text-div-record">
              <div className="head-title-record">{recorditem.title}</div>
              <br />
              <div className="date-record">
                Uploaded On{" "}
                {recorditem.updated_at
                  ? recorditem.updated_at.split(" ")[0]
                  : ""}
              </div>
            </div>
          </div>
        ));
      } else {
        let audioTmpList = [];

        tempRecordList.map((recorditem, i) => {
          if (recorditem.urlcheck === "audioFile") {
            let audioFile = (
              <div key={i} className="audio-grid text-center">
                <div className="d-flex justify-content-center align-items-center">
                  <audio controls style={browserCSS}>
                    <source
                      className="record-set"
                      src={recordPath + "/" + recorditem.filename}
                      type="audio/mp3"
                    />
                  </audio>
                </div>
                <div className="recordcorner">
                  {this.state.primary_user_id == recorditem.user_id ? ('') :
                    (<button
                      className="textrecord"
                      onClick={this.toggleDeleteRecord.bind(this, recorditem.id)}
                    >
                      X
                    </button>)}
                </div>
                <div className="text-div-record">
                  <div className="head-title-record">{recorditem.title}</div>
                  <br />
                  <div className="date-record">
                    Uploaded On{" "}
                    {recorditem.updated_at
                      ? recorditem.updated_at.split(" ")[0]
                      : ""}
                  </div>
                </div>
              </div>
            );
            audioTmpList.push(audioFile);
          } else if (recorditem.urlcheck === "audioLink") {
            let audioLink = (
              <div key={i} className="audio-grid">
                <li>
                  <a
                    href={recorditem.filename}
                    alt="auido url" rel="noopener noreferrer" target="_blank"
                  >
                    {recorditem.filename}
                  </a>
                </li>
                <div className="recordcorner">
                  <button
                    className="textrecord"
                    onClick={this.toggleDeleteRecord.bind(this, recorditem.id)}
                  >
                    X
                  </button>
                </div>
                <div className="text-div-record">
                  <div className="head-title-record">{recorditem.title}</div>
                  <br />
                  <div className="date-record">
                    Uploaded On{" "}
                    {recorditem.updated_at
                      ? recorditem.updated_at.split(" ")[0]
                      : ""}
                  </div>
                </div>
              </div>
            );
            audioTmpList.push(audioLink);
          }

          audioList = audioTmpList;
        });
      }
    } else {
      if (this.state.loadingRecords) {
        audioList = <p className="empty-msg-mem"> Loading Record Data... </p>;
      } else {
        audioList = (
          <div className="container">
            <div className="row" style={{ marginLeft: "6px" }}>
              <img
                src={zeroState}
                className="img-fluid mx-auto d-block"
                alt="love-pat"
                style={{ marginTop: "27px" }}
              />
            </div>
          </div>
        );
      }
    }
    return audioList;
  };

  selectRecords = (event) => {
    let selectrecords = [];
    let validInvalidMessage = "";
    for (var i = 0; i < event.target.files.length; i++) {
      selectrecords[i] = event.target.files.item(i);
    }

    selectrecords = selectrecords.filter((record) =>
      record.name.match(/\.(mp3|wav|m4a|mid|amr|oga|weba|wma|webm|ogg|aac|au)$/)
    );

    let selectedRecord = selectrecords[0];
    let recordName = selectedRecord.name.split(".")[0];
    this.setState({
      selectedRecord,
      recordName: recordName,
    });

    if (selectrecords.length > 0) {
      validInvalidMessage = `${selectrecords.length} valid records selected`;
      this.setState({
        validInvalidMessage,
      });
      this.setState({
        uploadBtnSts: "true",
      });
    } else {
      validInvalidMessage = `Invalid records selected`;
      this.setState({
        validInvalidMessage,
      });
      this.setState({
        uploadBtnSts: "false",
      });
    }
  };

  selectRecordsMultiple = (event) => {
    let selectrecords = [];
    let validInvalidMessage = "";

    for (var i = 0; i < event.target.files.length; i++) {
      selectrecords[i] = event.target.files.item(i);

      if (selectrecords[i].size > 5249338) {
        this.setState({
          errorFileAudio: "Audio file size is over of limit",
        });
        return 0;
      } else {
        this.setState({
          errorFileAudio: "",
        });
      }
    }

    selectrecords = selectrecords.filter((record) =>
      record.name.match(/\.(mp3|wav|m4a|mid|amr|oga|weba|wma|webm|ogg|aac|au)$/)
    );

    let selectedRecord = selectrecords;
    let recordName = selectedRecord ? selectedRecord[0].name.split(".")[0] : "";
    this.setState({
      selectedRecord,
      recordName: recordName,
    });

    if (selectrecords.length > 0) {
      validInvalidMessage = `${selectrecords.length} valid records selected`;
      this.setState({
        validInvalidMessage,
      });
      this.setState({
        uploadBtnSts: "true",
      });
    } else {
      validInvalidMessage = `Invalid records selected`;
      this.setState({
        validInvalidMessage,
      });
      this.setState({
        uploadBtnSts: "false",
      });
    }
  };

  handleRecordUpload = () => {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }

    let urlLink = "";
    const urlTypecheck = "audioFile";
    const audUrlTitle = "";

    let selectedRecord = this.state.selectedRecord;
    let recordList = this.state.recordLists;
    //let recordName = this.state.recordName;
    if (this.state.errorFileAudio) {
      return false;
    }

    memoriesService.storeRecordData(
      userId,
      urlTypecheck,
      urlLink,
      audUrlTitle,
      selectedRecord,
      recordList,
      this
    );
    this.setState({
      uploadingData: true,
      recordName: "",
    });
    this.loadMoreRecordContent();
  };

  deleteRecords() {
    const tempRecordID = this.state.deleteID;
    let successDel = memoriesService.deleteRecordData(tempRecordID);
    if (successDel) {
      const recordLists = this.state.recordLists.filter(
        (record) => record.id !== tempRecordID
      );
      this.setState({
        recordLists,
      });
      this.setState({
        deleteRecord: false,
      });
    } else {
      window.alert("Delete Failed");
    }
  }

  saveAudioLink = () => {
    let urlLink = document.getElementById("urlpathAudio").value;
    let audUrlTitle = document.getElementById("audtitle").value;

    if (urlLink.length < 1) {
      ToastsStore.warning("Add the required field");
      return false;
    }

    if (audUrlTitle.length < 1) {
      ToastsStore.warning("Add the required field");
      return false;
    }

    const urlTypecheck = "audioLink";

    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }
    let selectedRecord = this.state.selectedRecord;
    let recordList = this.state.recordLists;
    if (this.state.errorFileAudio) {
      return false;
    }

    memoriesService.storeRecordData(
      userId,
      urlTypecheck,
      urlLink,
      audUrlTitle,
      selectedRecord,
      recordList,
      this
    );
    this.setState({
      uploadingData: true,
      recordName: "",
    });
  };

  /*****************************    Memories Page Record Option End  ******************************/

  render() {
    const { item, videoitem, letteritem, recorditem } = this.state;
    const { imageToDelete } = this.state;
    return (
      <div className="home-container" id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Helmet>
          <meta charSet="utf-8" />
          <title> ThisHeart - Memories </title>
          <link rel="memories" href="http://thisheart.co/memories" />
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="container-fluid p-4">
            <Row>
              <Col sm="8" className="">

                <div className="enable-2fa-header">
                  <h1 className="account-header mr-3">MEMORIES</h1>
                  {/* <img className="d-flex align-items-center sm-hide" src={lineOfColours} alt="line of colours" /> */}
                </div>
                <p>
                  Store your memories here. Pictures, love letters, family
                  videos, recipes, save it all here for later. When the time is
                  right, we’ll share them in a beautiful way.
                </p>
              </Col>
              <Col sm="4" className="link-column" />
            </Row>
          </div>
          <div className="container-fluid">
            <TabContent
              className="tab-content"
              activeTab={this.state.activeTab}
            >
              <TabPane tabId="1">

                <Row>
                  <Col sm="12">
                    {/* {this.showAddNewBtnPhoto()} */}
                    <Modal
                      isOpen={this.state.modalphoto}
                      toggle={this.togglePhotoButton}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.togglePhotoButton}>
                        Add Photos
                      </ModalHeader>
                      <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
                        <form
                          className="form-container"
                          method="post"
                          id="myForm"
                        >

                          {/* Add Image Title */}
                          <label>
                            <Input
                              id="img_title"
                              type="text"
                              name="image_title"
                              defaultValue={this.state.imageTitle}
                              placeholder="Image Title"
                              size="60"
                              required
                              onChange={e => this.setState({ imageTitle: e.target.value })}
                              style={{ backgroundColor: 'white', border: '1px solid #000', marginTop: '1px', borderRadius: '4px', height: '35px' }}
                            />
                          </label>

                          {/* Add Image Description */}
                      
                            <Input
                              id="img_desc"
                              type="textarea"
                              name="image_description"
                              className="form-control textarea w-100"
                              defaultValue={this.state.imageDescription}
                              placeholder="Image Description"
                              size="60"
                              required
                              onChange={e => this.setState({ imageDescription: e.target.value })}
                              style={{ backgroundColor: 'white', border: '1px solid #000', marginTop: '1px', borderRadius: '4px', height: '80px' }}
                            />
                       

                          <label className="text-black" style={{ fontWeight: 'bold' }}>
                            Select Your Photo (
                            <span>maximum 20 files</span>)
                            <br />
                            <ul style={{ cursor: "pointer", paddingLeft: "0px" }}>
                              <li className="mt-3" style={{
                                display: "inline-block",
                                padding: "10px 20px",
                                fontSize: "16px",
                                color: "#7f8c8d",
                                backgroundColor: "white",
                                borderRadius: "4px",
                                textAlign: "center",
                                cursor: "pointer",
                                width: "100%",
                              }}>
                                {this.imageFileUpload()}
                              </li>
                            </ul>

                          </label>
                          <p
                            className={
                              this.state.uploadBtnSts === "false"
                                ? "text-danger"
                                : "text-info"
                            }
                          >
                            {this.state.validInvalidMessage}
                          </p>
                        </form>

                      </ModalBody>
                      <ModalFooter>
                        <Button className="btn btn-danger" onClick={this.handleAttachImageFileUpload} style={{ backgroundColor: "#ef6e6e", color: "#fff", borderRadius: "7px", }}>
                          Upload
                        </Button>
                        <Button onClick={this.togglePhotoButton} style={{ backgroundColor: 'black', color: '#f6dc20', borderRadius: "7px", }}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
                <div className="row container-fluid">
                  <div className="px-3 col-xs-12 col-sm-12 col-md-12">
                    <div className="searchBarMemory">
                      <Form onSubmit={this.handleSearchInputChange}>
                        <FormGroup className="d-flex w-100">
                          <Input
                            className="control-box"
                            type="search"
                            name="search"
                            id="search_txt"
                            placeholder="Search for"
                            onChange={this.handleSearchInputChange}
                            style={{
                              border: '1px solid #ced4da',
                              borderRadius: '0.15rem',
                            }}
                          />
                          <button
                            type="submit"
                            className="btn btn-warning search-button px-3"
                            style={{
                              backgroundColor: '#FFD700',
                              borderColor: '#FFD700',
                              padding: '5px 25px',
                              color: '#000000',
                              height: '40px',
                              margin: '5px 0',
                            }}
                          >
                            Search
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
                {/* <Row>{this.onDemandContentImages()}</Row> */}
                {/* Image Section */}
                <Row className="mb-5">
                  <div className="px-4 col-xs-12 col-sm-12 col-md-12 justify-content-between">
                    <div className="row">
                      <div className="d-flex col-6 col-md-6 col-sm-6 align-items-center" style={{ paddingBottom: '10px' }}>
                        <h3 className="m-0">Photos</h3>
                        {/* <div className="pt-1 ml-2 d-lg-block d-none"> <img src={lineOfColours} /></div> */}
                        {/* <div className="pt-1 ml-2 d-lg-block d-none"></div> */}
                      </div>
                      <div className="d-flex col-6 col-md-6 col-sm-6 justify-content-end" style={{ paddingBottom: '10px' }}>
                        <div className="d-flex mr-3">
                          <div>{this.showAddNewBtnPhoto()}</div>
                          <div>{this.showViewMorePhotos()}</div>
                        </div>
                        {/* <div className="col-2 align-items-center">View More</div>
                        <div className="col-2 align-items-center">Add Photos</div> */}
                      </div>
                    </div>
                    {/* <div className="align-items-center">{this.showAddNewBtnPhoto()}</div> */}

                    {this.renderImagesWithPlaceholders()}
                  </div>
                </Row>
                {/* Modal */}
                <Modal isOpen={this.state.imageOpen} toggle={this.handleImagePopUp}>
                  <ModalHeader toggle={this.handleImagePopUp}>Memories Image</ModalHeader>
                  <ModalBody>
                    <div className="img-effect">
                      <img src={this.state.imgPathModal} className="mediaView" alt="Selected" style={{ width: '100%' }} />
                    </div>
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={this.state.deleteImg}
                  toggle={this.toggleDeleteMemoryImg}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.toggleDeleteMemoryImg}>Memories Image</ModalHeader>
                  <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>

                    <p>Are you sure you want to delete this item ?</p>
                    <div className="img-effect">
                      <img src={this.state.imgPathModal} className="mediaView" alt="Selected" style={{ width: '100%' }} />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      type="button"
                      onClick={this.deleteMemoriesImages}
                    >
                      Delete
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      onClick={this.toggleDeleteMemoryImg}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
                {/* Video Section */}
                <Row className="mb-5">
                  <div className="px-4 col-xs-12 col-sm-12 col-md-12 justify-content-between">
                    <div className="row justify-content-lg-between align-items-center">
                      <div className="d-flex col-6 col-lg-6 col-md-6 col-sm-6 align-items-center" style={{ paddingBottom: '10px' }}>
                        <div><h3 className="m-0">Videos</h3></div>
                        {/* <div className="pt-1 ml-2 d-lg-block d-none"> </div> */}
                        {/* <div className="pt-1 ml-2 d-lg-block d-none"> <img src={lineOfColours} className="" /></div> */}
                      </div>
                      <div className="d-flex col-6 col-lg-6 col-md-6 col-sm-6 justify-content-end align-items-center" style={{ paddingBottom: '10px' }}>
                        <div className="d-flex mr-3">
                          <div>{this.addNewVideo()}</div>
                          <div>{this.showViewMoreVideos()}</div>
                        </div>
                      </div>
                    </div>
                    {this.renderVideosWithPlaceholders()}
                    {/* {this.renderMoreVideoButton()} */}
                  </div>
                </Row>
                {/* Audio Section  */}
                <Row className="mb-5">
                  <div className="px-4 col-xs-12 col-sm-12 col-md-12 justify-content-between">
                    <div className="row justify-content-lg-between align-items-center">
                      <div className="d-flex col-6 col-lg-6 col-md-6 col-sm-6 align-items-center" style={{ paddingBottom: '10px' }}>
                        <div><h3 className="m-0">Audios</h3></div>
                        {/* <div className="pt-1 ml-2 d-lg-block d-none"></div> */}
                        {/* <div className="pt-1 ml-2 d-lg-block d-none"> <img src={lineOfColours} className="" /></div> */}
                      </div>
                      <div className="d-flex col-6 col-lg-6 col-md-6 col-sm-6 justify-content-end align-items-center" style={{ paddingBottom: '10px' }}>
                        <div className="d-flex mr-3">
                          <div>{this.addNewAudioRecord()}</div>
                          <div>{this.showViewMoreAudios()}</div>
                        </div>
                      </div>
                    </div>
                    {this.renderAudiosWithPlaceholders()}
                  </div>
                </Row>
                {/* Letter Section */}
                <Row className="mb-5">
                  <div className="px-4 col-xs-12 col-sm-12 col-md-12 justify-content-between">
                    <div className="row justify-content-lg-between align-items-center">
                      <div className="d-flex col-6 col-sm-6 align-items-center " style={{ paddingBottom: '10px' }} >
                        <div><h3 className="m-0">Letters</h3></div>
                        {/* <div className="pt-1 ml-2 d-lg-block d-none"></div> */}
                        {/* <div className="pt-1 ml-2 d-lg-block d-none"><img src={lineOfColours} /></div> */}
                      </div>
                      <div className="d-flex col-6 col-sm-6 justify-content-end align-items-center  " style={{ paddingBottom: '10px' }}>
                        <div className="d-flex mr-3">
                          <div>{this.addNewLetters()}</div>
                          <div>{this.showViewMoreLetters()}</div>
                        </div>
                      </div>
                    </div>
                    {this.renderLettersWithPlaceholders()}
                  </div>
                </Row>
                {/* Social Section */}
                <Row className="mb-5">
                  <div className="px-4 col-xs-12 col-sm-12 col-md-12 justify-content-between">
                    <div className="row justify-content-lg-between align-items-center mb-2">
                      <div className="d-flex col-12 col-lg-4 align-items-center">
                        <h3>From Social</h3>
                        {/* <div className="pt-1 ml-2 d-lg-block d-none"><img src={lineOfColours} alt="Line of Colours" /></div> */}
                        {/* <div className="pt-1 ml-2 d-lg-block d-none"></div> */}
                      </div>
                      <div className="d-flex col-12 col-lg-8 justify-content-end align-items-center">
                        <div className="mr-3">
                          {this.hiddenSocialBtn()}
                        </div>
                      </div>
                    </div>
                    {this.renderSocialWithPlaceholders()}
                  </div>
                </Row>

              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="6">
                    {this.showAddNewBtnVideo()}

                    <Modal
                      isOpen={this.state.modalvideo}
                      toggle={this.toggleVideoButton}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleVideoButton}>
                        Add Videos
                      </ModalHeader>
                      <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
                        <form className="form-container" method="post" id="myVideo">
                          {/* Add Video Title */}
                          <label className="mb-0">
                            <input
                              id="vid_title"
                              type="text"
                              name="video_title"
                              className="mb-0"
                              defaultValue={this.state.videoTitle}
                              placeholder="Video Title"
                              size="60"
                              required
                              onChange={e => this.setState({ videoTitle: e.target.value, videoTitleError: '' })}
                              style={{ backgroundColor: 'white', border: '1px solid #000', marginTop: '1px', borderRadius: '4px', height: '35px' }}
                            />
                          </label>
                          <span className={this.state.videoTitleError ? 'text-danger' : 'text-info'} style={{ marginTop: '-25px' }}>
                            {this.state.videoTitleError}
                          </span>

                          {/* Add Video Description */}
                          <div className="mt-5">
                            <Input
                              id="vid_desc"
                              type="textarea"
                              name="video_description"
                              defaultValue={this.state.videoDescription}
                              placeholder="Video Description"
                              size="60"
                              required
                              onChange={e => this.setState({ videoDescription: e.target.value })}
                              style={{ backgroundColor: 'white', border: '1px solid #000', marginTop: '1px', borderRadius: '4px', height: '80px' }}
                            />
                          </div>
                          <div>
                            Select Video (
                            <span className="text-black" style={{ fontWeight: 'bold' }}>
                              max 100mb & type: MP4
                            </span>
                            )
                            <br />
                            <div className="container-fluid">
                              <ul style={{ cursor: 'pointer', padding: 0 }}>
                                <li onClick={this.urlToggleVideo} className="mt-3 upload-option">
                                  Upload Url or Link
                                </li>
                                <li className="mt-3 upload-option"  >
                                  {this.fileUploadThreadHtml()}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className={this.state.errorFileVideo ? 'text-danger' : 'text-info'}>
                            {this.state.validInvalidMessage} <br />
                            {this.state.errorFileVideo}
                          </p>
                        </form>
                      </ModalBody>
                      <ModalFooter>
                        <Button className="bg-thisheart" style={{ color: '#000', borderRadius: '4px' }} onClick={() => this.handleAttachVideoFileDownload()}>
                          Upload Video
                        </Button>
                        <Button style={{ backgroundColor: 'black', color: '#f6dc20', borderRadius: '4px' }} onClick={() => this.toggleVideoButton()}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                  <Col sm="6">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="searchBarMemory">
                        <Form onSubmit={this.handleSearchInputChange}>
                          <FormGroup>
                            <span
                              className="search-icon-mem"
                              onClick={this.handleSearchInputChange}
                            >
                              <FaSearch />
                            </span>
                            <Input
                              className="control-box"
                              type="search"
                              name="search"
                              id="search_txt"
                              placeholder="Search for"
                              onChange={this.handleSearchInputChange}
                            />
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="12">
                    <div>{this.onDemandContentVideo()}</div>
                  </Col>
                </Row>
              </TabPane>



              {/********************* External video/Audio Link show Start  *************************/}

              <TabPane tabId="3"></TabPane>

              {/********************** External video/Audio Link show End  ************************/}

              <TabPane tabId="4">
                <div className="conatiner-fluid">
                  <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-6 ">
                      {this.showAddNewBtnLetter()}
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <div className="searchBarMemory">
                        <Form onSubmit={this.handleSearchInputChange}>
                          <FormGroup>
                            <span
                              className="search-icon-mem"
                              onClick={this.handleSearchInputChange}
                            >
                              <FaSearch />
                            </span>
                            <Input
                              className="control-box"
                              type="search"
                              name="search"
                              id="search_txt_letter"
                              placeholder="Search for"
                              onChange={this.handleSearchInputChange}
                            />
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col sm="12">
                    <Modal
                      isOpen={this.state.modalletter}
                      toggle={this.toggleLetterButton}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleLetterButton}>
                        Add Letters
                      </ModalHeader>

                      <ModalBody className="form-popup" style={{ overflow: 'hidden', overflowY: 'auto' }}>
                        <Form
                          className="form-container-letter"
                          style={{ overflow: 'hidden' }}
                        >
                          <FormGroup className="required">
                            <Label className="letter-lbl">
                              <b className="control-label">Subject</b>
                              <br />
                              <Input
                                type="text"
                                name="subject"
                                id="sub_txt"
                                placeholder="Subject of letter"
                                onChange={this.handleLetterChange}
                              />
                            </Label>
                          </FormGroup>
                          {this.subjectValidation()}
                          <FormGroup className="required custom-description-height mb-0">
                            <Label>
                              <b className="control-label">Description</b>
                              <br />
                              {this.textEditorPanel()}
                              {this.state.errorDescription && (
                                <div className="alert alert-danger">
                                  {this.state.errorDescription}
                                </div>
                              )}

                            </Label>
                            <div className="btn-saveletter">
                              {this.state.loadingLetters && (
                                <img
                                  className="loader-img"
                                  alt="loaderImg Letter"
                                  src={loaderImg}
                                />
                              )}
                              {/* Move the onSubmit to the button */}
                              <Button
                                color="danger"
                                style={{ borderRadius: "7px" }}
                                onClick={this.handleSubmitLetter.bind(this)}
                              >
                                Save
                              </Button>
                            </div>
                          </FormGroup>
                        </Form>
                      </ModalBody>

                    </Modal>
                  </Col>
                </Row>
                <div className="row">
                  <div className="col-xs-12 col-sm-12">
                    <div className="letterListView">
                      {this.onDemandContentLetter()}
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  <Col sm="6">
                    {this.showAddNewBtnRecord()}
                    <Modal
                      isOpen={this.state.modalrecord}
                      toggle={this.toggleRecordButton}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleRecordButton}>
                        Add Audios
                      </ModalHeader>
                      <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
                        <form className="form-container" name="myForm" method="post">

                          <div>
                            <input
                              id="rec_name"
                              type="text"
                              name="record_title"
                              defaultValue={this.state.recordTitle}
                              placeholder="Record File"
                              accept="audio/*"
                              size="60"
                              required
                              onChange={e => this.setState({ recordTitle: e.target.value, recordTitleError: '' })}
                              style={{ backgroundColor: 'white', border: '1px solid #000', marginTop: '1px', borderRadius: '4px', height: '35px' }}
                            />
                            <p className={this.state.recordTitleError ? 'text-danger' : 'text-info'} style={{ marginTop: '-20px' }}>{this.state.recordTitleError} </p>
                          </div>

                          <div>
                            Select Record (
                            <span className="text-danger">
                              max 5mb & type: MP3, WAV
                            </span>
                            )
                            <br />
                            <ul className="list-group" style={{ cursor: "pointer" }}>
                              <li className="list-group-item list-group-item-action mt-1 bg-light border-0">
                                <Input
                                  id="rec_select"
                                  type="file"
                                  name="filename"
                                  // onChange={this.selectRecordsMultiple}
                                  onChange={this.handleAttachAudioFile}
                                  accept="audio/mp3, audio/*"
                                  multiple
                                  required
                                />
                              </li>
                              {/* <li onClick={this.urlToggleAudio}>
                                Upload Url or Link
                              </li> */}
                            </ul>
                          </div>
                          <p
                            className={
                              this.state.errorFileAudio
                                ? "text-danger"
                                : "text-info"
                            }
                          >
                            {this.state.validInvalidMessage} <br />
                            {this.state.errorFileAudio}
                          </p>
                        </form>

                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={() => this.handleAttachAudioFileDownload()} className="bg-thisheart" style={{ float: "right", color: "#000", borderRadius: "7px" }}>
                          Upload Recording Audio
                        </Button>
                        <Button onClick={() => this.toggleRecordButton()} style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                  <Col sm="6">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="searchBarMemory">
                        <Form onSubmit={this.handleSearchInputChange}>
                          <FormGroup>
                            <span
                              className="search-icon-mem"
                              onClick={this.handleSearchInputChange}
                            >
                              <FaSearch />
                            </span>
                            <Input
                              className="control-box"
                              type="search"
                              name="search"
                              id="search_txt"
                              placeholder="Search for"
                              onChange={this.handleSearchInputChange}
                            />
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div>{this.onDemandContentRecord()}</div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row>
                  <Col sm="12" className="photo-column">
                    {this.hiddenSocialBtn()}
                  </Col>
                  <Col sm="12">
                    {this.state.loadingSocialData && (
                      <img
                        className="loader-img"
                        alt="loaderImg"
                        src={loaderImg}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="photo-column">
                    <div className="social-img-grid">
                      <p className="text-center text-danger h4">
                        {this.state.socialImageError}
                      </p>
                      {this.socialImageList()}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="social-vid-grid"></div>
                  </Col>
                </Row>
              </TabPane>

              {/****************** Social Tabpane Start End *********************/}
            </TabContent>
          </div>
          {/* /************************** Letter section Pop up Window After Click On View Button Start *****************************/}
          <div>
            <Modal isOpen={this.state.preview} className={this.props.className + ' modal-lg'}>
              <ModalHeader toggle={this.toggleLetterViewButton}>
                View Letter
              </ModalHeader>
              <ModalBody className="form-popup">
                <Form className="mem-letter">
                  <Label>
                    <b> Subject: </b> <br />
                  </Label>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.subj,
                    }}
                  />
                  <br />
                  <Label>
                    <b> Description: </b> <br />
                  </Label>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.desc,
                    }}
                  />
                </Form>
                <Button
                  color="danger"
                  type="button"
                  onClick={this.toggleLetterViewButton}
                  style={{ float: "right" }}
                >
                  Close
                </Button>
              </ModalBody>
            </Modal>
          </div>
          {/* /*********************** Letter section Pop up Window After Click On View Button End *************************/}

          {/* /**********************  Letter section Pop up Window After Click On Edit Button Start ***********************/}
          <div>
            <Modal
              isOpen={this.state.editview}
              toggle={this.toggleLetterEditButton}
              className={this.props.className}
              size="lg"
            >
              <ModalHeader toggle={this.toggleLetterEditButton}>
                Edit Letter
              </ModalHeader>
              <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
                <Form
                  method="post"
                  onSubmit={this.saveChanges}
                  className="form-container-letter"
                >
                  <FormGroup className="required">
                    <Label className="letter-lbl control-label">
                      <b>Subject:</b>
                    </Label>
                    <Input
                      type="text"
                      name="subj"
                      id="subj"
                      required
                      value={this.state.editSubject}
                      onChange={this.onChangeSubject}
                    />
                    {this.state.errorSubjectEdit ? (
                      <div className="alert alert-danger">
                        {this.state.errorSubjectEdit}
                      </div>
                    ) : (
                      ""
                    )}
                  </FormGroup>

                  <FormGroup className="required">
                    <Label className="view-label control-label">
                      {" "}
                      Description:{" "}
                    </Label>
                    <Editor
                      editorState={this.state.editorEditState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      name="desc"
                      id="desc"
                      required
                      onEditorStateChange={this.onChangeDescription}
                    // localization={localization}
                    />

                    {this.state.errorDescriptionEdit ? (
                      <div className="alert alert-danger">
                        {this.state.errorDescriptionEdit}
                      </div>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                  <Button
                    color="danger"
                    className="btn-right"
                    type="submit"
                    disabled={this.state.btnActive === false}
                  >
                    Save changes
                  </Button>
                </Form>
              </ModalBody>
            </Modal>
          </div>
          {/* /*********************  Letter section Pop up Window After Click On Edit Button End ***********************/}

        </div>
        {/* /****************************** Delete Image Custom Pop Up Start ****************************/}
        {/* <div>
        <Modal
          isOpen={this.state.deleteImg}
          toggle={this.toggleDeleteImg}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeleteImg}>
            Memories Image
          </ModalHeader>
          <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
            <p> Are you sure, want to Delete this item Hi? </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              type="button"
              onClick={this.deleteImages.bind(this, item)}
            >
              Delete
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={this.toggleDeleteImg}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div> */}
        {/* /****************************** Delete Image Custom Pop Up End ****************************/}

        {/* /****************************** Delete Video Custom Pop Up Start ****************************/}
        <div>
          <Modal
            isOpen={this.state.deleteVid}
            toggle={this.toggleDeleteVideo}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteVideo}>
              Memories Video
            </ModalHeader>
            <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
              <p> Are you sure, want to Delete this item ? </p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deleteVideos.bind(this, videoitem)}
              >
                Delete
              </Button>
              <Button
                color="primary"
                type="button"
                onClick={this.toggleDeleteVideo}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* /****************************** Delete Video Custom Pop Up End ****************************/}

        {/* /****************************** Delete Letter Custom Pop Up Start ****************************/}
        <div>
          <Modal
            isOpen={this.state.deleteLetter}
            toggle={this.toggleDeleteLetter}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteLetter}>
              Memories Letter
            </ModalHeader>
            <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
              <p>
                Are you sure you want to delete this letter? You won't be able
                to get it back after you do.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deleteLetter.bind(this, letteritem)}
              >
                Delete
              </Button>
              <Button
                color="primary"
                type="button"
                onClick={this.toggleDeleteLetter}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* /****************************** Delete Letter Custom Pop Up End ****************************/}

        {/* /****************************** Delete Record Custom Pop Up Start ****************************/}
        <div>
          <Modal
            isOpen={this.state.deleteRecord}
            toggle={this.toggleDeleteRecord}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteRecord}>
              Memories Record
            </ModalHeader>
            <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
              <p> Are you sure, want to Delete this item ? </p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deleteRecords.bind(this, recorditem)}
              >
                Delete
              </Button>
              <Button
                color="primary"
                type="button"
                onClick={this.toggleDeleteRecord}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* /****************************** Delete Record Custom Pop Up End ****************************/}

        {/***************************** Image Pop Up View Start ************************************/}
        {/* <Modal
        isOpen={this.state.imageOpen}
        toggle={this.handleShowDialog}
        className={`modalImagePreview ${this.props.className}`}
        size="lg"
      >
        <ModalHeader toggle={this.handleShowDialog}>View Image</ModalHeader>
        <ModalBody style={{ overflow: 'hidden' }}> {this.popImageView()} </ModalBody>
      </Modal> */}
        {/***************************** Image Pop Up View End ************************************/}

        {/************************* Image Pop Up DeleteSocial Start ******************************/}
        <div>
          <Modal
            isOpen={this.state.socialImgDel}
            toggle={this.toggleDeleteImgSocial}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteImgSocial}>
              Social Image
            </ModalHeader>
            <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
              <p> Are you sure, want to Delete this item ? </p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.socialImageDelete.bind(this, this.state.deleteID)}
              >
                Delete
              </Button>
              <Button
                color="primary"
                type="button"
                onClick={this.toggleDeleteImgSocial}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/************************* Image Pop Up DeleteSocial End *******************************/}

        {/************************* Modal URL add extra video *******************************/}

        <div>
          <Modal
            isOpen={this.state.urlLinkVideo}
            toggle={this.urlToggleVideo}
            className={this.props.className + ' modal-lg'}
          >
            <ModalHeader toggle={this.urlToggleVideo}>Link or Url </ModalHeader>

            <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
              <Form className="form-container" method="post">
                <Label>
                  <b>Title </b>
                </Label>
                <Input
                  id="urltitle"
                  type="text"
                  name="urltitle"
                  placeholder="URL title"
                  required
                />
                <Label>
                  {" "}
                  <b>Video URL</b>
                </Label>
                <Input
                  id="urlpath"
                  type="text"
                  name="urlpath"
                  placeholder="Enter Url or Link"
                  onChange={this.onChangeUrlVideo}
                  required
                />
                {this.state.errorUrl && (
                  <div className="text-danger">{this.state.errorUrl}</div>
                )}
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.saveVideosLink}
              >
                Save
              </Button>
              <Button
                color="primary"
                type="button"
                onClick={this.urlToggleVideo}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/************************* Modal URL add extra Audio*******************************/}


        {/************************* Modal Video player  *******************************/}

        <div>
          <Modal
            isOpen={this.state.modalVideoPlayer}
            toggle={this.toggleVideoPlayer}
            className={this.props.className + ' modal-lg'}
          >
            <ModalHeader toggle={this.toggleVideoPlayer}>{this.state.modalVideoPlayerVideoFile.title} </ModalHeader>

            <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
              {
                this.videoPlayerContent()
              }

            </ModalBody>
            <ModalFooter>

              <Button
                color="primary"
                type="button"
                onClick={this.toggleVideoPlayer}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/************************* Modal Video player *******************************/}

        <UploadProgress memoriesState={this} />
      </div>
    );
  }

  videoPlayerContent = () => {
    let playerContent = ""
    if (this.state.modalVideoPlayerType === "videoLink") {

      const opts = {
        height: '450',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };

      playerContent = (
        <YouTube videoId={this.state.modalVideoPlayerUrl} opts={opts} autoplay={true} onReady={this._onReady} />
      )
    } else {
      playerContent = (
        <video
          width="100%"
          height="90%"
          controls
          poster={loadVideoPlay}
          style={{
            outline: "none",
          }}
        >
          <source src={apiUrl + "/" + this.state.modalVideoPlayerUrl} />
        </video>)
    }
    return playerContent
  }
}

const mapDispatchToProps = dispatch => ({
  setUploadFile: (files, filesType) => dispatch(setUploadFile(files, filesType)),
})

export default connect(null, mapDispatchToProps)(Memories);
