import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/accessbeneficiary.css";
import { Helmet } from "react-helmet";
import logoImage from "../pages/images/logo-img.png";
import bnAccessBg from "../pages/images/bnaccessbg.jpeg";
import bnAccessBody from "../pages/images/thisheartweb/web.png";
import bnAccessBodyTab from "../pages/images/beneficiaryAccessTab.png";
import {
  Media,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import { beneficiaryUserService } from "../services/beneficiary.user.service";

class BeneficiaryAccess extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    this.state = {
      accessCode: "",
      primaryUserId: 0,
      beneficiaryId: 0,
      messages: "",
      nocodeMessage: false,
      userObj: JSON.parse(user),
    };
  }

  handleChange = (e) => {
    this.setState({ accessCode: e.target.value });
  };

  submitBenefiCode = (e) => {
    e.preventDefault();
    let urlWithCode = window.location.href;
    beneficiaryUserService.validateAccessCode(
      this.state.accessCode,
      urlWithCode,
      this
    );
    console.log("submitBenefiCode URL COde:", urlWithCode);
  };

  toggleNoCode = () => {
    this.setState((prevState) => ({
      nocodeMessage: !prevState.nocodeMessage,
    }));
  };

  render() {
    return (
      <div className="home-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-Beneficiary Access</title>
        </Helmet>
        <div className="header-body ">
          <Row >
            {/* <Media src=" " object className="body-image" /> */}
            <Col sm="3">
              <Link to="/login">
                <Media className="home-logo-acc" left>
                  <Media object src={logoImage} alt="image" />
                </Media>
              </Link>
            </Col>
          </Row>
        
          <Row className="d-flex">
            <Row className="d-flex col-sm-6 col-lg-6 col-md-6 justify-content-center align-items-center">
                <div>
                  <Form method="post" onSubmit={this.submitBenefiCode}>
                    <FormGroup>
                      <Label className="text">
                        Enter Invited Access Code
                        <Input
                          className="input-box-size"
                          type="text"
                          name="beneficode"
                          id="beneficode"
                          placeholder="Enter Access Code"
                          required
                          onChange={this.handleChange}
                        />
                      </Label>
                      <Button className="btn-go" name="socialCode">
                        Go
                      </Button>
                      <div className="errormesgbene">{this.state.messages}</div>
                    </FormGroup>
                    <div className="textcode">
                      <button className="nocodetext" onClick={this.toggleNoCode}>
                        No Code?
                      </button>
                    </div>
                  </Form>
                 </div>
          
              {/* <Row>
                <Col sm="4">
                  <div className="textcode">
                    <button className="nocodetext" onClick={this.toggleNoCode}>
                      No Code?
                    </button>
                  </div>
                </Col>
              </Row> */}
            </Row>
            <Row className="d-flex col-sm-6 col-lg-6 col-md-6 mx-1">
                <div className="d-flex">
                  <img src={bnAccessBody} alt="Beneficiary Access Body" className="img-fluid" />
                </div>
            </Row>
        </Row>
          
        </div>
        <div>
          <Modal
            isOpen={this.state.nocodeMessage}
            toggle={this.toggleNoCode}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleNoCode}>
              Access Invitaion
            </ModalHeader>

            <ModalBody className="form-popup">
              <b>You must be invited to access this feature</b>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" type="button" onClick={this.toggleNoCode}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* <Footer {...this.props} /> */}
      </div>
    );
  }
}
export default BeneficiaryAccess;
