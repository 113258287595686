import React, { Component } from "react";
import {
  Media,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
 
import loaderImg from "./images/loader.gif";
import logoImage from "./images/thisheart_group.svg";
import contributors_new_registration from "./images/contributors_new_registration.svg";
import profilePhoto from "../pages/images/dp.png";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import "./styles/registerbeneficiary.css";
import "../components/asterisk.css";
import PhoneInput from "react-phone-number-input";
import { beneficiaryUserService } from "../services/beneficiary.user.service";
import { Helmet } from "react-helmet";
import zxcvbn from "zxcvbn";
import { ToastsStore } from "react-toasts";
import { useSearchParams, useLocation   } from 'react-router-dom';
import { decrypt_url } from "../helpers/encrypt-url";


class ContributorsRegistration extends Component {
  constructor(props) {
    super(props);
    const stateTmp = this.props.location.state;
    const email_url=this.props.match.params?this.props.match.params.email_url:'';
    const full_name_url=this.props.match.params?decrypt_url(this.props.match.params.full_name_url):'';
    const primary_user_id=this.props.match.params?this.props.match.params.primary_user_id:'';
    const queryParams = new URLSearchParams(window.location.search);
    const contributor_id = queryParams.get('contributor');
  
    console.log("url page state: ", email_url, full_name_url,primary_user_id,contributor_id);
 
  
    this.state = {
        email: email_url,
        password: "",
        phoneNumber: "",
        userName: full_name_url,
        confirmPassword: "",
        passwordVisible: "password",
        lastFourSocial: stateTmp?stateTmp.last_4_beneficiary:'',
        primaryUserId: primary_user_id,
        beneficiaryId: 0,
        submitted: false,
        loading: false,
        regFailed: false,
        errorMessage: { email: "", mobile: "", other: "", social: "" },
        statusCode: "",
        regErrors: "",
        errorPassword: [],
        passwordScore: "",
        email_url:email_url,
        full_name_url: full_name_url,
        contributor_id:contributor_id
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
    if (name === "password") {
      const passwordSuggent = zxcvbn(value);
      this.setState({
        passwordScore: passwordSuggent.score,
        errorPassword: passwordSuggent.feedback.suggestions,
      });
    }
  }

  handleSubmit(e) {
    
    e.preventDefault();
    if (this.state.password.length < 8 || this.state.passwordScore < 3) {
      ToastsStore.warning("Password length isn't in correct format!");
      return;
    }
    
    const { email, password, lastFourSocial } = this.state;
    if (this.state.password.length < 8) {
      ToastsStore.warning("Password isn't in correct format!");
      return;
    }

    if (this.state.lastFourSocial.length != 4) {
      this.setState({
        errorMessage:{
          social:'Social code is invalid!'
        }
      })
      return;
    }

    console.log('i m here...')

    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    if (email && password && lastFourSocial) {
      this.setState({ loading: true });
      beneficiaryUserService.registerContributorsUser(
        this.state.email,
        this.state.password,
        this.state.lastFourSocial,
        this.state.userName,
        this.state.phoneNumber,
        this
      );
    }
  }

  emailValidation() {
    if (this.state.submitted && !this.state.email) {
      return <div className="help-block-bnreg-login">Email is required</div>;
    } else if (
      this.state.submitted === true &&
      this.state.regFailed === true &&
      this.state.statusCode === 406
    ) {
      return (
        <div className="help-block-bnreg-login">{this.state.errorMessage}</div>
      );
    }
  }

  passwordValidation() {
    if (this.state.submitted && !this.state.password) {
      return <div className="help-block-bnreg">Password is required</div>;
    } else if (this.state.submitted && this.state.password.length < 8) {
      return (
        <div className="help-block-bnreg">
          Password is too short. Minimum length is 8 characters.
        </div>
      );
    }

    return <div className="help-block-bnreg" />;
  }

  passwordConfirmation = () => {
    if (
      this.state.submitted &&
      this.state.password !== this.state.confirmPassword
    ) {
      return <div className="help-block-bnreg">Password does not match</div>;
    } else {
    }
    return <div className="help-block-bnreg" />;
  };

  socialNumberValidity = () => {
    if (  this.state.submitted && this.state.lastFourSocial.length !== 4) {
      return <div className="help-block-bnreg">Fill the last 4 number</div>;
    } else if (
      this.state.submitted === true &&
      this.state.regFailed === true &&
      this.state.statusCode === 401
    ) {
      return (
        <div className="help-block-bnreg-login">{this.state.errorMessage}</div>
      );
    } else if (
      this.state.submitted === true &&
      this.state.regFailed === true &&
      this.state.statusCode === 402
    ) {
      return (
        <div className="help-block-bnreg-login">{this.state.errorMessage}</div>
      );
    }
    return <div className="help-block-bnreg"></div>;
  };

  phoneNumberValidation() {
    if (this.state.submitted && !this.state.phoneNumber) {
      return (
        <div className="help-block-bnreg">
          Phone number is required. {this.state.regErrors.mobile}
        </div>
      );
    }
    return (
      <div className="help-block-bnreg">{this.state.regErrors.mobile}</div>
    );
  }

  phoneInputField = () => {
    let phoneNumber = "";
    phoneNumber = (
      <div className="phone-registerbn">
        <PhoneInput
          name="phonenumber"
          placeholder="Enter phone number"
          required
          value={this.state.phoneNumber}
          onChange={(phoneNumber) =>
            this.setState({ phoneNumber, errorMessage: { mobile: "" } })
          }
        />
      </div>
    );
    return phoneNumber;
  };

  eyeIcon = (regPass) => {
    if (regPass) {
      if (this.state.passwordVisible === "password") {
        this.setState({ passwordVisible: "text" });
      } else {
        this.setState({ passwordVisible: "password" });
      }
    }
  };

  render() {
    return (
      <div className=" container-fluid">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-Contributors Registration</title>
        </Helmet>

        <div id="header" className="fixed-top mb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 logo " style={{marginTop:'-15px'}}>
                <img
                  src={logoImage}
                  onClick={this.redirectImgLink}
                  alt="logo"
                  className="img-responsive"
                  style={{ cursor: "pointer" }}
                ></img>
              </div>
              <div className="col-md-7"></div>
              <div className="col-md-2"></div>
  
            </div>{/**** end top row ****/}
          </div>
        </div>
        <div className="container">
        <div className="row" style={{marginTop:'100px'}}>
          
          <div className="col-lg-8 col-sm-12 col-xs-12 border registration-container">
            <div className="row  ">
              <div className=" col-lg-8 registration-div border p-0 " style={{borderRadius:'15px', overflowY:'auto'}}>
                 
                <div className="bg-thisheart p-2">
                  <h3 className="">Create Account For Contributors</h3>
                  <div className="">
                    Now that you're here, create an account so you can easily come
                    back.
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                <Form className="bnregister-form" onSubmit={this.handleSubmit}>
                  <FormGroup className="required">
                    <Label className="lbl-regbn  control-label">Full Name</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="userName"
                      id="userName"
                      onChange={this.handleChange}
                      placeholder="Full Name"
                      value={this.state.full_name_url}
                      
                    />
                  </FormGroup>
                  {this.state.submitted && !this.state.userName && (
                    <div className="help-block-bnreg">Full name is required</div>
                  )}

                  <FormGroup className="required">
                    <Label className="lbl-regbn  control-label">
                      Email Address
                    </Label>
                    <Input
                      className="form-control"
                      type="email"
                      name="email"
                      id="userEmail"
                      required
                      onChange={this.handleChange}
                      value={this.state.email_url}
                      placeholder="Email"
                    />
                  </FormGroup>
                  {this.emailValidation()}

                  <div className="help-block-bnreg mt-3">
                    {this.state.errorMessage.email}
                  </div>
                  <FormGroup className="required">
                    <Label className="lbl-regbn  control-label">Password</Label>
                    <Input
                      className="form-control"
                      type={this.state.passwordVisible}
                      placeholder="Password"
                      name="password"
                      id="userPassword"
                      onChange={this.handleChange}
                      required
                    />
                    <span
                      className="field-icon-benefireg"
                      onClick={this.eyeIcon.bind(this, "regPassword")}
                    >
                      {this.state.passwordVisible === "password" ? (
                        <FaEyeSlash />
                      ): (
                        <FaEye />
                      )}
                      {/* : {this.state.passwordScore} */}
                    </span>
                  </FormGroup>
                  {this.passwordValidation()}
                  <div className="help-block">
                    <ul>
                      {this.state.errorPassword.map((err, index) => (
                        <li key={index}>{err}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-4" style={{paddingTop:'0.06em'}}>
                    <FormGroup className="required">
                      <Label className="lbl-regbn control-label  ">
                        Last 4 Social
                      </Label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="Last 4 Social"
                        name="lastFourSocial"
                        id="lastFourSocial"
                        required
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    {this.socialNumberValidity()}
                    <div className="help-block-bnreg">
                      {this.state.errorMessage.social}
                    </div>
                  </div>
                  
 
                  <div className="text-center">
                    {this.state.loading && (
                      <img className="loader-img" alt="loaderImg" src={loaderImg} />
                    )}
                    <Button className="btn btn-dark text-warning mt-4" style={{width:'150px'}}> Save </Button>
                  </div>
                </Form>
                </div>
                <hr className="border border-warning m-0 bg-thisheart" style={{height:'2px'}}/>
              </div>{/*** end border div ***/}
              <div className="col-md-4 text-center sm-hide tab-hide">
                <img src={contributors_new_registration} style={{height:'450px'}}></img>
              </div>
               
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        </div>
      </div>
    );
  }
}

export default ContributorsRegistration;
