import React, { Component } from "react";
import medicalImg from "../pages/images/medicalImg.svg";
import insuranceImg from "../pages/images/Insurance.svg";
import financialImg from "../pages/images/Financial.svg";
import legalImg from "../pages/images/legal.svg";
import benefiImg from "../pages/images/beneficiaryImg.svg";
import demoImage from "../pages/images/image.svg";
import topRightArrow from "../pages/images/top-right-arrow-mem.png";
import memPhotos from "../pages/images/photos-mem.png";
import progressPhotos from "../pages/images/photos-progress.png";
import progressVideos from "../pages/images/videos-progress.png";
import progressAudios from "../pages/images/audios-progress.png";
import progressLetters from "../pages/images/documents-progress.png";
import accLegal from "../pages/images/legal-acc-cart.png";
import accInsurance from "../pages/images/insurance-acc-cart.png";
import accLegalBG from "../pages/images/legal-bg.png";
import beneficiariesUsers from "../pages/images/benificiaries-users.png";
import contributorsUsers from "../pages/images/contributors-users.png";


import beneCBG from "../pages/images/benificiariesCBG.png";
import medicalCBG from "../pages/images/medicalCBG.png";

import accInsuranceBG from "../pages/images/insurance-bg.png";
import memPhotosBG from "../pages/images/photos-mem-cart.png";
import memVidessBG from "../pages/images/videos-mem-cart.png";
import financialBG from "../pages/images/financial-cart.png";
import medicalBG from "../pages/images/medical-history-cart.png";
import memVideos from "../pages/images/videos-mem.png";
import videoPlaceholder from "../pages/images/videos-placeholder.png";
import audioPlaceholder from "../pages/images/audios-placeholder.png";

import memoriesHeader from "../pages/images/memories-header.png";
import primaryDashboard from "../pages/images/primary-dashboard.png";
import contributorDashboard from "../pages/images/contributor-dashboard.png";
import accountsHeader from "../pages/images/accounts-header.png";
import memBeneficiaryFile from "../pages/images/benificiaries-file.png";

import totalStorage from "../pages/images/total-storage.png";
import memContributorFile from "../pages/images/contributors-file.png";
import demoAudio from "../pages/images/audio.svg";
import demoVideo from "../pages/images/Video.svg";
import demoVideoPlaceholder from "../pages/images/video-placeholder.png";

import "font-awesome/css/font-awesome.min.css";

import "../assets/css/maindashboard.css";
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import apiService from "./../services/api.service";
import { Col, Row, Button, FormGroup, Input } from "reactstrap";
import { encrypt_url } from "./../helpers/encrypt-url";
import { memoriesService } from "../services/memories.service";
import YouTube from 'react-youtube';
import { css } from "jquery";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");
    this.toggle = this.toggle.bind(this);
    this.state = {
      userObj: JSON.parse(user),
      deleteID: 0,
      userType: localStorage.getItem("user_type"),
      searchText: "",
      encryptedString: encrypt_url(),
      allMemories: "",
      allMemoriesImages: "",
      allMemoriesVideos: "",
      allMemoriesRecords: "",
      allImageVideos: "",
      insurance_count: "",
      financial_count: "",
      legal_count: "",
    };
  }

  componentDidMount() {
    let userId = 0;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }
    memoriesService.getContentDashboard(this);
    return userId
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  changeString = (e) => {
    let srchTxt = e.target.value;
    this.setState({
      searchText: srchTxt,
    });
  };

  handleEnter = (e) => {
    if (e.keyCode === 13) {
      this.props.history.push({
        pathname: "/search",
        search: `?query=${this.state.searchText}`,
      });
    }
  };

  searchHandle = (e) => {

    this.props.history.push({
      pathname: "/search",
      search: `?query=${this.state.searchText}`,
    });
  };



  handleInsurance = () => {
    this.props.history.push({
      pathname: `/accounts/${this.state.encryptedString}`,
      search: "account-type=insurance",
    });
  };

  handleFinancial = () => {
    this.props.history.push({
      pathname: `/accounts/${this.state.encryptedString}`,
      search: "account-type=financial",
    });
  };

  handleLegal = () => {
    this.props.history.push({
      pathname: `/accounts/${this.state.encryptedString}`,
      search: "account-type=legal",
    });
  };

  showVideoThumbnail = (videoFileList) => {
    let videoThumbnail = "";
    console.log('videoFileList', videoFileList)
    if (videoFileList.length && videoFileList[0].urlcheck === "videoFile" && videoFileList[0]) {
      videoThumbnail = (

        <div style={{ position: "relative" }} className="media-shape img-thumbnail overflow-hidden align-items-center justify-content-center  text-center">
          <video height="170" name="videoPlayer" className="" style={{ maxWidth: '260px' }}>
            <source src={apiService.apiUrl + "/" + videoFileList[0].filename} type="video/mp4" style={{ maxWidth: '260px' }} />
          </video>
        </div>

      );
    } else if (videoFileList.length && videoFileList[0].urlcheck === "videoLink" && videoFileList[0]) {
      const opts = {
        height: '170',
        width: '100%',
        playerVars: {
          // Disable autoplay and other player controls
          autoplay: 0,
          controls: 0,
          disablekb: 1,
          modestbranding: 1,
          rel: 0,
          showinfo: 0
        },
      };

      let videoFilePath = "";

      let fileWatch = videoFileList[0].filename ? videoFileList[0].filename.split("watch?v=") : null;
      let fileEmbed = videoFileList[0].filename ? videoFileList[0].filename.split("embed/") : null;
      let fileYoutu = videoFileList[0].filename ? videoFileList[0].filename.split("youtu.be/") : null;

      if (fileWatch && fileWatch[1]) {
        videoFilePath = fileWatch[1];
      }
      if (fileEmbed && fileEmbed[1]) {
        videoFilePath = fileEmbed[1];
      }
      if (fileYoutu && fileYoutu[1]) {
        videoFilePath = fileYoutu[1];
      }

      videoThumbnail = (
        <div style={{ position: "relative" }} className="media-shape img-thumbnail">
          <YouTube videoId={videoFilePath} opts={opts} onReady={this._onReady} />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              zIndex: 1,
            }}
          ></div>
        </div>
      );
    } else {
      videoThumbnail = (

        <div className="video-shape pt-2 d-flex align-items-center justify-content-center border-0">
          <img className="img-fluid" src={videoPlaceholder} alt="Video" />

        </div>
      );
    }

    return videoThumbnail;
  }



  showImageThumbnail() {
    console.log('allMemoriesImages:::-', this.state.allMemoriesVideos.length);
    return (
      <div
        className="col-md-12 btm-margin media-shape img-thumbnail-mem img-thumbnail"
        style={{
          backgroundImage:
            "url(" +
            (this.state.allMemoriesImages && this.state.allMemoriesImages[0]
              ? this.state.allMemoriesImages &&
              apiService.apiUrl + "/" +
              this.state.allMemoriesImages[0].thumbnail_url
              : demoImage) +
            ")",
          backgroundSize: 'cover'
        }}
      ></div>
    )
  }


  renderAudioFile = () => {

    if (this.state.allMemoriesRecords.length) {
      return (
        <audio controls style={{ width: '100%' }} name="audio">
          <source src={`${apiService.apiUrl + '/' + this.state.allMemoriesRecords[0].filename}`} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )
    }

  }




  render() {
    const isContributor = window.location.href.includes("dashboard-contributors");
    const audioItemStyle = { height: '250px', };
    console.log(this.state.allMemoriesRecords[0]);

    return (
      <div className="page-wrapper" style={{ display: 'block' }}>

        {this.state.userObj.data.user_type === "primary" ? (
          <div className="container-fluid p-2 p-lg-4">
            <div className="row m-0 p-0">
              <div className="dash-title col-lg-9 col-md-8 col-11" >
                <h1 className="head-dash mt-2">
                  <span>Welcome Back, </span>
                  {this.state.userObj.data.user_name.split(" ").length === 1
                    ? this.state.userObj.data.user_name
                    : this.state.userObj.data.user_name
                      .split(" ")
                      .slice(0, -1)
                      .join(" ")}
                  !
                </h1>
                {/* <p className="mobile-hide web-show"> */}
                <p className="">
                  Pick back up where you left off. Upload some new stuff, or
                  update some of your existing stuff.
                </p>
              </div>
              <div className="col-lg-3 col-md-4 col-1 d-flex align-items-center justify-content-end">
                <div>
                  <img className="ml-1" src={primaryDashboard} alt="Memories" style={{ maxWidth: "25px" }} />
                </div>
                <div className="d-md-block d-lg-block d-none">
                  <h6 className="m-0 ml-1">Primary Dashboard</h6>
                </div>
              </div>
            </div>
            {/* <div className="bg-search">
              <div className="container">
                <div className="row" style={{ alignItems: 'center' }}>
                  <div className="col-md-4 col-12 p-txt mb-1">
                    <p style={{ marginBottom: "0px" }}>
                      Find your Stuff quickly by simple search
                    </p>
                  </div>
                  <div className="col-md-6 col-8" style={{ paddingRight: '3px' }}>
                    <div className="has-search">
                      <FormGroup style={{ marginBottom: "0px" }}>
                        <span className="fa fa-search form-control-feedback" style={{ color: "#3e5669" }}></span>
                        <Input
                          type="search"
                          className="form-control-input placeholder-color"
                          placeholder="Search for"
                          onChange={this.changeString}
                          onKeyUp={this.handleEnter}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-md-2 col-3 pl-0">
                    <Button style={{ background: "#f6dc20", border: "none", color: "black", fontWeight: "400" }} onClick={this.searchHandle}>
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-12" style={{ paddingTop: "20px" }}>
                {/* Memories start */}
                <div className="col-md-12">
                  <h4 className="head-memo mb-0">Your Memories</h4>
                </div>
                <div className="mem-div border-0">
                  <div className="row m-0 w-100">
                    <div className="col-lg-6 col-12 row m-0 w-100 p-1">
                      <div className="col-12 m-0 p-1 w-100">
                        <div className="m-0 row p-2" style={{ background: "#f6da20", borderRadius: "7px" }}>
                          <div className="col-lg-10 col-11 row m-0 p-0">
                            <div className="col-4 d-flex align-items-center p-0">
                              <img src={memoriesHeader} alt="Memories" className="img-fluid" />
                            </div>
                            <div className="col-8 d-flex justify-content-between p-2 flex-column">
                              <div>
                                <h6 className="m-0">My Story of happiness</h6>
                                <p className="m-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                                  ? this.state.userObj.data.user_name
                                  : this.state.userObj.data.user_name
                                    .split(" ")
                                    .slice(0, -1)
                                    .join(" ")}</b> forwards</p>
                              </div>
                              <div>
                                <p className="mt-1 mb-0" style={{ color: "#9d9559" }}>Last Update</p>
                                <p className="mb-0" style={{ color: "#9d9559" }}>01-11-2024 forwards</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-1 d-flex align-items-center justify-content-end">
                            <div className="d-flex justify-content-end">
                              <Button
                                className="btn-plus"
                                style={{ background: "#907f13", border: "none" }}
                                href={`/memories/${this.state.encryptedString}`}
                              >
                                <i
                                  className="fa fa-plus text-white"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row w-100 m-0">
                        <div className="col-12 col-lg-6 col-md-6 m-0 p-1 w-100">
                          <div className="row m-0 p-2 w-100" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (memPhotosBG) + ")", backgroundSize: '100% 100%' }}>
                            <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                              <div className="">
                                <img className="text-center" style={{ maxWidth: "40px" }} src={memPhotos} alt="Memories" />
                              </div>
                              <div>
                                <h6 className="mt-2 text-center" style={{ color: "#999797" }}>Photos</h6>
                              </div>
                            </div>
                            <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                              <div className="d-flex justify-content-center">
                                <h4 className="m-0" style={{ color: "#999797" }}><span className="h2">{this.state.allMemoriesImages.length ? (this.state.allMemoriesImages.length < 10 ? '0' + this.state.allMemoriesImages.length : this.state.allMemoriesImages.length) : "00"}</span> <br /> <span className="mt-2"></span>Files</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 m-0 p-1">
                          <div className="row m-0 p-2 w-100" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (memVidessBG) + ")", backgroundSize: '100% 100%' }}>
                            <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                              <div className="">
                                <img className="text-center" style={{ maxWidth: "40px" }} src={memVideos} alt="Memories" />
                              </div>
                              <div>
                                <h6 className="mt-2 text-center" style={{ color: "#999797" }}>Videos</h6>
                              </div>
                            </div>
                            <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                              <div className="d-flex justify-content-center">
                                <h4 className="m-0" style={{ color: "#999797" }}><span className="h2">{this.state.allMemoriesVideos.length ? (this.state.allMemoriesVideos.length < 10 ? '0' + this.state.allMemoriesVideos.length : this.state.allMemoriesVideos.length) : "00"}</span> <br /> <span className="mt-2"></span>Files</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 row m-0 p-1 w-100">
                      <div className="col-6 col-md-6 w-100 p-1">
                        <div className="p-3 h-100" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)" }}>
                          <div className="d-flex justify-content-between pb-2">
                            <div>
                              <img src={memBeneficiaryFile} alt="Image" style={{ maxWidth: "40px" }} />
                            </div>
                            <div>
                              <Button
                                className=""
                                style={{ border: "none", background: "#fff" }}
                                href={`/beneficiaries/${this.state.encryptedString}`}
                              >
                                <img src={topRightArrow} alt="Image" style={{ maxWidth: "40px" }} />
                              </Button>

                            </div>
                          </div>
                          <div style={{ color: "#999797" }}>
                            <h5>Beneficiaries</h5>
                            <p>Share with people</p>
                            <p className="my-2"><b>15 Users</b></p>
                          </div>
                          <div className="d-flex justify-content-start pt-1">
                            <img src={contributorsUsers} alt="Image" style={{ maxWidth: "100px" }} />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 w-100 p-1">
                        <div className="p-3 h-100" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)" }}>
                          <div className="d-flex justify-content-between pb-2">
                            <div>
                              <img src={memContributorFile} alt="Image" style={{ maxWidth: "40px" }} />
                            </div>
                            <div>
                              <Button
                                className=""
                                style={{ border: "none", background: "#fff" }}
                                href={`/contributors/${this.state.encryptedString}`}
                              >
                                <img src={topRightArrow} alt="Image" style={{ maxWidth: "40px" }} />
                              </Button>

                            </div>
                          </div>
                          <div style={{ color: "#999797" }}>
                            <h5>Contributors</h5>
                            <p>Share with people</p>
                            <p className="my-2"><b>12 Users</b></p>
                          </div>
                          <div className="d-flex justify-content-start pt-1">
                            <img src={contributorsUsers} alt="Image" style={{ maxWidth: "100px" }} />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* Memories end */}
                {/* Accounts start */}
                <div className="col-md-12 mt-2">
                  <h4 className="head-memo mb-0">Your Accounts</h4>
                </div>
                <div className="mem-div border-0">
                  <div className="row m-0 w-100">
                    <div className="col-lg-6 col-12 m-0 w-100 p-1">
                      <div className="row m-0 w-100 p-0">
                        <div className="col-12 m-0 p-1">
                          <div className="p-2 row m-0 pr-0" style={{ background: "#e9f6fc", borderRadius: "7px" }}>
                            <div className="col-lg-10 col-11 row m-0 p-0">
                              <div className="col-4 d-flex align-items-center p-0">
                                <img src={accountsHeader} alt="Memories" className="img-fluid" />
                              </div>
                              <div className="col-8 d-flex justify-content-between p-2 flex-column">
                                <div>
                                  <h6 className="m-0">My Story of happiness</h6>
                                  <p className="m-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                                    ? this.state.userObj.data.user_name
                                    : this.state.userObj.data.user_name
                                      .split(" ")
                                      .slice(0, -1)
                                      .join(" ")}</b> forwards</p>
                                </div>
                                <div>
                                  <p className="mt-1 mb-0" style={{ color: "#9b9fa1" }}>Last Update</p>
                                  <p className="mb-0" style={{ color: "#9b9fa1" }}>01-11-2024 forwards</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2 col-1 d-flex  align-items-center justify-content-end">
                              <div className="d-flex justify-content-end">
                                <Button
                                  className="btn-plus"
                                  style={{ background: "#1497d5", border: "none" }}
                                  href={`/accounts/${this.state.encryptedString}`}
                                >
                                  <i
                                    className="fa fa-plus text-white"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row w-100 m-0">
                          <div className="col-12 col-lg-6 col-md-6 m-0 p-1 w-100">
                            <div className="row m-0 p-1 w-100" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (accLegalBG) + ")", backgroundSize: '100% 100%' }}>
                              <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                                <div className="">
                                  <img className="text-center" style={{ maxWidth: "40px" }} src={accLegal} alt="Memories" />
                                </div>
                                <div>
                                  <h6 className="mt-2 text-center" style={{ color: "#999797" }}>Legal</h6>
                                </div>
                              </div>
                              <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                                <div className="d-flex justify-content-center">
                                  <h4 className="m-0" style={{ color: "#999797" }}><span className="h2">{this.state.legal_count.length ? (this.state.legal_count.length < 10 ? '0' + this.state.legal_count.length : this.state.legal_count.length) : "00"}</span> <br /> <span className="mt-2"></span>Files</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-6 col-md-6 m-0 p-1 w-100">
                            <div className="row m-0 p-1" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (accInsuranceBG) + ")", backgroundSize: '100% 100%' }}>
                              <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                                <div className="">
                                  <img className="text-center" style={{ maxWidth: "40px" }} src={accInsurance} alt="Memories" />
                                </div>
                                <div>
                                  <h6 className="mt-2 text-center" style={{ color: "#999797" }}>Insurance</h6>
                                </div>
                              </div>
                              <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                                <div className="d-flex justify-content-center">
                                  <h4 className="m-0" style={{ color: "#999797" }}><span className="h2">{this.state.insurance_count.length ? (this.state.insurance_count.length < 10 ? '0' + this.state.insurance_count.length : this.state.insurance_count.length) : "00"}</span> <br /> <span className="mt-2"></span>Files</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 m-0 p-0 w-100 d-none d-lg-block">
                      <div className="row m-0 p-0 w-100 h-100 p-1">
                        <div className="col-12 col-lg-6 p-1 w-100 h-100">
                          <div className="p-2 w-100 h-100 d-flex align-items-center" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)" }}>
                            <div className="d-flex justify-content-center align-items-center flex-column pb-2 mx-auto">
                              <div className="d-flex justify-content-center align-items-center" style={{ width: "135px", height: "135px", backgroundImage: "url(" + (totalStorage) + ")", backgroundSize: 'cover' }}>
                                <div className="text-white text-center">
                                  <h6 className="m-0 p-0">20 GB</h6>
                                  <p className="m-0 p-0">Used</p>
                                </div>
                              </div>
                              <div className="mt-2">
                                <h6 style={{ color: "#858585" }}>Total Space 100GB</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 p-1 w-100 h-100">
                          <div className="p-2" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)" }}>
                            <div>
                              <h4 className="mb-3" style={{ color: "#858585" }}>Used Space Details</h4>
                              <div className="row mb-2">
                                <div className="col-2">
                                  <img src={progressPhotos} alt="Image" style={{ maxWidth: "40px" }} />
                                </div>
                                <div className="col-9 row ml-1">
                                  <div className="col-12 d-flex justify-content-between">
                                    <div style={{ color: "#858585" }}>
                                      <h6 className="m-0">Photos</h6>
                                    </div>
                                    <div style={{ color: "#858585" }}>
                                      <h6 className="m-0">4GB</h6>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="progress">
                                      <div className="progress-bar" role="progressbar" style={{ width: "25%", background: "#f6d900" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-2">
                                  <img src={progressVideos} alt="Image" style={{ maxWidth: "40px" }} />
                                </div>
                                <div className="col-9 row ml-1">
                                  <div className="col-12 d-flex justify-content-between">
                                    <div style={{ color: "#858585" }}>
                                      <h6 className="m-0">Videos</h6>
                                    </div>
                                    <div style={{ color: "#858585" }}>
                                      <h6 className="m-0">6GB</h6>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="progress">
                                      <div className="progress-bar" role="progressbar" style={{ width: "75%", background: "#636363" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-2">
                                  <img src={progressAudios} alt="Image" style={{ maxWidth: "40px" }} />
                                </div>
                                <div className="col-9 row ml-1">
                                  <div className="col-12 d-flex justify-content-between">
                                    <div style={{ color: "#858585" }}>
                                      <h6 className="m-0">Audios</h6>
                                    </div>
                                    <div style={{ color: "#858585" }}>
                                      <h6 className="m-0">4GB</h6>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="progress">
                                      <div className="progress-bar" role="progressbar" style={{ width: "50%", background: "#1195d4" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-2">
                                  <img src={progressLetters} alt="Image" style={{ maxWidth: "40px" }} />
                                </div>
                                <div className="col-9 row ml-1">
                                  <div className="col-12 d-flex justify-content-between">
                                    <div style={{ color: "#858585" }}>
                                      <h6 className="m-0">Letters</h6>
                                    </div>
                                    <div style={{ color: "#858585" }}>
                                      <h6 className="m-0">6GB</h6>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="progress">
                                      <div className="progress-bar" role="progressbar" style={{ width: "80%", background: "#636363" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Accounts end */}
                {/* Medical start */}
                <div className="mem-div border-0 d-lg-block d-none">
                  <div className="row m-0 w-100">
                    <div className="col-lg-3 p-1">
                      <div>
                        <div className="mt-3">
                          <h4 className="head-memo mb-2">Medical History</h4>
                        </div>
                        <div className="d-flex justify-content-between flex-column p-3" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (medicalBG) + ")", backgroundSize: '100% 100%', minHeight: "250px" }}>
                          <div className="d-flex justify-content-between">
                            <div className="">
                              <h6 className="m-0">My Story of happiness</h6>
                              <p className="m-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                                ? this.state.userObj.data.user_name
                                : this.state.userObj.data.user_name
                                  .split(" ")
                                  .slice(0, -1)
                                  .join(" ")}</b> forwards</p>
                            </div>
                            <div className="">
                              <Button
                                className="btn-plus"
                                style={{ background: "#dfdfdf", border: "none" }}
                                href={`/medicalhistory/${this.state.encryptedString}`}
                              >
                                <i
                                  className="fa fa-plus text-white"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </div>
                          </div>
                          <div>
                            <p className="mt-1 mb-0" style={{ color: "#858585" }}>Last Update</p>
                            <p className="mb-0" style={{ color: "#858585" }}>01-11-2024 forwards</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 p-1">
                      <div>
                        <div className="mt-3">
                          <h4 className="head-memo mb-2">Financial</h4>
                        </div>
                        <div className="d-flex justify-content-between flex-column p-3" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (financialBG) + ")", backgroundSize: '100% 100%', minHeight: "250px" }}>
                          <div className="d-flex justify-content-between">
                            <div className="">
                              <h6 className="m-0">My Story of happiness</h6>
                              <p className="m-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                                ? this.state.userObj.data.user_name
                                : this.state.userObj.data.user_name
                                  .split(" ")
                                  .slice(0, -1)
                                  .join(" ")}</b> forwards</p>
                            </div>
                            <div className="">
                              <Button
                                className="btn-plus"
                                style={{ background: "#dfdfdf", border: "none" }}
                                href={`/accounts/${this.state.encryptedString}`}
                              >
                                <i
                                  className="fa fa-plus text-white"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </div>
                          </div>
                          <div>
                            <p className="mt-1 mb-0" style={{ color: "#858585" }}>Last Update</p>
                            <p className="mb-0" style={{ color: "#858585" }}>01-11-2024 forwards</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 p-1">
                      <div>
                        <div className="mt-3">
                          <h4 className="head-memo mb-2">Audio Memories</h4>
                        </div>
                        <div className="d-flex justify-content-between flex-column p-3" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", minHeight: "250px" }}>
                          <div className="d-flex justify-content-start">
                            <div className="">
                              <img src={progressAudios} alt="Audios of Memories" />
                            </div>
                            <div className="ml-2">
                              <h6 className="m-0">My Story of happiness</h6>
                              <p className="m-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                                ? this.state.userObj.data.user_name
                                : this.state.userObj.data.user_name
                                  .split(" ")
                                  .slice(0, -1)
                                  .join(" ")}</b> forwards</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <img className="img-fluid" src={audioPlaceholder} alt="Audio" style={{ maxWidth: "200px" }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 p-1">
                      <div>
                        <div className="mt-3">
                          <h4 className="head-memo mb-2">Video Memories</h4>
                        </div>
                        <div className="d-flex justify-content-between flex-column p-3" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", minHeight: "250px" }}>
                          <div className="d-flex justify-content-start">
                            <div className="">
                              <img src={progressVideos} alt="Video of Memories" />
                            </div>
                            <div className="ml-2">
                              <h6 className="m-0">My Story of happiness</h6>
                              <p className="m-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                                ? this.state.userObj.data.user_name
                                : this.state.userObj.data.user_name
                                  .split(" ")
                                  .slice(0, -1)
                                  .join(" ")}</b> forwards</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            {/* {this.showVideoThumbnail(this.state.allMemoriesVideos)} */}
                            <img className="img-fluid" src={videoPlaceholder} alt="Audio" style={{ maxWidth: "200px" }} />
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid p-2 p-lg-4">
            <div className="row m-0 p-0">
              <div className="dash-title col-lg-9 col-md-8 col-11 pr-3" >
                <h1 className="head-dash mt-2">
                  <span>Welcome as Contributor, </span>
                  {this.state.userObj.data.user_name.split(" ").length === 1
                    ? this.state.userObj.data.user_name
                    : this.state.userObj.data.user_name
                      .split(" ")
                      .slice(0, -1)
                      .join(" ")}
                  !
                </h1>
                {/* <p className="mobile-hide web-show"> */}
                <p className="">
                  Pick back up where you left off. Upload some new stuff, or
                  update some of your existing stuff.
                </p>
              </div>
              <div className="col-lg-3 col-md-4 col-1 d-flex align-items-center justify-content-end">
                <div>
                  <img className="ml-1" src={contributorDashboard} alt="Memories" style={{ maxWidth: "25px" }} />
                </div>
                <div className="d-md-block d-lg-block d-none">
                  <h6 className="m-0 ml-1">Contributor Dashboard</h6>
                </div>
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-lg-6 col-12 h-100">
                <div className="mt-2 mb-1">
                  <h4 className="head-memo mb-0">Beneficiaries</h4>
                </div>
                <div className="p-3" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (beneCBG) + ")", backgroundSize: '100% 100%' }}>
                  <div className="d-flex justify-content-between pb-2 mb-3">
                    <div className="d-flex">
                      <div>
                        <img src={memBeneficiaryFile} alt="Image" style={{ maxWidth: "40px" }} />
                      </div>
                      <div className="ml-2" style={{ color: "#999797" }}>
                        <h5 className="m-0 p-0">Share with people</h5>
                        <p className="m-0 p-0"><b>15 Users</b></p>
                      </div>
                    </div>
                    <div>
                      <Button
                        className=""
                        style={{ border: "none", background: "#fff" }}
                        href={`/beneficiaries/${this.state.encryptedString}`}
                      >
                        <img src={topRightArrow} alt="Image" style={{ maxWidth: "40px" }} />
                      </Button>

                    </div>
                  </div>
                  <div className="d-flex justify-content-start pt-1">
                    <img src={contributorsUsers} alt="Image" style={{ maxWidth: "100px" }} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 h-100">
                <div className="mt-2 mb-1">
                  <h4 className="head-memo mb-0">Medical History</h4>
                </div>
                <div className="p-3" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (medicalCBG) + ")", backgroundSize: '100% 100%' }}>
                  <div className="d-flex justify-content-between pb-2 mb-3">
                    <div className="d-flex">
                      {/* <div>
                        <img src={memBeneficiaryFile} alt="Image" style={{ maxWidth: "40px" }} />
                      </div> */}
                      <div className="" style={{ color: "#999797" }}>
                        <h5 className="m-0 p-0">My Story of Happiness</h5>
                        <p className="m-0 p-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                          ? this.state.userObj.data.user_name
                          : this.state.userObj.data.user_name
                            .split(" ")
                            .slice(0, -1)
                            .join(" ")}</b> forwards</p>
                      </div>
                    </div>
                    <div>
                      <Button
                        className="btn-plus"
                        style={{ background: "#b0b0b0", border: "none" }}
                        href={`/medicalhistory/${this.state.encryptedString}`}
                      >
                        <i
                          className="fa fa-plus text-white"
                          aria-hidden="true"
                        ></i>
                      </Button>

                    </div>
                  </div>
                  <div>
                    <p className="mt-1 mb-0" style={{ color: "#858585" }}>Last Update</p>
                    <p className="mb-0" style={{ color: "#858585" }}>01-11-2024 forwards</p>
                  </div>
                </div>
              </div>

            </div>
            <div className="">
              {/* Memories start */}
              <div className="mem-div border-0">
                <div className="row m-0 w-100">
                  <div className="col-lg-6 col-12 m-0 w-100 p-1">
                    <div className="mb-1 mt-2">
                      <h4 className="head-memo mb-0">Your Memories</h4>
                    </div>
                    <div className="m-0 p-1 w-100">
                      <div className="m-0 row p-2" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)" }}>
                        <div className="col-lg-10 col-11 row m-0 p-0">
                          <div className="col-4 d-flex align-items-center p-0">
                            <img src={memoriesHeader} alt="Memories" className="img-fluid" />
                          </div>
                          <div className="col-8 d-flex justify-content-between py-2 px-1 flex-column">
                            <div>
                              <h6 className="m-0">My Story of happiness</h6>
                              <p className="m-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                                ? this.state.userObj.data.user_name
                                : this.state.userObj.data.user_name
                                  .split(" ")
                                  .slice(0, -1)
                                  .join(" ")}</b> forwards</p>
                            </div>
                            <div>
                              <p className="mt-1 mb-0" style={{ color: "#999797" }}>Last Update</p>
                              <p className="mb-0" style={{ color: "#999797" }}>01-11-2024 forwards</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-1 d-flex align-items-center justify-content-end">
                          <div className="d-flex justify-content-end">
                            <Button
                              className="btn-plus"
                              style={{ background: "#b0b0b0", border: "none" }}
                              href={`/memories/${this.state.encryptedString}`}
                            >
                              <i
                                className="fa fa-plus text-white"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row w-100 m-0">
                      <div className="col-12 col-lg-6 col-md-6 m-0 p-1 w-100">
                        <div className="row m-0 p-2 w-100" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (memPhotosBG) + ")", backgroundSize: '100% 100%' }}>
                          <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                            <div className="">
                              <img className="text-center" style={{ maxWidth: "40px" }} src={memPhotos} alt="Memories" />
                            </div>
                            <div>
                              <h6 className="mt-2 text-center" style={{ color: "#999797" }}>Photos</h6>
                            </div>
                          </div>
                          <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                            <div className="d-flex justify-content-center">
                              <h4 className="m-0" style={{ color: "#999797" }}><span className="h2">{this.state.allMemoriesImages.length ? (this.state.allMemoriesImages.length < 10 ? '0' + this.state.allMemoriesImages.length : this.state.allMemoriesImages.length) : "00"}</span> <br /> <span className="mt-2"></span>Files</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 col-md-6 m-0 p-1">
                        <div className="row m-0 p-2 w-100" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (memVidessBG) + ")", backgroundSize: '100% 100%' }}>
                          <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                            <div className="">
                              <img className="text-center" style={{ maxWidth: "40px" }} src={memVideos} alt="Memories" />
                            </div>
                            <div>
                              <h6 className="mt-2 text-center" style={{ color: "#999797" }}>Videos</h6>
                            </div>
                          </div>
                          <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                            <div className="d-flex justify-content-center">
                              <h4 className="m-0" style={{ color: "#999797" }}><span className="h2">{this.state.allMemoriesVideos.length ? (this.state.allMemoriesVideos.length < 10 ? '0' + this.state.allMemoriesVideos.length : this.state.allMemoriesVideos.length) : "00"}</span> <br /> <span className="mt-2"></span>Files</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 row m-0 p-1 w-100">

                    <div className="mb-1 mt-2">
                      <h4 className="head-memo mb-0">Your Accounts</h4>
                    </div>
                    <div className="row m-0 w-100 p-0">
                      <div className="col-12 m-0 p-1">
                        <div className="p-2 row m-0 pr-0" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)" }}>
                          <div className="col-lg-10 col-11 row m-0 p-0">
                            <div className="col-4 d-flex align-items-center p-0">
                              <img src={accountsHeader} alt="Memories" className="img-fluid" />
                            </div>
                            <div className="col-8 d-flex justify-content-between py-2 px-1 flex-column">
                              <div>
                                <h6 className="m-0">My Story of happiness</h6>
                                <p className="m-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                                  ? this.state.userObj.data.user_name
                                  : this.state.userObj.data.user_name
                                    .split(" ")
                                    .slice(0, -1)
                                    .join(" ")}</b> forwards</p>
                              </div>
                              <div>
                                <p className="mt-1 mb-0" style={{ color: "#9b9fa1" }}>Last Update</p>
                                <p className="mb-0" style={{ color: "#9b9fa1" }}>01-11-2024 forwards</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-1 d-flex  align-items-center justify-content-end">
                            <div className="d-flex justify-content-end">
                              <Button
                                className="btn-plus"
                                style={{ background: "#1497d5", border: "none" }}
                                href={`/accounts/${this.state.encryptedString}`}
                              >
                                <i
                                  className="fa fa-plus text-white"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row w-100 m-0">
                        <div className="col-12 col-lg-6 col-md-6 m-0 p-1 w-100">
                          <div className="row m-0 p-1 w-100" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (accLegalBG) + ")", backgroundSize: '100% 100%' }}>
                            <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                              <div className="">
                                <img className="text-center" style={{ maxWidth: "40px" }} src={accLegal} alt="Memories" />
                              </div>
                              <div>
                                <h6 className="mt-2 text-center" style={{ color: "#999797" }}>Legal</h6>
                              </div>
                            </div>
                            <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                              <div className="d-flex justify-content-center">
                                <h4 className="m-0" style={{ color: "#999797" }}><span className="h2">{this.state.legal_count.length ? (this.state.legal_count.length < 10 ? '0' + this.state.legal_count.length : this.state.legal_count.length) : "00"}</span> <br /> <span className="mt-2"></span>Files</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 m-0 p-1 w-100">
                          <div className="row m-0 p-1" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", backgroundImage: "url(" + (accInsuranceBG) + ")", backgroundSize: '100% 100%' }}>
                            <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                              <div className="">
                                <img className="text-center" style={{ maxWidth: "40px" }} src={accInsurance} alt="Memories" />
                              </div>
                              <div>
                                <h6 className="mt-2 text-center" style={{ color: "#999797" }}>Insurance</h6>
                              </div>
                            </div>
                            <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                              <div className="d-flex justify-content-center">
                                <h4 className="m-0" style={{ color: "#999797" }}><span className="h2">{this.state.insurance_count.length ? (this.state.insurance_count.length < 10 ? '0' + this.state.insurance_count.length : this.state.insurance_count.length) : "00"}</span> <br /> <span className="mt-2"></span>Files</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Memories end */}
              {/* Accounts start */}

              {/* Accounts end */}
              {/* Medical start */}
              {/*  */}
            </div>

            <div className="mem-div border-0 d-lg-block d-none">
              <div className="row m-0 w-100">
                <div className="col-lg-3 p-1">
                  {/* show primary users name, mail, phone, address */}
                  <div className="mt-3">
                    <h4 className="head-memo mb-2">Primary User Details</h4>
                  </div>
                  <div className="d-flex justify-content-between flex-column p-2 custom-scrollbar" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", height: "250px", overflowY:'scroll', overflowX:'hidden' }}>
                    <div>
                      <p className="p-1 mx-0 mt-0" style={{ borderRadius: "7px", marginBottom:"6px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)"}}><b>Name : </b>Shourov Hasan</p>
                      <p className="p-1 mx-0 mt-0" style={{ borderRadius: "7px", marginBottom:"6px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)"}}><b>Email : </b>XXXX@gmail.com</p>
                      <p className="p-1 mx-0 mt-0" style={{ borderRadius: "7px", marginBottom:"6px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)"}}><b>Phone : </b>017XX-XXXXXX</p>
                      <p className="p-1 mx-0 mt-0" style={{ borderRadius: "7px", marginBottom:"6px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)"}}><b>City : </b>Dhaka</p>
                      <p className="p-1 mx-0 mt-0" style={{ borderRadius: "7px", marginBottom:"6px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)"}}><b>Country : </b>Bangladesh</p>
                      <p className="pt-1 px-1 pb-0 m-0" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)"}}><b>Address : </b>Mirpur-DOHS, Dhaka-Bangladesh</p>
                    </div>
                  </div>


                </div>
                <div className="col-lg-3 p-1 h-100">
                  <div className="">
                    <div className="mt-3">
                      <h4 className="head-memo mb-2">Files Details</h4>
                    </div>
                    <div className="px-2 py-3 h-100" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", minHeight: "250px" }}>
                      <div>
                        {/* <h4 className="mb-3" style={{ color: "#858585" }}>Files Details</h4> */}
                        <div className="row mb-2">
                          <div className="col-2">
                            <img src={progressPhotos} alt="Image" style={{ maxWidth: "40px" }} />
                          </div>
                          <div className="col-9 row ml-1">
                            <div className="col-12 d-flex justify-content-between">
                              <div style={{ color: "#858585" }}>
                                <h6 className="m-0">Photos</h6>
                              </div>
                              <div style={{ color: "#858585" }}>
                                <h6 className="m-0">40 files</h6>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "25%", background: "#f6d900" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-2">
                            <img src={progressVideos} alt="Image" style={{ maxWidth: "40px" }} />
                          </div>
                          <div className="col-9 row ml-1">
                            <div className="col-12 d-flex justify-content-between">
                              <div style={{ color: "#858585" }}>
                                <h6 className="m-0">Videos</h6>
                              </div>
                              <div style={{ color: "#858585" }}>
                                <h6 className="m-0">60 files</h6>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "75%", background: "#636363" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-2">
                            <img src={progressAudios} alt="Image" style={{ maxWidth: "40px" }} />
                          </div>
                          <div className="col-9 row ml-1">
                            <div className="col-12 d-flex justify-content-between">
                              <div style={{ color: "#858585" }}>
                                <h6 className="m-0">Audios</h6>
                              </div>
                              <div style={{ color: "#858585" }}>
                                <h6 className="m-0">40 files</h6>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "50%", background: "#1195d4" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-2">
                            <img src={progressLetters} alt="Image" style={{ maxWidth: "40px" }} />
                          </div>
                          <div className="col-9 row ml-1">
                            <div className="col-12 d-flex justify-content-between">
                              <div style={{ color: "#858585" }}>
                                <h6 className="m-0">Letters</h6>
                              </div>
                              <div style={{ color: "#858585" }}>
                                <h6 className="m-0">60 files</h6>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "80%", background: "#636363" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 p-1">
                  <div>
                    <div className="mt-3">
                      <h4 className="head-memo mb-2">Audio Memories</h4>
                    </div>
                    <div className="d-flex justify-content-between flex-column p-3" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", minHeight: "250px" }}>
                      <div className="d-flex justify-content-start">
                        <div className="">
                          <img src={progressAudios} alt="Audios of Memories" />
                        </div>
                        <div className="ml-2">
                          <h6 className="m-0">My Story of happiness</h6>
                          <p className="m-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                            ? this.state.userObj.data.user_name
                            : this.state.userObj.data.user_name
                              .split(" ")
                              .slice(0, -1)
                              .join(" ")}</b> forwards</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid" src={audioPlaceholder} alt="Audio" style={{ maxWidth: "200px" }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 p-1">
                  <div>
                    <div className="mt-3">
                      <h4 className="head-memo mb-2">Video Memories</h4>
                    </div>
                    <div className="d-flex justify-content-between flex-column p-3" style={{ borderRadius: "7px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)", minHeight: "250px" }}>
                      <div className="d-flex justify-content-start">
                        <div className="">
                          <img src={progressVideos} alt="Video of Memories" />
                        </div>
                        <div className="ml-2">
                          <h6 className="m-0">My Story of happiness</h6>
                          <p className="m-0">by <b>{this.state.userObj.data.user_name.split(" ").length === 1
                            ? this.state.userObj.data.user_name
                            : this.state.userObj.data.user_name
                              .split(" ")
                              .slice(0, -1)
                              .join(" ")}</b> forwards</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {/* {this.showVideoThumbnail(this.state.allMemoriesVideos)} */}
                        <img className="img-fluid" src={videoPlaceholder} alt="Audio" style={{ maxWidth: "200px" }} />
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        )}
      </div >
    );
  }
}

export default Dashboard;
