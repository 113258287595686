import { ToastsStore } from "react-toasts";
import { authHeader } from "../helpers/auth-header";
import axios from "axios";
import apiService from "./api.service";
import OTPAuthService from "./otpauth.service";
// import { encrypt_url } from "../helpers/encrypt-url";
import MainServices from "../services/main.service";
import { useHistory } from 'react-router-dom';
import profilePhoto from "../pages/images/dp.png";

export const userService = {
  login,
  adminLogin,
  approvedFreeAccount,
  register,
  logout,
  checkAccountData,
  checkMemoriesData,
  checkMedicalData,
  checkBeneficiaryData,
  resetPasswordRequest,
  resetPasswordValidate,
  verifyEmail,
  editProfile,
  getUserDetails,
  editUserInfo,
  uploadProfileImage,
  removeProfileImage,
};

const apiUrl = apiService.apiUrl;
// let encrypted_url = encrypt_url()

function checkAccountData(userId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  console.log(userId);
  axios
    .post(`${apiUrl}/api/account_info`, {}, header)
    .then(response => {
      console.log("Data size ", response.data.length);
      if (response.data.length > 0) {
        self.setState({ accountDataExist: true });
        self.setState({ accountLoading: false });
      } else {
        self.setState({ accountDataExist: false });
        self.setState({ accountLoading: false });
      }
    })
    .catch(function(error) {
      // handle error
    });
}


function uploadProfileImage(imageFile, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  const formData = new FormData();
  formData.append("profile_image", imageFile);

  axios
    .post(`${apiUrl}/api/image/profileUpload`, formData, header)
    .then(response => {
      if (response.data.status === "success") {
        self.setState({
          profile_image: apiService.apiUrl + "/" + response.data.data.image_url
        });
        let userTmp = JSON.parse(localStorage.getItem("user"));
        userTmp.data.profile_image = response.data.data.image_url;
        localStorage.setItem("user", JSON.stringify(userTmp));
        userTmp = JSON.parse(localStorage.getItem("user"));
        // console.log(userTmp.data.profile_image);
      }
      console.log('self.state.profile_image->>', self.state.profile_image);
      self.setState({loaderProfileImage:false})
      ToastsStore.success("Profile Image Uploaded successfull");
    })
    .catch(function(error) {
      // handle error
      //console.log(error.response);
      //self.setState({ uploadingData: false });
      self.setState({loaderProfileImage:false})
      ToastsStore.error("Sorry, profile image isn't uploaded, "+ error.response.data.message);
    });

  return false;
}


function removeProfileImage(imageFile, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

 
  const formData = new FormData();
  formData.append("remove_profile_image", 'false');

  axios
    .post(`${apiUrl}/api/image/profileUpload`, formData, header)
    .then(response => {
      
      if (response.data.status === "success") {
       
        let userTmp = JSON.parse(localStorage.getItem("user"));
        console.log('userTmp:::>>>>',userTmp)
        userTmp.data.profile_image = profilePhoto;
        localStorage.setItem("user", JSON.stringify(userTmp));
        //userTmp = JSON.parse(localStorage.getItem("user"));
        self.setState({
          profile_image: profilePhoto
        });
     
      }
       
      self.setState({loaderProfileImage:false})
      ToastsStore.success("Profile image deleted successfull");
    }).catch(function(error) {
      self.setState({loaderProfileImage:false})
      ToastsStore.error("Sorry, profile image isn't uploaded, "+ error.data);
    });

  return false;
}



function checkMemoriesData(userId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  axios
    .get(`${apiUrl}/api/memories/getContentDataCount/${userId}`, header)
    .then(response => {
      self.setState({ memories: response.data });
      if (
        response.data.imageCount > 0 ||
        response.data.videoCount > 0 ||
        response.data.recordCount > 0 ||
        response.data.letterCount > 0
      ) {
        self.setState({ memoriesDataExist: true });
        self.setState({ memoriesLoading: false });
      } else {
        self.setState({ memoriesDataExist: false });
        self.setState({ memoriesLoading: false });
      }
    })
    .catch(function(error) {
      // handle error
    });
}

function editProfile(self, profileData) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  axios.post(`${apiUrl}/api/account/update_info`, profileData, header).then(
    response => {
      if (response.data.status === "success") {
        let userTmp = JSON.parse(localStorage.getItem("user"));
        userTmp.data.user_name = response.data.user_name;
        userTmp.data.additional_email = response.data.additional_email;
        userTmp.data.data = response.data.data;
        userTmp.data.mobile = response.data.mobile;
        localStorage.setItem("user", JSON.stringify(userTmp));
        self.setState({
          userList: {
            ...self.state.userList,
            userName: response.data.user_name,
            additional_email: response.data.additional_email,
            mobile: response.data.mobile,
          },
          updateProfileSpin:false
        });
        ToastsStore.success("Success, Profile information is updated!");
      } else if (response.data.status === "error") {
        self.setState({
          errors: {
            ...self.state.errors,
            currentPass: response.data.message
          },
          updateProfileSpin:false
        });
        ToastsStore.error("Sorry, "+response.data.message)
      }
    },
    error => {
      let msg = error.response.data.message;
      console.log('msg',error.response)
      let msgTmp = msg.split(":");
      let msgError = msgTmp && msgTmp[2] ? msgTmp[2].substr(0,21) : "";
      if (
        msgError ===
        " 1062 Duplicate entry"
      ) {
        let errors = [];
        errors["mobile"] = "Mobile number is used already!";
        self.setState({ errors: errors, updateProfileSpin:false });
        ToastsStore.error("Sorry, update profile failed: "+msgError)
        return false
      }
      console.log(error.response.data.message)
      self.setState({updateProfileSpin:false });
      ToastsStore.error("Sorry, update profile failed: "+msgError)
    }
  );
}


function getUserDetails(self, profileData) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  axios.post(`${apiUrl}/api/account/get_user_details`, profileData, header).then(
    response => {
      if (response.data.status == "success") {
 
        let userTmp = JSON.parse(localStorage.getItem("user"));
        userTmp.data.user_name = response.data.user_name;
        userTmp.data.additional_email = response.data.additional_email;
        userTmp.data.data = response.data.data;
        localStorage.setItem("user", JSON.stringify(userTmp));
        self.setState({
          userList: {
            ...self.state.userList,
            userName: response.data.user_name,
            additional_email: response.data.additional_email,
            user_info: response.data.user_info,
          },
          updateProfileSpin:false
        });
         
      } else if (response.data.status === "error") {
        self.setState({
          errors: {
            ...self.state.errors,
            currentPass: response.data.message
          },
          updateProfileSpin:false
        });
        
      }
    },
    error => {
      let msg = error.response.data.message;
      console.log('msg',error.response)
      self.setState({updateProfileSpin:false });
      
    }
  );
}

function editUserInfo(self, userInfoData) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  axios.post(`${apiUrl}/api/account/edit_user_info`, {userInfoData:userInfoData}, header).then(
    response => {
      if (response.data.status === "success") {
        let userTmp = JSON.parse(localStorage.getItem("user"));
        userTmp.data.user_info = response.data.user_info;
        userTmp.data.user_info = response.data.user_info;
        userTmp.data.mobile = response.data.mobile;
        localStorage.setItem("user", JSON.stringify(userTmp));
        self.setState({
          userList: {
            ...self.state.userList,
            userName: response.data.user_name,
            additional_email: response.data.additional_email,
            user_info: response.data.user_info,
            mobile: response.data.mobile,
          },
          updateProfileSpin:false
        });
        ToastsStore.success("Success, Profile information is updated!");
      } else if (response.data.status === "error") {
        self.setState({
          errors: {
            ...self.state.errors,
            currentPass: response.data.message
          },
          updateProfileSpin:false
        });
        ToastsStore.error("Sorry, "+response.data.message)
      }
    },
    error => {
      let msg = error.response.data.message;
      console.log('msg',error.response)
      let msgTmp = msg.split(":");
      let msgError = msgTmp && msgTmp[2] ? msgTmp[2].substr(0,21) : "";
      if (
        msgError ===
        " 1062 Duplicate entry"
      ) {
        let errors = [];
        errors["mobile"] = "Mobile number is used already!";
        self.setState({ errors: errors, updateProfileSpin:false });
        ToastsStore.error("Sorry, update profile failed: "+msgError)
        return false
      }
      console.log(error.response.data.message)
      self.setState({updateProfileSpin:false });
      ToastsStore.error("Sorry, update profile failed: "+msgError)
    }
  );
}

function checkBeneficiaryData(userId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  axios
    .get(`${apiUrl}/api/beneficiary/getById/${userId}`, header)
    .then(response => {
      if (response.data.length > 0) {
        self.setState({ benefeciaryDataExist: true });
        self.setState({ benificiaryLoading: false });
      } else {
        self.setState({ benefeciaryDataExist: false });
        self.setState({ benificiaryLoading: false });
      }
    })
    .catch(function(error) {
      // handle error
    });
}

function checkMedicalData(userId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  axios
    .get(`${apiUrl}/api/medichistory/getPersonTypeDataCount/${userId}`, header)
    .then(response => {
      console.log("Data size :", response.data);
      if (
        response.data.meCount > 0 ||
        response.data.momCount > 0 ||
        response.data.dadCount > 0 ||
        response.data.partnerCount > 0
      ) {
        self.setState({ medicalDataExist: true });
        self.setState({ medicalLoading: false });
      } else {
        self.setState({ medicalDataExist: false });
        self.setState({ medicalLoading: false });
      }
    })
    .catch(function(error) {
      // handle error
    });
}

function login(email, password, self) {
  let data = JSON.stringify({
    email: email,
    password: password
  });

  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };
  let progressStatus = false;
  let accountWizard = false;
 
  return axios
    .post(`${apiUrl}/api/login`, data, header, { credentials: "include" })
    .then(user => {
    
      if (user) {
        let now = new Date(),
        expires = new Date();
        expires.setMinutes(now.getMinutes() + 30);
        localStorage.setItem("auth_expires", expires);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem('package_info',user.data.sub_plan)
        const package_info = user.data.sub_plan
        
        let user_type = user.data.user_type;
        if (user_type === "primary") {
          localStorage.setItem("user_type", "primary");
        } else if (user_type === "beneficiary") {
          localStorage.setItem("user_type", "bn");
        } else if (user_type === "contributor") {
          localStorage.setItem("user_type", "contributor");
        }

        progressStatus = user.data.account_progress_status;

        accountWizard = user.data.account_wizard;
        const registration = MainServices.getBStore('reg_process')
        if(registration){
          MainServices.removeStore('reg_process')
        }
        
        if((user_type === "beneficiary" || user_type === "contributor") && 
          (user.data && user.data.status==="expired")){
          MainServices.setBStore('trial_process','trial_process')
          self.props.history.push("/trialpriceplanbeneficiary")
          return false
        }else if((user_type === "beneficiary"  || user_type === "contributor") && 
        user.data && user.data.status==="unsubscribed"){
          MainServices.setBStore('trial_process','trial_process')
          self.props.history.push("/unsubscribedbeneficiary")
          return false
        }
        if (user_type === "beneficiary") {
          checkOTP(self); //check exists otp settings
          return 1;
        }else if(user_type==="primary"){
          
          if(package_info==="NA"){
            
            MainServices.setBStore('trial_process','trial_process')
         
            //const history = useHistory();
            window.location.href='/selectpackage';
 
            return false;
          }else if(user.data &&user.data.status_unsubscribed==="unsubscribed"){
 
            MainServices.setBStore('trial_process','trial_process')
            self.props.history.push("/unsubscribedpackage")
            return false
          }else if(user.data &&user.data.status==="expired") {
 
            MainServices.setBStore('trial_process','trial_process')
            self.props.history.push("/trialpriceplan")
            return false
          }else{
            MainServices.removeStore('trial_process')
          }
        }else if(user_type==="contributor"){
          
         if(user.data &&user.data.status_unsubscribed==="unsubscribed"){
            MainServices.setBStore('trial_process','trial_process')
            self.props.history.push("/unsubscribedpackage")
            return false
          }else if(user.data &&user.data.status==="expired") {
            MainServices.setBStore('trial_process','trial_process')
            self.props.history.push("/trialpriceplan")
            return false
          }else{
            MainServices.removeStore('trial_process')
          }
        }

        if (accountWizard.length < 1) {
          self.props.history.push("/onboard2fa");
          return 1;
        } else {
          let step01 = checkWizard(accountWizard, "step-01");
          if (!step01.length) {
            self.props.history.push("/onboard2fa");
            return 1;
          }         
          checkOTP(self); //check exists otp settings
        } //accountWizard.length
      }
      ToastsStore.success("Successfully login thisheart");
    })
    .catch(function(error) {
      // handle error
  
      self.setState({
        loginFailed: true,
        errorMessage: {
          user_type:
            typeof self.state.errorMessage.user_type === "undefined"
              ? ""
              : self.state.errorMessage.user_type
        },
        loading: false,
        btnActive: true
      });
 
      if (error.response) {
        self.setState({
          loginFailed: true,
          errorEmail: error.response.data.message,
          errorMessage: error.response.data.message,
          loading: false,
          statusCode: error.response.status
        });
      } else {
        self.setState({
          loginFailed: true,
          errorEmail: error.message,
          errorMessage: error.message,
          loading: false,
          statusCode: 0
        });
      }

      if (error.response && error.response.data.code === "user_type") {
        self.setState({
          errorMessage: { user_type: error.response.data.message }
        });
      }
      ToastsStore.warning("Something is wrong!!,  "+(error.response&&error.response.data.message));
    });
    return progressStatus
}


function adminLogin(email, password, self) {
  let data = JSON.stringify({
    user_name: email,
    password: password
  });

  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };
  
  return axios
    .post(`${apiUrl}/api/adminlogin`, data, header, { credentials: "include" })
    .then(user => {
      MainServices.setBStore("adminlogin","adminlogin")
      if (user.data.status==="success") {
        const activation_code = self.props.match.params.activation_code
        self.props.history.push(`/reqadmin/${activation_code}`);
        self.setState({loading:false})
      }
    }, error=>{
      self.setState({loading:false})
      ToastsStore.warning("Something is wrong!!,  "+(error.response&&error.response.data.message));
    })
}

function approvedFreeAccount(self, requestedData) {
  let data = JSON.stringify({
    freeAccountRequested: requestedData.reqested,
    activationCode: requestedData.activation_code
  });

  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };
  
  return axios
    .post(`${apiUrl}/api/approvedFreeAccount`, data, header, { credentials: "include" })
    .then(response => {
      console.log('response..........', response.data)
      MainServices.setBStore("adminlogin","adminlogin")
      if (response.data.status==="success") {
        ToastsStore.success("Successfully free account setup is processed for ThisHeart");
      }
      self.setState({loadingFreeAccount:false})
    }, error=>{
      ToastsStore.warning("Something is wrong!!,  "+(error.response&&error.response.data.message));
      self.setState({loadingFreeAccount:false})
    })
}


function checkOTP(self) {
  OTPAuthService.isExistsOTP().then(
    status => {
      if (status.data.status === "success") {

        if (
          status.data.data === "enable" &&
          (status.data.method === "sms" || status.data.method === "email")
        ) {
          self.props.history.push("/verifytwofa");
        } else if (
          status.data.data === "enable" &&
          status.data.method === "googleauth"
        ) {
          self.props.history.push({
            pathname: "/verifyqrcode",
            state: { qr_url: status.data.qr_url }
          });
        } else if (status.data.data === "disable") {
          //self.props.history.push(`/FirstPackageSelection/${encrypt_url()}`);
          self.props.history.push("/enrolltwofa");
        } else if (status.data.data === "not-found") {
          //self.props.history.push(`/FirstPackageSelection/${encrypt_url()}`);
          self.props.history.push("/enrolltwofa");
        }
      }
    },
    error => {
      let errMsg = "";
      console.log('OTP-Error::', error)
      errMsg = error.response.data.message.split(":")[1];
      self.setState({
        loginFailed: true,
        errorEmail: errMsg,
        errorMessage: errMsg,
        loading: false
      });
    }
  );
}

function register(email, userName, password, self) {
  let data = {
    email: btoa(email),
    name: btoa(userName),
    password: btoa(password),
    beneficiary_id: btoa("0")
  };

  let header = {
    headers: {
      "Content-Type": "application/json"
      // Authorization: authHeader()
    }
  };

  return axios.post(`${apiUrl}/api/register`, data, header);
}

function verifyEmail(data, self) {
  let header = {
    headers: {
      "Content-Type": "application/json"
      // Authorization: authHeader()
    }
  };

  console.log(data);
  return axios.get(
    `${apiUrl}/api/email_verification/` + data.url_token + "/" + data.email,
    header
  );
}

function logout(property) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  axios
    .get(`${apiUrl}/api/logout`, header)
    .then(response => {
      console.log(response);
      ToastsStore.warning("Logout from the application!");
      return true;
    })
    .catch(function(error) {
      // handle error
    });
  localStorage.removeItem("user");
  localStorage.removeItem("twofa");
  localStorage.removeItem("select_package");
  property.history.push("/login");
}

function resetPasswordRequest(email, self) {
  let header = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  let data = JSON.stringify({
    email: email
  });

  axios
    .post(`${apiUrl}/api/password/email`, data, header)
    .then(response => {
      self.setState({
        requestFailed: false,
        serverMessage: response.data.message,
        statusCode: response.status,
        loading: false
      });
    })
    .catch(function(error) {
      // handle error
      if (error.response) {
        self.setState({
          requestFailed: true,
          serverMessage: error.response.data.message,
          loading: false,
          statusCode: error.response.status
        });
      }
    });
}

function resetPasswordValidate(email, token, password, self) {
  let header = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  let data = JSON.stringify({
    email: email,
    token: token,
    password: password
  });

  axios
    .post(`${apiUrl}/api/password/reset`, data, header)
    .then(response => {
      self.props.history.push("/login");

      self.setState({
        resetFailed: false,
        errorMessage: response.data.message,
        statusCode: response.status,
        loading: false
      });
    })
    .catch(function(error) {
      // handle error
      if (error.response) {
        self.setState({
          resetFailed: true,
          errorMessage: error.response.data.message,
          loading: false,
          statusCode: error.response.status
        });
      } else {
        console.log("Error", error.message);
      }
    });
}

function checkWizard(wizs, step) {
  let wizard = wizs.filter(w => {
    if (w.steps === step && w.status === 1) {
      return true;
    }
  });
  return wizard;
}
