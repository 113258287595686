import React, { Component } from "react";
import {
  Media,
  Col,
  Row,
  Form,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import logoImage from "./images/logo-img.png";
import loaderImg from "./images/loader.gif";
import OTPAuthService from "./../services/otpauth.service";
import "./styles/enable2fa.css";
import PhoneInput from "react-phone-number-input";

import { Helmet } from "react-helmet";
import { encrypt_url } from "../helpers/encrypt-url";

class EnrollTwoFactorAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStep: "email",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      statusCode: "",
      mobile: "",
      encryptedString: encrypt_url(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMobile = this.handleMobile.bind(this);
  }

  handleChange = (e) => {
    let { value } = e.target;
    this.setState({ selectedStep: value });
    this.setState({ submitted: false });
  };

  handleMobile(e) {
    let { value } = e.target;
    this.setState({ mobile: value });
    this.setState({ submitted: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
      errorMobile: "",
    });

    let { selectedStep, mobile } = this.state;

    if (selectedStep === "sms" && mobile === "") {
      this.setState({ errorMobile: "Please input mobile number!" });
      return false;
    }

    if (selectedStep) {
      this.setState({ loading: true });
      OTPAuthService.generateOTP(selectedStep, mobile, this).then(
        (response) => {
          console.log(response.data);
          let responseData = response.data;
          if (responseData.status === "success") {
            if (
              responseData.method === "sms" ||
              responseData.method === "email"
            ) {
              this.props.history.push("/verifytwofa");
            } else if (responseData.method === "googleauth") {
              this.props.history.push({
                pathname: "/verifyqrcode",
                state: { qr_url: responseData.data },
              });
            }
          } else {
            this.setState({
              errorMessage: response.data.message,
              loading: false,
            });
          }
        },
        (error) => {
          let errorMsgTmp = "";
          console.log("Error: ", error.response.data.message);
          let errorMsg = error.response.data.message.split(":");

          if (typeof errorMsg[1] != "undefined" && errorMsg[1].substr(0, 16) === " The 'To' number") {
            errorMsgTmp = "Mobile number is invalid!";
          } else if (
            typeof errorMsg[2] != "undefined" &&
            errorMsg[2].substr(0, 21) === " 1062 Duplicate entry"
          ) {
            errorMsgTmp = "Mobile number is exists already!";
          } else {
            errorMsgTmp = error.response.data.message;
          }
          this.setState({
            errorMessage: errorMsgTmp,
            loading: false,
          });
        }
      );
    }
  }

  twoFactorProcess = () => {
    let optionSelect = "";
    optionSelect = (
      <div>
        <div className="option-list">
         
          <FormGroup check>
            <Label check className="lbl-method ">
              <b>Email</b>
              <Input
                type="radio"
                name="radio"
                value="email"
                id="email"
                onChange={this.handleChange}
                defaultChecked
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check className="lbl-method ">
              <b>SMS</b>
              <Input
                type="radio"
                name="radio"
                value="sms"
                id="sms"
                onChange={this.handleChange}
                
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check className="lbl-method ">
              <b>Google Authenticator</b>
              <Input
                type="radio"
                name="radio"
                value="googleauth"
                id="googleauth"
                onChange={this.handleChange}
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>
          <hr />
          {this.state.selectedStep === "sms" && (
            <FormGroup>
              <Label style={{ color: "#212529" }}>
                <b>Mobile</b>
              </Label>

              <PhoneInput
                name="mobile"
                id="mobile"
                placeholder="Enter mobile number"
                onChange={(mobile) => {
                  this.setState({ mobile });
                  this.setState({ errorMobile: "" });
                }}
              />
              <div
                className="help-block"
                style={{ width: "100%", marginTop: "15px" }}
              >
                {this.state.errorMobile}
              </div>
            </FormGroup>
          )}
        </div>
      </div>
    );
    return optionSelect;
  };

  skipButton = () => {
    const user_type = localStorage.getItem("user_type");

    localStorage.setItem("twofa", true);
    if (user_type === "primary") {
      this.props.history.push(`/dashboard/${this.state.encryptedString}`);
    } else if (user_type === "contributor") {
      this.props.history.push(`/dashboard-contributors/${this.state.encryptedString}`);
    } else if (user_type === "bn") {
      this.props.history.push(
        `/beneficiaryentry/${this.state.encryptedString}`
      );
    }
  };

  render() {
    return (
      <div className="register-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Enroll Authentication</title>
        </Helmet>
        <Row>
          <Col sm={{ offset: 2, size: 8 }}>
            <Media left className="logo-image-enroll">
              <Media object src={logoImage} alt="image" />
            </Media>

            <h3 className="heading">Enable Two Factor Authentication</h3>
            <p className="parag">
              <b>
                Two factor authentication (2FA) Secure Your ThisHeart Account
              </b>{" "}
              Because stronger passwords alone aren't enough to protect your
              account from password breaches. Two factor authentication protects
              against phishing, social engineering and password brute force
              attacks and secures your account from attackers exploiting weak or
              stolen credentials.
            </p>
            <p className="parag">
              To{" "}
              <span className="higlight">Enable Two Factor Authentication</span>{" "}
              on your Account, login through any one of the following:
            </p>

            <Form className="register-form" onSubmit={this.handleSubmit}>
              {this.twoFactorProcess()}

              <div>
                {this.state.loading && (
                  <img className="loader-img" alt="loaderImg" src={loaderImg} />
                )}

                <div className="help-block mt-3 mb-3 text-center">
                  {this.state.errorMessage}
                </div>
                <Button className="btn-enable">Enable 2FA</Button>
              </div>
              <div>
                <Button className="btn-skip" onClick={this.skipButton}>
                  Skip
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EnrollTwoFactorAuth;
