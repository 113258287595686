import React, { Component } from "react";
import "./styles/homeIndex.css";
import "bootstrap/dist/css/bootstrap.min.css";
import trialPackage from "../pages/images/endtrialphoto.png";
import { encrypt_url } from "../helpers/encrypt-url";
import { Helmet } from "react-helmet";
import PackagesServices from "../services/packages.service";
import { ToastsStore } from "react-toasts";

class UnsubscribedPackage extends Component {

  constructor(props){
    super(props)
    this.state = {
      userPackage:'',
      allPackages:'',
      prev_selected_package:'',
      encryptedString: encrypt_url(),
    }
  }

  componentDidMount(){
    this.getPackageByUser()
    localStorage.setItem('trial_package','yes')
    const user_type = localStorage.getItem('user_type')
    if(user_type!=="primary"){
      this.props.history.push('/login')
      return 1;
    }
  }
 

  getPackageByUser = () => {
    PackagesServices.getPackageByUser(this).then(
      (response) => {
        if (response.data.status === "success") {
          const allPackage = response.data.all_package_list;
          let pkgList = allPackage.filter(pkg=>{
            if( pkg.package.toLowerCase()!=="admin" && pkg.package.toLowerCase()!=="trial package"){
              return true
            }
          });
          
          console.log('user packages:......', response.data)

          const starter_pkg = pkgList.filter(pkg=>(pkg.package.toLowerCase()==="STARTER".toLowerCase()))
          const access_pkg = pkgList.filter(pkg=>(pkg.package.toLowerCase()==="ACCESS".toLowerCase()))
          const family_pkg = pkgList.filter(pkg=>(pkg.package.toLowerCase()==="FAMILY".toLowerCase()))
          this.setState({
            starter_pkg:starter_pkg,
            access_pkg:access_pkg,
            family_pkg:family_pkg,
            userPackage: response.data.data,
            prev_selected_package: response.data.data.package.toLowerCase(),
            allPackages: pkgList,
          });
        }
      },
      (error) => {
        console.log(error);
        ToastsStore.error(
          "Sorry, package info error, " + error.response.data.message
        );
      }
    );
  };

 

  selectedPackage = () =>{
    return (
      <div className="banner-pri-love">
      <span className="star">
        <i className="fa fa-heart" />
      </span>
    </div>
    )
  }


  selectPackage = (package_id, e) =>{
    e.preventDefault();
    console.log('hello world.....', package_id)
    if(package_id){
      this.setState({
        selected_plan: package_id,
        loadingPkg: true
      })
    }
    let data = {
      sub_plan: package_id,
      trial_end:'yes'
    };
    PackagesServices.savePackageInfo(this, data).then(
      (response) => {
        if (response.data.status === "success") {

          this.props.history.push(`/packagepayment/${this.state.encryptedString}`)
          return true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }


  render() {
    return (
      <div id="landing-home">
         <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-End Trial</title>
        </Helmet>
        {/* <Button close aria-label="Cancel"></Button> */}
        <img
          src={trialPackage}
          alt="trialPhoto"
          className="img-fluid mx-auto d-block "
        />
        <div className="text-center">
          <h3 style={{ color: "#7E7D7E" }}> Oy! Account is unsubscribed/blocked.</h3>
          <h2 style={{ color: "#f26667" }}>Let's keep the love going.</h2>
          <h6 style={{ color: "#7E7D7E" }}>Pick a package to reactive your account.</h6>
        </div>

        {/* start pricing area  */}
        <div id="pricing" className="mt-4">
          <div className="container">
            <div className="price-title-head">
              {/* <h2 className="text-center">Start leaving things behind today</h2>
              <span>The first 30 days are free </span> */}
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="pri_table_list">
                  {this.state.prev_selected_package==="starter" && this.selectedPackage()}
                  <h3>Starter</h3>
                  <div className="price">
                    <span className="currency">$</span>
                    <span className="value">9.99</span>
                    <span className="type">\mo</span>
                  </div>
                  <p className="title-desc">TEST THE WATERS</p>
                  <hr />

                  <ol>
                    <li>10Gb of Data Storage</li>
                    <li>Store 10 accounts</li>
                    <li>Access to 2 Beneficiaries</li>
                    <li>Email User Support </li>
                  </ol>

                  <button type="submit" className="price-btn"  onClick={this.selectPackage.bind(this,this.state.starter_pkg && this.state.starter_pkg[0].id)}>
                    Select this plan
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="pri_table_list ">
                  {this.state.prev_selected_package==="access" && this.selectedPackage()}
                  <h3>ACCESS</h3>
                  <div className="price">
                    <span className="currency">$</span>
                    <span className="value">19.99</span>
                    <span className="type">\mo</span>
                  </div>

                  <p className="title-desc">ALL ACCESS</p>
                  <hr />
                  <ol>
                    <li>Unlimited Data Storage</li>
                    <li>Unlimited Account Storage</li>
                    <li>Unlimited Beneficiary Access</li>
                    <li>Email and Chat Support</li>
                  </ol>
                  <button type="submit" className="price-btn" onClick={this.selectPackage.bind(this,this.state.access_pkg && this.state.access_pkg[0].id)}>
                    Select this plan
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="pri_table_list">
                  {this.state.prev_selected_package==="family" && this.selectedPackage()}
                  <h3>FAMILY</h3>
                  <div className="price">
                    <span className="currency">$</span>
                    <span className="value">39.99</span>
                    <span className="type">\mo</span>
                  </div>

                  <p className="title-desc">
                    AN ACCOUNT FOR YOU AND 3 OF YOUR FAMILY MEMBERS
                  </p>
                  <hr />
                  <ol>
                    <li>All of the Access Plan Features</li>
                    <li>For up to 3 of your family members.</li>
                    <li>That's 4 total accounts including yours</li>
                  </ol>
                  <button type="submit" className="price-btn" onClick={this.selectPackage.bind(this,this.state.family_pkg && this.state.family_pkg[0].id)}>
                    Select this plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End pricing table area */}
      </div>
    );
  }
}
export default UnsubscribedPackage;
