import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaCircle, FaEye, FaPlay, FaMusic, FaPlus, FaMinus } from "react-icons/fa";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "./styles/beneficiarylanding.css";
import zeroState from "../pages/images/memzero.png";
import bnaudioEmpty from "../pages/images/bnaudiozero.png";

import { userService } from "../services/user.service";
import { memoriesService } from "../services/memories.service";
import { Helmet } from "react-helmet";
import Fullscreen from "react-full-screen";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { beneficiaryUserService } from "../services/beneficiary.user.service";
import apiService from "./../services/api.service";
import Header from "./../components/Header";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
import "./customCarouselStyles.css";
import "font-awesome/css/font-awesome.min.css";
import left from "./images/left.svg";
import right from "./images/right.svg";
import audioView from "./images/audioPlaceHolder.png";
import videoView from "./images/videoPlaceHolder.png";
import mediaView from "./images/imagePlaceHolder.png";
import letterView from "./images/letter_view.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Collapse, CardBody, Card } from 'reactstrap';
import LeftSidebar from "../components/LeftSidebar";


import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Media,
  Col,
  Row,
  Form,
  Label,
} from "reactstrap";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const apiUrl = apiService.apiUrl;

class BeneficiaryLanding extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.handleLogout = this.handleLogout.bind(this);

    this.state = {
      activeTab: "1",
      dropdownOpen: false,
      preview: false,
      loadingLetter: false,
      statusCode: "",
      subject: "",
      description: " ",
      uploadBtnSts: "false",
      imageOpen: false,
      imageLists: [],
      imgPathModal: 0,
      videoLists: [],
      letterLists: [],
      letterListTemp: [],
      recordLists: [],
      socialImageList: [],
      socialVideoList: [],
      isVisible: true,
      letterId: 0,
      userObj: JSON.parse(user),
      userType: localStorage.getItem("user_type"),
      primary_user: "",
      isFull: false,
      selectedLetterItem: "",
      allMemories: "",
      centerSlideIndex: 0,
      audioOpen: false,
      currentAudio: null,
      currentLetterIndex: 0,
      isLetterModalOpen: false,
      currentView: 'images',
      openAccordion: null,
    };
    this.scrollToCarouselViewer = this.scrollToCarouselViewer.bind(this);
  }
  // console it this.state

  componentDidMount() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }

    memoriesService.getImageData(userId, this);
    memoriesService.getVideoData(userId, this);
    memoriesService.getLetterData(userId, this);
    memoriesService.getRecordData(userId, this);
    this.setState({
      primary_user: this.state.userObj.data.primary_user,
    });
  }
  goFull = () => {
    this.setState({ isFull: true });
  };
  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleLogout = () => {
    if (this.state.dropdownOpen) {
      if (this.state.userType === "bn") {
        beneficiaryUserService.logoutBeneficiaryUser(this.props);
      } else {
        userService.logout(this.props);
      }
    }
  };

  // for accordion
  toggleAccordion = (section) => {
    if (this.state.openAccordion === section) {
      this.setState({ openAccordion: null });
    } else {
      this.setState({ openAccordion: section });
    }
  };

  toggleAccordion = (section) => {
    this.setState({ openAccordion: this.state.openAccordion === section ? null : section });
  };

  renderAccordionHeader = (section) => {
    const isOpen = this.state.openAccordion === section;
    return (
      <CardBody
        onClick={() => this.toggleAccordion(section)}
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: isOpen ? '#f8f9fa' : '#ffffff',
          color: isOpen ? 'red' : '#333',
          borderBottom: '1px solid #ddd',
          padding: '10px',
          transition: 'all 0.9s',
          marginBottom: '0px',
          borderRadius: '0px',
        }}
      >
        <span>{section.charAt(0).toUpperCase() + section.slice(1)}</span>
        {isOpen ? <FaMinus size={15} /> : <FaPlus size={15} />}
      </CardBody>
    );
  };


  // for view change
  setViewToImages = () => {
    this.setState({ currentView: 'images' });
  };

  setViewToVideos = () => {
    this.setState({ currentView: 'videos' });
  };

  setViewToAudios = () => {
    this.setState({ currentView: 'audios' });
  };

  setViewToLetters = () => {
    this.setState({ currentView: 'letters' });
  };


  toggleProfilePhoto = () => {
    this.setState((prevState) => ({
      profilePhoto: !prevState.profilePhoto,
    }));
  };

  showFirstImage = () => {
    console.log("imageLists", this.state.imageLists);
    let imgObject = [];

    let imgPath = apiUrl;
    if (this.state.imageLists.length !== 0) {
      imgObject = this.state.imageLists.slice(0, 1).map((item, i) => (
        <div key={i}>
          <Media
            object
            src={imgPath + "/" + item.filename}
            className="single-image"
            alt="Images"
          />
        </div>
      ));
    } else {
      imgObject = (
        <div className="container">
          <div className="row ">
            <img
              className="img-fluid mx-auto d-block"
              src={zeroState}
              alt="love-pat"
            />
          </div>
        </div>
      );
    }
    return imgObject;
  };


  // for image showing work start
  showImageCarousalGallery = () => {

    function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
        <div
          className={"custom-arrow custom-prev-arrow"}
          onClick={onClick}
        >
          <img src={left} alt="Prev" />
        </div>
      );
    }
    function SampleNextArrow(props) {
      const { onClick } = props;
      return (
        <div
          className={"custom-arrow custom-next-arrow"}
          onClick={onClick}
        >
          <img src={right} alt="Next" />
        </div>
      );
    }

    const settings = {
      dots: false, // Disable bottom dots
      infinite: true,
      speed: 500,
      centerMode: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '100px',
      arrows: true, // Enable arrows
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ],

      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    // Function to check if the URL points to an image
    const isImageUrl = (url) => {
      return /\.(jpg|jpeg|png|gif)$/i.test(url);
    };
    return (
      console.log("imageLists1", this.state.imageLists),
      <Slider {...settings}>
        {this.state.imageLists.filter(item => isImageUrl(item.filename)).map((item, index) => (
          <div key={index} className="img-divcontainer" onClick={() => this.handleImagePopUp(item)}>
            <img src={`${apiUrl}/${item.filename}`} alt="slide" />
            <div className="right-bottom-icon">
              {/* <button
                className="viewimage-eye"
                onClick={() => this.handleImagePopUp(item)}
              >
                <FaEye />
              </button> */}
            </div>
          </div>
        ))}
      </Slider>
    );
  };

  handleImagePopUp = (imgObj) => {
    this.setState({
      imageOpen: !this.state.imageOpen,
      imgPathModal: `${apiUrl}/${imgObj.filename}`,
    });
  };
  showViewMorePhotos = () => {

    return (
      <Button href="/memories-images" className="d-flex btn bg-white text-dark view-more-btn" onClick={this.reloadPage}>
        View More <i className="fas fa-eye d-md-block d-none ml-1 my-auto"></i>
      </Button>
    );

  };
  // memories-videos
  showViewMoreVideos = () => {

    return (
      <Button href="/memories-videos" className="d-flex btn bg-white text-dark view-more-btn" onClick={this.reloadPage}>
        View More <i className="fas fa-eye d-md-block d-none ml-1 my-auto"></i>
      </Button>
    );

  };
  //memories-audios
  showViewMoreAudios = () => {

    return (
      <Button href="/memories-audios" className="d-flex  btn bg-white text-dark view-more-btn" onClick={this.reloadPage}>
        View More <i className="fas fa-eye d-md-block d-none ml-1 my-auto"></i>
      </Button>
    );

  };


  // memories-letters
  showViewMoreLetters = () => {

    return (
      <Button href="/memories-letters" className="d-flex btn bg-white text-dark view-more-btn" onClick={this.reloadPage}>
        View More <i className="fas fa-eye d-md-block d-none ml-1 my-auto"></i>
      </Button>
    );

  };
  // for image showing work end

  // for video showing work start
  showVideoCarousalGallery = () => {
    const SamplePrevArrow = ({ onClick }) => (
      <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
        <img src={left} alt="Prev" />
      </div>
    );

    const SampleNextArrow = ({ onClick }) => (
      <div className="custom-arrow custom-next-arrow" onClick={onClick}>
        <img src={right} alt="Next" />
      </div>
    );

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      centerMode: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '100px',
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

    return (
      console.log("videoLists 2", this.state.videoLists),
      <Slider {...settings}>
        {this.state.videoLists.map((video, index) => (
          <div key={index} className="video-divcontainer mt-2">
            <video width="100%" Height="226px" style={{ borderRadius: "7px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }} preload="metadata">
              <source src={`${apiUrl}/${video.filename}`} type="video/mp4" />
            </video>
            <div className="video-right-bottom-icon">
              <button className="viewimage-eye" onClick={() => this.handleVideoPopUp(video)}>
                <FaPlay />
              </button>
            </div>
          </div>
        ))}
      </Slider>
    );
  };


  handleVideoPopUp = (video) => {
    this.setState({
      videoOpen: true,
      currentVideo: `${apiUrl}/${video.filename}` // Store the full path for modal display
    });
  };
  // for video showing work end


  truncateText = (htmlContent, wordLimit) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    const textContent = div.textContent || div.innerText || "";
    const words = textContent.split(' ');

    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(' ')}... see more`;
    } else {
      return textContent;
    }
  };

  showLetterCarousalGrid = () => {
    const SamplePrevArrow = ({ onClick }) => (
      <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
        <img src={left} alt="Prev" />
      </div>
    );

    const SampleNextArrow = ({ onClick }) => (
      <div className="custom-arrow custom-next-arrow" onClick={onClick}>
        <img src={right} alt="Next" />
      </div>
    );

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      centerMode: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '100px',
      arrows: true,
      responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
        { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
      ],
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

    return (
      console.log("letterLists 4", this.state.letterLists),
      <Slider {...settings}>
        {this.state.letterLists.map((letter, index) => (
          <div key={index} className="letter-grid">
            <div className="letter-content">
              <h5 dangerouslySetInnerHTML={{ __html: this.truncateText(letter.subTxt, 7) }}></h5>
              <div dangerouslySetInnerHTML={{ __html: this.truncateText(letter.desTxt, 15) }} />
              <button className="viewimage-eye-letter" onClick={() => this.handleLetterOpen(index)}>
                <FaEye />
              </button>
            </div>
          </div>
        ))}
      </Slider>
    );

  };

  // Function to handle letter opening
  handleLetterOpen = (index) => {
    this.setState({ currentLetterIndex: index, isLetterModalOpen: true });
  };

  // Function to toggle the letter modal
  toggleLetterModal = () => {
    this.setState(prevState => ({ isLetterModalOpen: !prevState.isLetterModalOpen }));
  };

  showLetterList = () => {
    let listLtr = [];

    listLtr = this.state.letterLists.map((letterItem, i) => (
      <div className="letter-grid" key={i}>
        <React.Fragment>
          <Card
            className="letter-box"
            onClick={this.letterViewActiveDeactive.bind(this, letterItem)}
          >
            <span
              id={"letterId-" + letterItem.id}
              className={letterItem.active === 1 ? "circle-gray" : "circle-red"}
            >
              <FaCircle />{" "}
              <i
                className="fa fa-external-link full-view"
                aria-hidden="true"
                onClick={this.letterViewActiveDeactive.bind(this, letterItem)}
              ></i>
            </span>
            <p className="titlesub">
              {letterItem.subTxt} {letterItem.subTxt.length > 30 ? "..." : ""}
            </p>
            <div
              className="paragraph"
            //dangerouslySetInnerHTML={{ __html: letterItem.desTxt }}
            >
              <p>
                {letterItem.desTxt
                  .replace(
                    /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
                    " "
                  )
                  .substr(0, 100)}
                {letterItem.desTxt.length > 75 ? "..." : ""}{" "}
              </p>
            </div>
          </Card>
        </React.Fragment>
      </div>
    ));

    return listLtr;
  };
  toggleLetterViewButton = () => {
    this.setState((prevState) => ({
      preview: !prevState.preview,
    }));
  };

  letterViewActiveDeactive = (selectedLetter) => {
    let itemId = "letterId-" + selectedLetter.id;
    document.getElementById(itemId).removeAttribute("class");
    document.getElementById(itemId).setAttribute("class", "circle-gray");

    if (selectedLetter.active === 0) {
      memoriesService.changeStatus(selectedLetter, this);
    }
    this.setState({
      isFull: true,
      selectedLetterItem: selectedLetter,
    });
    this.letterPopView(selectedLetter);
  };

  letterPopView = (selectedLetter) => {
    let subj = this.state.subject;
    let desc = this.state.description;
    subj = selectedLetter.subTxt;
    desc = selectedLetter.desTxt;
    this.setState({ subj });
    this.setState({ desc });
  };

  showLetterFullScreenMode = () => {
    return this.state.selectedLetterItem.desTxt
      .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ")
      .substr(0, 100);
  };

  showAudioCarousalGrid = () => {
    const SamplePrevArrow = ({ onClick }) => (
      <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
        <img src={left} alt="Prev" />
      </div>
    );

    const SampleNextArrow = ({ onClick }) => (
      <div className="custom-arrow custom-next-arrow" onClick={onClick}>
        <img src={right} alt="Next" />
      </div>
    );

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      centerMode: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '100px',
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ],
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

    // Function to check if the URL points to an audio file
    const isAudioUrl = (url) => {
      return /\.(mp3|wav|ogg|m4a)$/i.test(url);
    };
    return (
      console.log("recordLists 3", this.state.recordLists),
      <Slider {...settings}>
        {this.state.recordLists.filter(item => isAudioUrl(item.filename)).map((audio, index) => (
          <div key={index} className="audio-divcontainer">
            <p className="audio-title" style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{audio.title}</p>
            <audio controls style={{ width: '100%' }}>
              <source src={`${apiUrl}/${audio.filename}`} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        ))}
      </Slider>
    );
  };

  handleAudioPopUp = (audio) => {
    this.setState({
      audioOpen: true,
      currentAudio: `${apiUrl}/${audio.filename}` // Store the full path for modal display
    });
  };
  showRecordList = () => {
    let recordPath = apiUrl;

    let listofRecord = [];
    listofRecord = this.state.recordLists.map((recorditem, i) => (
      <div key={i} className="col-md-3 audio-grid-list">
        <div className="text-div">
          <div className="head-title">{recorditem.title}</div>
          <span className="date">{recorditem.date}</span>
        </div>
        <div className="list-audio">
          <div className="audio-div">
            <audio className="player-custom" controls="controls">
              <source
                className="grid-audio"
                src={recordPath + "/" + recorditem.filename}
                type="audio/mp3"
              />
            </audio>
          </div>
        </div>
      </div>
    ));

    return listofRecord;
  };

  downloadAudio = () => {
    console.log("download file with new name:");
    document.getElementById("dwn_audio").download = "MyAudio";
  };
  /*****************************************   Memories Page Image Option Start   *****************************/

  disableBeneficiaryLink = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Link className="link-style-beneficiaries" to="/beneficiaries">
          Beneficiaries
        </Link>
      );
    }
  };

  /*****************************    Memories Page Record Option End  ******************************/

  primaryUserFirstName = (e) => {
    if (this.state.primary_user) {
      let fname = this.state.primary_user.name.split(" ");
      return fname[0] ? fname[0] : "Primary user ";
    } else {
      return "Primary user ";
    }
  };

  isImageUrl = (url) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  };

  scrollToCarouselViewer = () => {
    const carouselViewer = document.getElementById("mediaViewer");
    if (carouselViewer) {
      carouselViewer.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };



  renderImagesWithPlaceholders = () => {
    const { imageLists } = this.state;
    const validImages = imageLists.filter(image => this.isImageUrl(image.filename));
    const maxImagesToShow = 4;
    const imagesToShow = validImages.slice(0, maxImagesToShow);
    const placeholdersCount = maxImagesToShow - imagesToShow.length;

    return (
      <div className="row" id="mediaView" onClick={this.scrollToCarouselViewer}>
        {imagesToShow.map((image, index) => (
          <div className="col-md-3 mt-2">
            <img key={index} src={`${apiUrl}/${image.filename}`} style={{ borderRadius: "8px", width: '100%', height: '155px' }} onClick={this.setViewToImages} className=" img-fluid border" alt="Memory" />
          </div>
        ))}
        {[...Array(placeholdersCount)].map((_, index) => (
          <div className="col-md-3 mt-2">
            <img key={`placeholder-${index}`} src={mediaView} style={{ borderRadius: "8px", width: '100%', height: '155px' }} onClick={this.setViewToImages} className=" img-fluid border" alt="Placeholder" />
          </div>
        ))}
      </div>
    );
  };


  renderVideosWithPlaceholders = () => {
    const { videoLists } = this.state;
    const maxVideosToShow = 4;
    const placeholdersCount = Math.max(maxVideosToShow - videoLists.length, 0);

    return (
      <div className="d-flex flex-wrap justify-content-start" id="mediaView" onClick={this.scrollToCarouselViewer}> {/* Use Flexbox for layout */}
        {videoLists.slice(0, maxVideosToShow).map((video, index) => (
          <div key={index} className="col-md-3 mt-2">
            <div className="video-thumbnail" onClick={() => this.setViewToVideos(video)}>
              <video width="100%" height="auto" style={{ borderRadius: "7px", cursor: "pointer", width: '100%', height: '155px' }} preload="metadata">
                <source src={`${apiUrl}/${video.filename}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="play-icon-overlay">
                <FaPlay className="play-icon" />
              </div>
            </div>
          </div>
        ))}
        {[...Array(placeholdersCount)].map((_, index) => (
          <div key={`placeholder-${index}`} className="col-md-3 mt-3">
            <div className="video-thumbnail" onClick={this.setViewToVideos}>
              <img src={videoView} alt="Video Placeholder" className="img-fluid" style={{ borderRadius: "10px", width: '100%', height: '155px', cursor: "pointer" }} />
              <div className="play-icon-overlay">
                <FaPlay className="play-icon" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  renderAudiosWithPlaceholders = () => {
    const { recordLists } = this.state;
    const maxAudiosToShow = 4; // Maximum number of audios to show

    // Filter for valid audio URLs
    const validAudios = recordLists.filter(audio => /\.(mp3|wav|ogg|m4a)$/i.test(audio.filename));
    const audioItemsToShow = validAudios.slice(0, maxAudiosToShow); // Get up to the max number of valid audio files
    const placeholdersCount = Math.max(maxAudiosToShow - audioItemsToShow.length, 0); // Calculate the number of placeholders needed
    console.log("audioItemsToShow", audioItemsToShow);
    return (
      <div className="d-flex flex-wrap justify-content-start" id="mediaView" onClick={this.scrollToCarouselViewer}>
        {audioItemsToShow.map((audio, index) => (
          <div key={index} className="audio-container mt-2" onClick={() => this.setViewToAudios(audio)} style={{ backgroundColor: '#e3e3e3' }}>
            <div className="audio-thumbnail">
              <audio controls style={{ width: '100%' }}>
                <source src={`${apiUrl}/${audio.filename}`} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              <p className="audio-title" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: this.truncateText(audio.title, 1) }}></p>

              <div className="play-icon-overlay">
                <FaPlay className="play-icon" />
              </div>
            </div>
          </div>
        ))}
        {[...Array(placeholdersCount)].map((_, index) => (
          <div key={`placeholder-${index}`} className="audio-container" onClick={this.setViewToAudios}>
            <div className="audio-thumbnail">
              <img src={audioView} alt="Audio Placeholder" className="img-fluid" style={{ borderRadius: "10px", width: '100%', height: '155px' }} />

              <div className="play-icon-overlay">
                <FaPlay className="play-icon" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  renderLettersWithPlaceholders = () => {
    const { letterLists } = this.state;
    const maxLettersToShow = 4;
    const lettersToShow = letterLists.slice(0, maxLettersToShow);
    const placeholdersCount = maxLettersToShow - lettersToShow.length;

    return (
      <div className="letter-container d-flex gap-2" id="mediaView" onClick={this.scrollToCarouselViewer}>
        {lettersToShow.map((letter, index) => (
          <div key={index} className="letter-item col-md-3" onClick={this.setViewToLetters} >
            <div className="letter-preview"  >
              <h5 dangerouslySetInnerHTML={{ __html: this.truncateText(letter.subTxt, 2) }}></h5>
              <div dangerouslySetInnerHTML={{ __html: this.truncateText(letter.desTxt, 3) }} />
            </div>
          </div>
        ))}
        {[...Array(placeholdersCount)].map((_, index) => (
          <div key={`placeholder-${index}`} className="letter-item placeholder" onClick={this.setViewToLetters}>
            <div className="letter-preview-img">
              <img src={letterView} className="img-fluid mx-auto d-block" style={{ maxHeight: "100px", borderRadius: "10px" }} alt="Placeholder" />
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div id="main-wrapper" className="" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-header-position="fixed" data-boxed-layout="full" >
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Beneficiary Dashboard</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="container-fluid p-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12" id="carouselViewer">

                      {this.state.currentView === 'images' && this.showImageCarousalGallery()}
                      {this.state.currentView === 'videos' && this.showVideoCarousalGallery()}
                      {this.state.currentView === 'audios' && this.showAudioCarousalGrid()}
                      {this.state.currentView === 'letters' && this.showLetterCarousalGrid()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="memories-tab mt-5">
            <div className="container-fluid px-5">
              <div className="row">
                <div className="col-md-8 col-12 mb-3">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div><h3>Images</h3></div>
                      {/* add view more button */}
                      <div>
                        <a href="/memories-images" className="btn btn-outline-warning btn-sm ml-2" style={{ borderRadius: "5px", color: "black" }} onClick={this.setViewToImages}>View More</a>
                      </div>
                      {/* <div className="pt-1 ml-2"> <img src={lineOfColours} className="" /></div> */}
                    </div>
                    {this.renderImagesWithPlaceholders()}
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div><h3>Videos</h3></div>
                      <div>
                        <a href="/memories-videos" className="btn btn-outline-warning btn-sm ml-2" style={{ borderRadius: "5px", color: "black" }} onClick={this.setViewToVideos}>View More</a>
                      </div>
                      {/* <div className="pt-1 ml-2"> <img src={lineOfColours} className="" /></div> */}
                    </div>
                    {this.renderVideosWithPlaceholders()}
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div><h3>Audios</h3></div>
                      <div>
                        <a href="/memories-audios" className="btn btn-outline-warning btn-sm ml-2" style={{ borderRadius: "5px", color: "black" }} onClick={this.setViewToAudios}>View More</a>
                      </div>
                    </div>
                    {this.renderAudiosWithPlaceholders()}
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div><h3>Letters</h3></div>
                      <div>
                        <a href="/memories-letters" className="btn btn-outline-warning btn-sm ml-2" style={{ borderRadius: "5px", color: "black" }} onClick={this.setViewToAudios}>View More</a>
                      </div>
                      {/* <div className="pt-1 ml-2"> <img src={lineOfColours} className="" /></div> */}
                    </div>
                    {this.renderLettersWithPlaceholders()}
                  </div>
                </div>
                <div className="col-md-4 col-12 mt-4">
                  {/* Accordion for Videos */}
                  <Card style={{ marginBottom: '10px', overflow: 'hidden', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                    {this.renderAccordionHeader('What can I do as a Beneficiary on ThisHeart?')}
                    <Collapse isOpen={this.state.openAccordion === 'What can I do as a Beneficiary on ThisHeart?'}>
                      <Card className={'mb-0'}>
                        <CardBody style={{ textAlign: "justify", fontWeight:"300", marginBottom:"0px" }} className="p-3">
                          As a Beneficiary, you are allowed to view all the data that the Owner has made accessible to you. However, you are not permitted to add, edit, or delete any data.
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Card>

                  {/* Accordion for Audios */}
                  <Card style={{ marginBottom: '10px', overflow: 'hidden', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                    {this.renderAccordionHeader('Can I upload or modify any data as a Beneficiary?')}
                    <Collapse isOpen={this.state.openAccordion === 'Can I upload or modify any data as a Beneficiary?'}>
                      <Card className={'mb-0'}>
                        <CardBody style={{ textAlign: "justify", fontWeight:"300" }} className="p-3">
                          No, Beneficiaries do not have permission to upload or modify data. Your role is limited to viewing the content shared with you by the account Owner.
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Card>

                  {/* Accordion for Letters */}
                  <Card style={{ marginBottom: '10px', overflow: 'hidden', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                    {this.renderAccordionHeader('How do I access the data shared with me as a Beneficiary?')}
                    <Collapse isOpen={this.state.openAccordion === 'How do I access the data shared with me as a Beneficiary?'}>
                      <Card className={'mb-0'}>
                        <CardBody style={{ textAlign: "justify", fontWeight:"300" }} className="p-3">
                          Once you log in to your Beneficiary dashboard, you will have access to the documents, memories, and other data that the Owner has chosen to share with you. Everything is organized for easy navigation.
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Card>
                  <Card style={{ marginBottom: '10px', overflow: 'hidden', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                    {this.renderAccordionHeader('Can I share the data I can see with others?')}
                    <Collapse isOpen={this.state.openAccordion === 'Can I share the data I can see with others?'}>
                      <Card className={'mb-0'}>
                        <CardBody style={{ textAlign: "justify", fontWeight:"300" }} className="p-3">
                          No, as a Beneficiary, you cannot share or distribute the data. You only have the privilege to view the information within the platform for your personal reference.                        </CardBody>
                      </Card>
                    </Collapse>
                  </Card>
                  <Card style={{ marginBottom: '10px', overflow: 'hidden', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                    {this.renderAccordionHeader('What should I do if I cannot see some data that I expect to view?')}
                    <Collapse isOpen={this.state.openAccordion === 'What should I do if I cannot see some data that I expect to view?'}>
                      <Card className={'mb-0'}>
                        <CardBody style={{ textAlign: "justify", fontWeight:"300" }} className="p-3">
                          If certain data is not visible, it means that the Owner has either not shared that information with you or restricted access. You can reach out to the account Owner for clarification.                        </CardBody>
                      </Card>
                    </Collapse>
                  </Card>
                </div>


              </div>
            </div>
          </div>

          {/* all modals are below  */}
          <Modal isOpen={this.state.imageOpen} toggle={this.handleImagePopUp} size="lg">
            <ModalHeader toggle={this.handleImagePopUp}>Memories Image</ModalHeader>
            <ModalBody>
              <div className="img-effect">
                <img src={this.state.imgPathModal} className="mediaView" alt="" />
              </div>
            </ModalBody>
          </Modal>

          {/* Modal for displaying selected videos */}
          <Modal isOpen={this.state.videoOpen} toggle={() => this.setState({ videoOpen: false })} size="lg">
            <ModalHeader toggle={() => this.setState({ videoOpen: false })}>Video Playback</ModalHeader>
            <ModalBody>
              <video width="100%" controls>
                <source src={this.state.currentVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </ModalBody>
          </Modal>

          {/* for letter showing start */}

          <Modal isOpen={this.state.isLetterModalOpen} toggle={this.toggleLetterModal} className="custom-modal-class">
            <ModalHeader toggle={this.toggleLetterModal}>Letter Detail</ModalHeader>
            <ModalBody>
              {this.state.letterLists.length > 0 && (
                <>
                  <h5 className="letter-detail-header">{this.state.letterLists[this.state.currentLetterIndex].subTxt}</h5>
                  <div className="letter-detail-content" dangerouslySetInnerHTML={{ __html: this.state.letterLists[this.state.currentLetterIndex].desTxt }} />
                </>
              )}
            </ModalBody>
          </Modal>



        </div>
      </div>
    );
  }
}

export default BeneficiaryLanding;
