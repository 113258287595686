import React, { Component } from "react";
import { Link } from "react-router-dom";
// import $ from "jquery";

import { Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-ui/style.css";
import "./styles/onboarding2.css";
import { Helmet } from "react-helmet";
import StepServices from "../services/steps.service";
import { encrypt_url } from "../helpers/encrypt-url";

class OnBoardingStepTwo extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    this.state = {
      userType: localStorage.getItem("user_type"),
      userObj: JSON.parse(user),
      encryptedString: encrypt_url(),
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }
 
  pageRedirectPrevious = () => {
    this.props.history.push("/onboard2fa");
  };

  pageRedirectNext = () => {
    let data = {
      step: "step-02",
      info: "social data fetch",
    };
    StepServices.setSteps(this, data).then(
      (response) => {
        this.props.history.push("/onboardpass_manage");
      },
      (error) => {
        console.log(error);
      }
    );
    return false;
  };

  onGotToMemories = (e) => {
    e.preventDefault();
    localStorage.setItem("twofa", true);
    this.props.history.push(
      `/memories/${this.state.encryptedString}?actions=social`
    );
  };

  render() {
    return (
      <div className="container-fluid onboarding-two">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - OnBoarding Social Fetch</title>
        </Helmet>
        <div className="row">
          <div className="container image-step-two">
            <div className="col-sm-12 col-md-5 col-xs-6 text-social">
              <h4>
              SOCIAL POST <br />
                FETCHING
              </h4>
              <p className="step3-text">
                Let ThisHeart save all of your images and videos from your
                favorite social networks. It’s as easy as setting up your custom
                hashtag and tagging your posts. We’ll grab them and store them
                for later.
              </p>
              {/* <div className="try-block">
                <Button type="button" to={`/memories/${this.state.encryptedString}`} className="btn-try btn btn-link link-try" color="pink" onClick={this.onGotToMemories}></Button>
                <Button
                  type="button"
                  to={`/memories/${this.state.encryptedString}`}
                  className="btn-try btn btn-link link-try hidden"
                  color="pink"
                ></Button>

              </div> */}
            </div>
            <div className="row margin-bottom-40">
              <div className="col-sm-1 col-md-1 col-xs-1"></div>
              <div className="col-sm-10 col-md-10 col-xs-10 ml-3">
                <div className="prev-next">
                  <Button
                    className="mr-1"
                    onClick={this.pageRedirectPrevious}
                    style={{borderRadius:"7px"}}
                  >
                    <Link style={{color:"black"}} to="/?">&laquo; Prev</Link>
                  </Button>
                  <Button className="ml-1" onClick={this.pageRedirectNext}
                    style={{ borderRadius: "7px" }}
                  >
                    Next &raquo;
                  </Button>
                </div>
              </div>
             
            </div>
            <div className="row justify-content-end">
              <div className=" col-sm-1 col-md-1 col-xs-1">
                <div className="row text-right">
                  <p className="soc-skip">
                    <Link style={{color:"black"}} to={`/dashboard/${this.state.encryptedString}`}>
                      {localStorage.setItem("twofa", true)}
                      Skip
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OnBoardingStepTwo;
