import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaCircle, FaEye } from "react-icons/fa";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "./styles/beneficiarylanding.css";
import zeroState from "../pages/images/memzero.png";
import bnaudioEmpty from "../pages/images/bnaudiozero.png";

import { userService } from "../services/user.service";
import { memoriesService } from "../services/memories.service";
import { Helmet } from "react-helmet";
import Fullscreen from "react-full-screen";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { beneficiaryUserService } from "../services/beneficiary.user.service";
import apiService from "./../services/api.service";
import Header from "./../components/Header";
import { encrypt_url } from "../helpers/encrypt-url";
import profilePhoto from "../pages/images/dp.png";
import logoImage from "./images/thisheart_img.svg";
import loveIcon from "./images/loveIcon.svg";
import beneficiaryEntryBG from "./images/benficiary_enrty_bg.png";

import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Media,
  Col,
  Row,
  Card,
  Form,
  Label,
} from "reactstrap";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const apiUrl = apiService.apiUrl;

class BeneficiaryEntry extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.handleLogout = this.handleLogout.bind(this);

    this.state = {
      activeTab: "1",
      dropdownOpen: false,
      preview: false,
      loadingLetter: false,
      statusCode: "",
      subject: "",
      description: " ",
      uploadBtnSts: "false",
      imageOpen: false,
      imageLists: [],
      imgPathModal: 0,
      videoLists: [],
      letterLists: [],
      letterListTemp: [],
      recordLists: [],
      socialImageList: [],
      socialVideoList: [],
      isVisible: true,
      letterId: 0,
      userObj: JSON.parse(user),
      userType: localStorage.getItem("user_type"),
      primary_user: "",
      isFull: false,
      selectedLetterItem: "",
      allMemories: "",
      encryptedString: encrypt_url(),
      profile_image: ""
    };
  }
  // console it this.state

  componentDidMount() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
      console.log('primary user:::', this.state.userObj.data)
    } else {
      userId = this.state.userObj.data.user_id;
    }

    memoriesService.getImageData(userId, this);
    memoriesService.getVideoData(userId, this);
    memoriesService.getLetterData(userId, this);
    memoriesService.getRecordData(userId, this);
    this.setState({
      primary_user: this.state.userObj.data.primary_user,
    });

    let user = localStorage.getItem("user");
    if (user) {

      const profile_image = JSON.parse(user).data.profile_image
      if(profile_image && profile_image.substr(0,17)=="/static/media/dp."){
        this.setState({
          profile_image: profilePhoto,
        });
        
      }else if(profile_image){
        this.setState({
          profile_image: apiService.apiUrl +'/'+ profile_image,
        });
      }else{
        this.setState({
          profile_image: profilePhoto,
        });
      }

   

      // this.setState({
      //   profile_image: JSON.parse(user).data.profile_image
      //     ? apiService.apiUrl + "/" + JSON.parse(user).data.profile_image
      //     : profilePhoto,
      // });
    } else {
      this.props.history.push("/login");
    }
    
  }
  
  goFull = () => {
    this.setState({ isFull: true });
  };
  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleLogout = () => {
    if (this.state.dropdownOpen) {
      if (this.state.userType === "bn") {
        beneficiaryUserService.logoutBeneficiaryUser(this.props);
      } else {
        userService.logout(this.props);
      }
    }
  };

  toggleProfilePhoto = () => {
    this.setState((prevState) => ({
      profilePhoto: !prevState.profilePhoto,
    }));
  };
  showFirstImage = () => {
    console.log("imageLists", this.state.imageLists);
    let imgObject = [];

    let imgPath = apiUrl;
    if (this.state.imageLists.length !== 0) {
      imgObject = this.state.imageLists.slice(0, 1).map((item, i) => (
        <div key={i}>
          <Media
            object
            src={imgPath + "/" + item.filename}
            className="single-image"
            alt="Images"
          />
        </div>
      ));
    } else {
      imgObject = (
        <div className="container">
          <div className="row ">
            <img
              className="img-fluid mx-auto d-block"
              src={zeroState}
              alt="love-pat"
            />
          </div>
        </div>
      );
    }
    return imgObject;
  };

  showImageCarousalGallery = () => {
    let imageSlider = [];
    const responsive = {
      desktop: {
        breakpoint: { max: 2000, min: 1024 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    console.log("imageLists", this.state.imageLists);
    if (this.state.imageLists.length !== 0) {
      imageSlider = (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={false}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {this.showImageListAll()}
        </Carousel>
      );
    } else {
      imageSlider = (
        <div className="container">
          <div className="row ">
            <img
              className="img-fluid mx-auto d-block"
              src={zeroState}
              alt="love-pat"
            />
          </div>
        </div>
      );
    }

    return imageSlider;
  };

  showImageListAll = () => {
    let imgObject = [];
    let imgPath = apiUrl;
    console.log("imageLists", this.state.imageLists);

    imgObject = this.state.imageLists.map((item, i) => (
      <div key={i} className="img-divcontainer">
        <Media
          src={imgPath + "/" + item.filename}
          className="image-grid-multi"
          alt="images"
        />
        <div className="middle-icon">
          <button
            className="viewimage-eye"
            onClick={this.handleImagePopUp.bind(this, item)}
          >
            <FaEye />
          </button>
        </div>
      </div>
    ));

    return imgObject;
  };

  handleImagePopUp = (imgObj) => {
    this.setState({
      imageOpen: !this.state.imageOpen,
      imgPathModal: apiUrl + "/" + imgObj.filename,
    });
  };

  popImageView = () => {
    return (
      <div className="img-effect">
        <img src={this.state.imgPathModal} className="imageView" alt="" />
      </div>
    );
  };

  showVideoCarousalGallery = () => {
    console.log("videoLists", this.state.videoLists);
    let videoSlider = [];
    const responsive = {
      desktop: {
        breakpoint: { max: 2000, min: 1024 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    if (this.state.videoLists.length !== 0) {
      videoSlider = (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={false}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {this.showVideoLists()}
        </Carousel>
      );
    } else {
      videoSlider = (
        <div className="container">
          <div className="row ">
            <img
              className="img-fluid mx-auto d-block"
              src={zeroState}
              alt="love-pat"
            />
          </div>
        </div>
      );
    }
    return videoSlider;
  };
  showVideoLists = () => {
    //const videoPath = '/videos/';
    let videoPath = apiUrl;
    let videoObject = [];
    videoObject = this.state.videoLists.map((videoitem, i) => (
      <div className="video-container" key={i}>
        <div className="video-grid">
          <video
            width="250"
            height="250"
            controls
            className="video-bg"
            style={{ outline: "none" }}
          >
            <source src={videoPath + "/" + videoitem.filename} />
          </video>
        </div>
      </div>
    ));
    return videoObject;
  };

  showLetterCarousalGrid = () => {
    let letterSlider = [];
    const responsive = {
      desktop: {
        breakpoint: { max: 2000, min: 1024 },
        items: 4,
        slidesToSlide: 4, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 2, // optional, default to 1.
      },
    };
    if (this.state.letterLists.length !== 0) {
      // console.log("letterLists", this.state.letterLists);
      letterSlider = (
        <div>
          <h1>Letters</h1>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={false}
            autoPlay={this.props.deviceType !== "mobile" ? true : false}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {this.showLetterList()}
          </Carousel>
        </div>
      );
    } else {
      letterSlider = (
        <div className="container-fluid">
          <h1>Letters</h1>
          <div className="row ">
            <img
              className="img-fluid mx-auto d-block"
              src={zeroState}
              alt="love-pat"
            />
          </div>
        </div>
      );
    }
    return letterSlider;
  };

  showLetterList = () => {
    let listLtr = [];

    listLtr = this.state.letterLists.map((letterItem, i) => (
      <div className="letter-grid" key={i}>
        <React.Fragment>
          <Card
            className="letter-box"
          //onClick={this.letterViewActiveDeactive.bind(this, letterItem)}
          >
            <span
              id={"letterId-" + letterItem.id}
              className={letterItem.active === 1 ? "circle-gray" : "circle-red"}
            >
              <FaCircle />{" "}
              <i
                className="fa fa-external-link full-view"
                aria-hidden="true"
                onClick={this.letterViewActiveDeactive.bind(this, letterItem)}
              ></i>
            </span>
            <p className="titlesub">
              {letterItem.subTxt} {letterItem.subTxt.length > 30 ? "..." : ""}
            </p>
            <div
              className="paragraph"
            //dangerouslySetInnerHTML={{ __html: letterItem.desTxt }}
            >
              <p>
                {letterItem.desTxt
                  .replace(
                    /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
                    " "
                  )
                  .substr(0, 100)}
                {letterItem.desTxt.length > 75 ? "..." : ""}{" "}
              </p>
            </div>
          </Card>
        </React.Fragment>
      </div>
    ));

    return listLtr;
  };
  toggleLetterViewButton = () => {
    this.setState((prevState) => ({
      preview: !prevState.preview,
    }));
  };

  letterViewActiveDeactive = (selectedLetter) => {
    let itemId = "letterId-" + selectedLetter.id;
    document.getElementById(itemId).removeAttribute("class");
    document.getElementById(itemId).setAttribute("class", "circle-gray");

    if (selectedLetter.active === 0) {
      memoriesService.changeStatus(selectedLetter, this);
    }
    this.setState({
      isFull: true,
      selectedLetterItem: selectedLetter,
    });
    this.letterPopView(selectedLetter);
  };

  letterPopView = (selectedLetter) => {
    let subj = this.state.subject;
    let desc = this.state.description;
    subj = selectedLetter.subTxt;
    desc = selectedLetter.desTxt;
    this.setState({ subj });
    this.setState({ desc });
  };

  showLetterFullScreenMode = () => {
    return this.state.selectedLetterItem.desTxt
      .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ")
      .substr(0, 100);
  };

  showAudioCarousalGrid = () => {
    let audioSlider = [];
     
    if (this.state.recordLists.length !== 0) {
      audioSlider = (
        <div>
          <h1>Audio Notes</h1>
          
          {this.showRecordList()}
   
        </div>
      );
    } else {
      audioSlider = (
        <div className="container-fluid">
          <h1>Audio Notes</h1>
          <div className="row ">
            <img
              className="img-fluid mx-auto d-block"
              src={bnaudioEmpty}
              alt="love-pat"
            />
          </div>
        </div>
      );
    }
    return audioSlider;
  };
  showRecordList = () => {
    let recordPath = apiUrl;

    let listofRecord = [];
    listofRecord = this.state.recordLists.map((recorditem, i) => (
      <div key={i} className="col-md-3 audio-grid-list">
        <div className="text-div">
          <div className="head-title">{recorditem.title}</div>
          <span className="date">{recorditem.date}</span>
        </div>
        <div className="list-audio">
          <div className="audio-div">
            <audio className="player-custom" controls="controls">
              <source
                className="grid-audio"
                src={recordPath + "/" + recorditem.filename}
                type="audio/mp3"
              />
            </audio>
          </div>
        </div>
      </div>
    ));

    return listofRecord;
  };

  downloadAudio = () => {
    console.log("download file with new name:");
    document.getElementById("dwn_audio").download = "MyAudio";
  };
  /*****************************************   Memories Page Image Option Start   *****************************/

  disableBeneficiaryLink = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Link className="link-style-beneficiaries" to="/beneficiaries">
          Beneficiaries
        </Link>
      );
    }
  };

  /*****************************    Memories Page Record Option End  ******************************/

  primaryUserFirstName = (e) => {
    if (this.state.primary_user) {
      let fname = this.state.primary_user.name.split(" ");
      return fname[0] ? fname[0] : "Primary user ";
    } else {
      return "Primary user ";
    }
  };

  render() {
    console.log("this.state", this.state.primary_user.name);
    return (
      <div className="bnhome-container" style={{ height: "100vh" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Beneficiary Landing</title>
        </Helmet>
        <Header {...this.props} />
  
 
        <div style={{ maxWidth: "28rem", margin: "auto", position: "relative", marginTop: "30px" }}>

          <div className="d-flex justify-content-center" style={{ position: "absolute", width: "100%", top: 0, transform: "translateY(-50%)", zIndex: "999" }}>
            <img className="profile-photo" style={{ borderRadius: "0%", marginTop: "3px", width: "30px" }} src={loveIcon} alt="This Heart" />
          </div>
          <div className="card" style={{
            backgroundImage: `url(${beneficiaryEntryBG})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            padding: "11px",
            border: "none",
            boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",        
            borderRadius: "10px"
          }}>
            <h6 className="text-center mt-3">In Memory of <span style={{ color: "#f6db20" }}>{this.state.primary_user.name}</span></h6>
            <img className="card-img-top" style={{ maxWidth: "250px", height: "285px", borderRadius: "10px", margin: "0 auto" }} src={this.state.primary_user.image_list && this.state.primary_user.image_list.length>0 ? apiUrl+'/'+this.state.primary_user.image_list[0].image_url:this.state.profile_image} alt="Card image cap" />
            <div className="card-body ml-3 pt-1">
              <p className="card-text text-dark" style={{fontSize:"13px"}}>Memories are always special.<br/>Sometimes we laugh by remembering the days we cried.<br/>And we cry by remembering the days we laughed !!!<br/><span style={{color:"#f6db20"}}>That's life</span></p>
            </div>
          </div>
          <div className="d-flex justify-content-center" style={{ position: "absolute", width: "100%", bottom: 0, transform: "translateY(50%)" }}>
            <a className="btn px-4 text-white" style={{borderRadius:"7px", background:"#f6db20"}} href={`/beneficiarylanding/${this.state.encryptedString}`}>Enter</a>
          </div>
        </div>


      </div>
    );
  }
}

export default BeneficiaryEntry;
