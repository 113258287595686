import React, { Component } from "react";
import lineOfColours from "./images/line_of_colours.png";
import {
  Media,
  Col,
  Row,
  Form,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
// import { Link } from "react-router-dom";
// import logoImage from "./images/logo.png";
import loaderImg from "./images/loader.gif";
import OTPAuthService from "./../services/otpauth.service";
import "./styles/enable2fa.css";

import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import { encrypt_url } from "../helpers/encrypt-url";
import LeftSidebar from "../components/LeftSidebar";

class ResetTwoFactorAuthAskPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pass_word: "",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      statusCode: "",
      encryptedString: encrypt_url(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    let { value } = e.target;
    this.setState({ pass_word: value });
    this.setState({ submitted: false });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    let { pass_word } = this.state;
    if (pass_word) {
      this.setState({ loading: true });
      OTPAuthService.checkPasswordOTP(pass_word, this).then(
        (response) => {
          let responseData = response.data;
          if (responseData.status === "success") {
            this.props.history.push("/resettwofa");
          } else {
            this.setState({
              loading: false,
              errorMessage: "Sorry, your password is not matched",
              submitted: false,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  twoFactorProcess = () => {
    let optionSelect = "";
    optionSelect = (
      <Col sm={{ offset: 3, size: 6 }}>
        <FormGroup>
          <Label>
            <b>Enter your password</b>
          </Label>
          <Input
            type="password"
            name="password"
            className="pass-input-set"
            placeholder="Password"
            id="Password"
            onChange={this.handleChange}
            required="required"
          />
        </FormGroup>
      </Col>
    );
    return optionSelect;
  };

  handleSkipButton = () => {
    const user_type = localStorage.getItem("user_type");
    if (user_type === "bn") {
      this.props.history.push(
        `/beneficiarylanding/${this.state.encryptedString}`
      );
    } else if (user_type === "primary") {
      this.props.history.push(`/dashboard/${this.state.encryptedString}`);
    }
  };

  render() {
    return (
      <div id="main-wrapper" className="" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-header-position="fixed" data-boxed-layout="full" >
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Reset TwoFA Password</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="container mt-3">
            <div className="enable-2fa-card">
              <div className="mr-3 text-break" style={{ width: "100%" }}>
                <h3 className="mr-2 w-100 text-break" style={{ width: "100%" }}>Enable Two Factor Authentication</h3>
                {/* <img style={{height:"5px", margin:"auto 0", paddingLeft:"10px"}} src={lineOfColours} alt="line of colours" /> */}
                {/* <img src={lineOfColours} alt="line of colours" /> */}
              </div>
              <p>Two-factor authentication (2FA): Secure Your ThisHeart Account Because
                stronger passwords alone aren't enough to protect your account from password
                breaches. Two-factor authentication protects against phishing, social engineering,
                and password brute force. attacks and secures your account from attackers exploiting
                weak or stolen credentials.</p>
              <p>To <span style={{ fontWeight: "500", color: "rgb(184 27 189)" }}>Enable Two-Factor Authentication</span> on your account, login through any one of the following:</p>
              {/* More instructions or content as per your design */}
              <Form onSubmit={this.handleSubmit}>
                <FormGroup id="2faInput">
                  <label htmlFor="Password">Enter your password</label>
                  <Input
                    type="password"
                    id="Password"
                    className="password-input w-50 ml-0"
                    placeholder="Password"
                    required
                    onChange={this.handleChange}
                  />

                </FormGroup>
                <div className="help-block-login ml-0">{this.state.errorMessage}</div>
                <div>
                  {this.state.loading && (
                    <img className="loader-img" alt="loaderImg" src={loaderImg} />
                  )}
                </div>
                <div>

                  <Button type="submit"  className="btn" style={{background:"#ffd139", border:"0px", borderRadius: "5px" }}>Change 2FA Settings</Button>

                  <Button className="btn-skip" onClick={this.handleSkipButton}>Skip</Button>

                </div>
              </Form>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default ResetTwoFactorAuthAskPassword;
