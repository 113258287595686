import React, { Component } from "react";
import { Media, Col, Row, Button } from "reactstrap";

import { Link } from "react-router-dom";
import "./styles/login.css";
import { userService } from "../services/user.service";
import logoImage from "./images/logo-img.png";
import emailConfirmation from "./images/email-confirmation.png";
import { FaCheck, FaDoorOpen } from "react-icons/fa";

class RegisterEmailConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      submitted: false,
      loading: false,
      requestFailed: false,
      serverMessage: "",
      errorMessage: "",
      statusCode: "",
      emailVerified: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      requestFailed: false,
      statusCode: "",
      serverMessage: "",
    });

    const { email } = this.state;
    if (email) {
      this.setState({ loading: true });
      userService.resetPasswordRequest(email, this);
    }
  }

  componentDidMount() {
    let data = {
      url_token: this.props.match.params.token,
      email: this.props.match.params.email,
    };
    userService.verifyEmail(data, this).then(
      (response) => {
        if (response.data.status === "success") {
          this.setState({
            emailVerified: true,
          });
        } else if (response.data.status === "error") {
          this.setState({
            emailVerified: false,
            errorMessage: response.data.message,
          });
        }
      },
      (error) => {
        console.log(error);
        if (error.response.data.status === "error") {
          this.setState({
            emailVerified: false,
            errorMessage: error.response.data.message,
          });
        }
      }
    );
  }
  emailValidation() {
    if (this.state.submitted && !this.state.email) {
      return <div className="help-block-login">Email is required</div>;
    } else if (
      this.state.submitted &&
      this.state.requestFailed &&
      this.state.statusCode === 404
    ) {
      return <div className="help-block-login">{this.state.serverMessage}</div>;
    }
  }

  renderBody() {
    if (this.state.submitted && this.state.statusCode === 200) {
      return (
        <Row>
          <Col sm="12">
            <Media left className="logo-image-forgot-pass">
              <Media object src={logoImage} alt="image" />
            </Media>
            <div className="send-password-link text-muted text-center">
              <strong>{this.state.serverMessage}</strong>
            </div>
            <Link
              className="sign-in-from-here text-muted text-center"
              to="/login"
            >
              {"<< "} Back to sign-in page
            </Link>
          </Col>
        </Row>
      );
    }

    return (
      <Row className="mt-5">
        <Col sm="12" className="text-center mt-4">
          <Media left className="logo-image">
            <Media object src={emailConfirmation} alt="image" />
          </Media>
          {/* {this.state.emailVerified && (
            <h3 className="mt-3 text-warning">
              <FaCheck />
            </h3>
          )} */}
          {/* {!this.state.emailVerified && (
            <h3 className="mt-3 text-warning">
              <FaDoorOpen />
            </h3>
          )} */}
          {/* {this.state.emailVerified && (
            <h3 className="mt-3 text-success">
              Your email has been confirmed.
            </h3>
          )} */}
          {this.state.emailVerified && (
            <h3 className="mt-3 confirm-message">
              Your email has been confirmed.
            </h3>
          )}
          {!this.state.emailVerified && (
            <h3 className="mt-3 text-danger">{this.state.errorMessage}</h3>
          )}
          <p>
            {" "}
            <Link to="/login">
              <Button className="confirm-login">LOG IN</Button>
            </Link>
          </p>
        </Col>
      </Row>
    );
  }

  render() {
    return <div className="login-container">{this.renderBody()}</div>;
  }
}

export default RegisterEmailConfirmation;
