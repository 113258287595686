import React, { Component } from "react";
import { Link } from "react-router-dom";
import loaderImg from "./images/loader.gif";
import { Form, Button, FormGroup, Input, Label } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/onboarding1.css";
import "./styles/enable2fa.css";
import "react-responsive-ui/style.css";
import PhoneInput from "react-phone-number-input";
import OTPAuthService from "./../services/otpauth.service";
import { Helmet } from "react-helmet";
import { encrypt_url } from "../helpers/encrypt-url";

class OnBoardingStepOne extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.state = {
      mobile: "",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      errorMobile: "",
      statusCode: "",
      userType: localStorage.getItem("user_type"),
      userObj: JSON.parse(user),
      selectedStep: "email",
      encryptedString: encrypt_url(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEnableClick = this.handleEnableClick.bind(this);
    this.handleSkipClick = this.handleSkipClick.bind(this);
  }

  componentDidMount() {
    let user = localStorage.getItem("user");
    if (!user) {
      this.props.history.push("/login");
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChange = (e) => {
    let { value } = e.target;
    this.setState({ selectedStep: value });
    this.setState({ submitted: false });
  };

  handleEnableClick() {
    this.handleSubmit();
  }

  handleMobile(e) {
    let { value } = e.target;
    this.setState({ mobile: value });
    this.setState({ submitted: false });
  }

  handleSubmit(e) {
    e && e.preventDefault(); // Allow form submission or direct call

    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
      errorMobile: "",
      encryptedString: encrypt_url(),
    });

    let { selectedStep, mobile } = this.state;

    if (selectedStep === "sms" && mobile === "") {
      this.setState({ errorMobile: "Please input mobile number!" });
      return false;
    }

    if (selectedStep) {
      this.setState({ loading: true });
      OTPAuthService.generateOTP(selectedStep, mobile, this).then(
        (response) => {
 
          let responseData = response.data;
          if (responseData.status === "success") {
            if (
              responseData.method === "sms" ||
              responseData.method === "email"
            ) {
              this.props.history.push("/onboard2faverifysmsemail");
            } else if (responseData.method === "googleauth") {
              this.props.history.push({
                pathname: "/onboard2faverifygoogle",
                state: { qr_url: responseData.data },
              });
            }
          } else {
            this.setState({
              errorMessage: response.data.message,
              loading: false,
            });
          }
        },
        (error) => {
          let errorMsgTmp = "";
          console.log("Error: ", error.response.data.message);
          let errorMsg = error.response.data.message.split(":");

          if (
            errorMsg.length > 1 &&
            errorMsg[1].substr(0, 16) === " The 'To' number"
          ) {
            errorMsgTmp = "Mobile number is invalid!";
          } else if (
            typeof errorMsg[2] != "undefined" &&
            errorMsg[2].substr(0, 21) === " 1062 Duplicate entry"
          ) {
            errorMsgTmp = "Mobile number is exists already!";
          } else {
            errorMsgTmp = error.response.data.message;
          }
          this.setState({
            errorMessage: errorMsgTmp,
            loading: false,
          });
        }
      );
    }
  }

  handleSkipClick() {
    localStorage.setItem("twofa", true);
    this.props.history.push(`/dashboard/${this.state.encryptedString}`);
  }

  phoneNumberValidation = () => {
    if (this.state.submitted && !this.state.mobile) {
      return <div className="help-block-login">Phone number is required</div>;
    } else if (this.state.submitted === true && this.state.regFailed === true) {
      return <div className="help-block-login"> {this.state.errorMessage}</div>;
    }
  };

  twoFactorProcess = () => {
    let optionSelect = "";
    optionSelect = (
      <div>
        <div className="option-list">
          <FormGroup check>
            <Label check className="lbl-method ">
              <b>Email</b>
              <Input
                type="radio"
                name="radio"
                value="email"
                id="email"
                onChange={this.handleChange}
                defaultChecked
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check className="lbl-method ">
              <b>SMS</b>
              <Input
                type="radio"
                name="radio"
                value="sms"
                id="sms"
                onChange={this.handleChange}
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check className="lbl-method ">
              <b>Google Authenticator</b>
              <Input
                type="radio"
                name="radio"
                value="googleauth"
                id="googleauth"
                onChange={this.handleChange}
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>
          <hr />
          {this.state.selectedStep === "sms" && (
            <FormGroup style={{marginTop:'-15px'}}>
              <Label style={{ color: "#212529" }}>
                <b>Mobile</b>
              </Label>

              <PhoneInput
                name="mobile"
                id="mobile"
                placeholder="Enter mobile number"
                className="col-9"
                onChange={(mobile) => {
                  this.setState({ mobile });
                  this.setState({ errorMobile: "", errorMessage: "" });
                }}
              />
              <div
                className="help-block"
                style={{ width: "100%", marginTop: "-2px" }}
              >
                {this.state.errorMobile}
              </div>
            </FormGroup>
          )}
        </div>
      </div>
    );
    return optionSelect;
  };

  render() {
    return (
      <div className="container-fluid onboarding-one">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - OnBoarding Two FA</title>
        </Helmet>

        <div className="container image-step">
            <div className="row mt-5 pt-5">
              <div className="col-sm-12 pl-3 col-md-6">
                <h4 className="pt-3">
                  <b>
                    TWO FACTOR <br />
                    AUTHENTICATION
                  </b>
                </h4>
                <p>
                  Two Factor Authentication is a security measure that adds an
                  additional layer of security to your account. We take security
                  very seriously. We’re kinda obsessed with it. We highly
                  recommend enabling 2 Factor Authentication. It’s easy and
                  totally worth it.
                </p>
              </div>
            </div>


            <div className="row">
              <div className="phone-block col-md-6">
                <Form onSubmit={this.handleSubmit} method="post" action="">
                  {this.twoFactorProcess()}
                  {this.state.loading && (
                    <img
                      className="loader-img"
                      alt="loaderImg"
                      src={loaderImg}
                    />
                  )}

                  {this.state.errorMessage && (
                    <div className="alert alert-warning mt-5">
                      {this.state.errorMessage}
                    </div>
                  )}
                </Form>
              </div>
            </div>

            <div className="row onboarding-btn justify-content-start align-items-center">
                <Button className="btn bg-thisheart btn-lg btnEnabled" onClick={this.handleEnableClick}>Enable</Button>     
                <Button className="btn btn-secondary btn-lg btnSkiped mx-3" onClick={this.handleSkipClick}>Skip</Button>
            </div>    
                  
            <div className="row">
              <div className="col-md-1 step-one-skip">
                <p className="skip-p">
                  <span>
                    {/* <Link to={`/dashboard/${this.state.encryptedString}`}>
                      {localStorage.setItem("twofa", true)}
                      Skip
                    </Link> */}
                  </span>
                </p>
              </div>
            </div>
          </div>
         
      </div>
    );
  }
}

export default OnBoardingStepOne;
