import { authHeader } from '../helpers/auth-header';
import axios from 'axios';
import apiService from './api.service';
import OTPAuthService from "./otpauth.service";
import { ToastsStore } from "react-toasts";
import Cryptr from "cryptr";
import { encrypt_url } from '../helpers/encrypt-url';
import Swal from 'sweetalert2';

const apiUrl = apiService.apiUrl;

export const beneficiaryUserService = {
	validateAccessCode,
	validateContributorsAccessCode,
	registerBeneficiaryUser,
	registerContributorsUser,
	loginBeneficiaryUser,
	logoutBeneficiaryUser
};

function validateAccessCode(accessCode, urlCode, self) {
	let header = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: authHeader()
		}
	};

	let data = JSON.stringify({
		access_code: accessCode,
		url_code: urlCode
	});

	let responseMsg = '';
	let codeValidationStatus = false;

	axios
		.post(`${apiUrl}/api/beneficiary/validateCode`, data, header)
		.then((response) => {
			responseMsg = response.data.message;
			self.setState({ messages: responseMsg });

			localStorage.setItem('primaryUserId', response.data.data.user_id);
			localStorage.setItem('beneficiaryId', response.data.data.id);
			localStorage.setItem('beneficiaryData', JSON.stringify(response.data.data));

			let validatedAccessCode = response.data.validated;

			if (validatedAccessCode === 1) {
				let params = {
					user_id: response.data.data.user_id,
					beneficiary_id: response.data.data.id,
					email: response.data.data.email,
					first_name: response.data.data.first_name,
					last_name: response.data.data.last_name,
					last_4_beneficiary: response.data.data.last_4_beneficiary
				}
				self.props.history.push('/beneficiary/register', params);
			}
			codeValidationStatus = true;
		})
		.catch(function (error) {
			// handle error
			responseMsg = error.response.data.message;
			self.setState({ messages: responseMsg });
		});

	return codeValidationStatus;
}

function validateContributorsAccessCode(accessCode, urlCode, self) {
	let header = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: authHeader()
		}
	};

	let data = JSON.stringify({
		access_code: accessCode,
		url_code: urlCode
	});

	let responseMsg = '';
	let codeValidationStatus = false;

	axios
		.post(`${apiUrl}/api/contributors/validate-access-code`, data, header)
		.then((response) => {
			responseMsg = response.data.message;
			self.setState({ messages: responseMsg });

			localStorage.setItem('primaryUserId', response.data.data.user_id);
			localStorage.setItem('beneficiaryId', response.data.data.id);
			localStorage.setItem('beneficiaryData', JSON.stringify(response.data.data));

			let validatedAccessCode = response.data.validated;

			if (validatedAccessCode === 1) {
				let params = {
					user_id: response.data.data.user_id,
					beneficiary_id: response.data.data.id,
					email: response.data.data.email,
					first_name: response.data.data.first_name,
					last_name: response.data.data.last_name,
					last_4_beneficiary: response.data.data.last_4_beneficiary
				}
				//console.log('we are here....')
				self.props.history.push('/contributor-user/registration', params);
			}
			codeValidationStatus = true;
		})
		.catch(function (error) {
			// handle error
			responseMsg = error.response.data.message;
			self.setState({ messages: responseMsg });
		});

	return codeValidationStatus;
}

function registerBeneficiaryUser(email, password, last4social_code, userName, mobile, self) {
	let header = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: authHeader()
		}
	};
	const state = self.props.location.state

	let data = JSON.stringify({
		email: email,
		password: password,
		name: userName,
		mobile: mobile,
		user_id: state.user_id,
		beneficiary_id: state.beneficiary_id,
		last4social_code: last4social_code
	});

	let responseMsg = '';
	return axios
		.post(`${apiUrl}/api/beneficiary/register`, data, header)
		.then((response) => {
			responseMsg = response.data.message;

			const cryptrs = new Cryptr('thisheart');
			localStorage.setItem('user', JSON.stringify(response.data))
			localStorage.setItem('reg_email', email)
			localStorage.removeItem('twofa')
			localStorage.setItem('reg_process', 'reg_process')
			self.props.history.push('/RegisterEmailConfirmation/' + cryptrs.encrypt(email))
		})
		.catch(function (error) {
			// handle error
			responseMsg = error.response.data;
			let errMsg = responseMsg.message.split(':')
			if (errMsg[0] === "[HTTP 400] Unable to create record") {
				self.setState({
					errorMessage: { mobile: errMsg[1] }
				});
			}
			console.log(errMsg[1])
			if (responseMsg.code === "invalid") {
				self.setState({
					errorMessage: responseMsg.data
				});
			} else if (responseMsg.code === "email") {
				self.setState({
					errorMessage: { email: responseMsg.message }
				});
			} else if (responseMsg.code === "social") {
				self.setState({
					errorMessage: { social: responseMsg.message }
				});
			} else if (responseMsg.code === "mobile") {
				self.setState({
					errorMessage: { mobile: responseMsg.message }
				});
			}

			self.setState({
				loading: false,
			});
			ToastsStore.error("Beneficiary user not registered")
		});
}

function registerContributorsUser(email, password, last4social_code, userName, mobile, self) {
	let header = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: authHeader()
		}
	};
	const state = self.state;

	let data = JSON.stringify({
		email: email,
		password: password,
		name: userName,
		mobile: mobile,
		user_id: state.primaryUserId,
		contributor_id: state.contributor_id,
		beneficiary_id: '0',
		last4social_code: last4social_code
	});

	let responseMsg = '';
	return axios
		.post(`${apiUrl}/api/contributors/register`, data, header)
		.then((response) => {
			if (response.data.status === 'success') {
				responseMsg = response.data.message;

				const cryptrs = new Cryptr('thisheart');
				localStorage.setItem('user', JSON.stringify(response.data))
				localStorage.setItem('reg_email', email)
				localStorage.removeItem('twofa')
				localStorage.setItem('reg_process', 'reg_process')
				self.props.history.push('/RegisterEmailConfirmation/' + encrypt_url(email))
			} else {
				throw new Error('Backend returned an error: ' + response.data.message);
			}
		})
		.catch(function (error) {
			responseMsg = error.response.data.message;
			self.setState({
				loading: false,
			});
			
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseMsg,
            });
		});
}

function loginBeneficiaryUser(email, password, self) {
	let data = JSON.stringify({
		email: email,
		password: password
	});
	let responseMsg = '';
	let header = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: authHeader()
		}
	};
	return axios
		.post(`${apiUrl}/api/beneficiary/login`, data, header)
		.then((response) => {
			responseMsg = response.data.message;
			console.log('Error Message in Log In:', responseMsg);
			localStorage.setItem('user', JSON.stringify(response));

			localStorage.setItem('twofa', true)
			localStorage.setItem('user_type', 'bn');
			self.setState({
				errorMessage: responseMsg,
				regFailed: false,
				loading: false,
				statusCode: response.status
			});

			OTPAuthService.isExistsOTP().then(status => {
				if (status.data.status === "success") {
					if (status.data.data === "enable" && (status.data.method === "sms" || status.data.method === "email")) {
						self.props.history.push('/verifytwofa');
					} else if (status.data.data === "enable" && status.data.method === "googleauth") {
						self.props.history.push({
							pathname: '/verifyqrcode',
							state: { qr_url: status.data.qr_url }
						});
					} else if (status.data.data === "disable") {
						self.props.history.push('/enrolltwofa');
					} else if (status.data.data === "not-found") {
						self.props.history.push('/enrolltwofa');
					}
				}

			}, error => {
				let errMsg = "";
				if (error.exception === "Twilio\Exceptions\RestException") {
					errMsg = error.message;
				}
				self.setState({
					loginFailed: true,
					errorMessage: errMsg,
					loading: false,

				});
				console.log('Login Error: ', error.message);
			});
		})
		.catch(function (error) {
			// handle error
			responseMsg = error.response.data.message;
			if (error.response) {
				self.setState({
					loginFailed: true,
					errorMessage: responseMsg,
					loading: false,
					statusCode: error.response.status
				});
			}
		});
}

function logoutBeneficiaryUser(property) {
	localStorage.removeItem('user');
	localStorage.removeItem('primaryUserId');
	localStorage.removeItem('beneficiaryId');
	localStorage.removeItem('user_type');
	property.history.push('/beneficiary/login');
}
