import { authHeader } from "../helpers/auth-header";
import axios from "axios";
import apiService from "./api.service";
import { EditorState } from "draft-js";
import { ToastsStore } from "react-toasts";

const apiUrl = apiService.apiUrl;

export const memoriesService = {
  storeImageData,
  getImageData,
  deleteImageData,
  storeVideoData,
  getVideoData,
  deleteVideoData,
  storeLetterData,
  deleteLetterData,
  getLetterData,
  updateLetterData,
  storeRecordData,
  getRecordData,
  getAllMemoriesData,
  deleteRecordData,
  storeProfileImageData,
  changeStatus,
  deleteSocialImageData,
  searchAll,
  searchByTextDesktop,
  getContentDashboard,
};

function storeImageData(userId, title, description, imageList, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  const formData = new FormData();

  for (const key of Object.keys(imageList)) {
    formData.append(
      "imagesFiles[" + key + "]",
      self.state.selectedImageMultiple[key]
    );
  }

  formData.append({'title': title,'description':description})
  
  let newId = 0;

  axios
    .post(`${apiUrl}/api/image/upload`, formData, header)
    .then((response) => {
      let imageDataInfo = response.data.data;
 
      let prevImageList = self.state.imageLists;
      let newImageList = prevImageList.concat(imageDataInfo);
      self.setState({
        imageLists: newImageList,
      });
      self.setState({ uploadingData: true });
      self.setState({ modalphoto: false });
     
      // setTimeout(function(){
      //   //window.location.reload()
      // },2500)
      
    })
    .catch(function(error) {
      self.setState({ uploadingData: false });
      ToastsStore.error("Sorry, image isn't uploaded! ");
    });

  return newId;
}

function storeProfileImageData(imageFile, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  const formData = new FormData();
  formData.append("profile_image", imageFile);

  axios
    .post(`${apiUrl}/api/image/profileUpload`, formData, header)
    .then((response) => {
      console.log(response.data);
      if (response.data.status === "success") {
        self.setState({
          profile_image: apiService.apiUrl + "/" + response.data.data.image_url,
        });
        let userTmp = JSON.parse(localStorage.getItem("user"));
        userTmp.data.profile_image = response.data.data.image_url;
        localStorage.setItem("user", JSON.stringify(userTmp));
        userTmp = JSON.parse(localStorage.getItem("user"));
      }
      self.toggleProfilePhoto();
      console.log(self.state.profile_image);
      ToastsStore.success("Profile Image Uploaded successfull");
    })
    .catch(function(error) {
      // handle error
      console.log(error);
      self.setState({ uploadingData: false });
    });

  return false;
}

function getImageData(userId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  self.setState({ loadingImages: true });
  var imageData = [];

  axios
    .get(`${apiUrl}/api/image/getAll`, header)
    .then((response) => {
      response.data.map((item) =>
        imageData.push({
          id: item.id,
          user_id: item.user_id,
          primary_user_id: item.primary_user_id,
          filename: item.filename,
          title: item.title,
          description: item.description,
          date: item.updated_at.split(" ")[0],
        })
      );
 
      self.setState({ imageLists: imageData });
      self.setState({ loadingImages: false });
    })
    .catch(function(error) {
      // handle error
      self.setState({ loadingImages: false });
    });
}

function deleteImageData(selectedItemId) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    id: selectedItemId,
  });

  //console.log("selectedItemId " + selectedItemId);

  axios
    .post(`${apiUrl}/api/image/delete/${selectedItemId}`, data, header)
    .then((response) => {
      console.log(response.data);
      ToastsStore.success("Image has been deleted successfully!");
      return true;
    })
    .catch(function(error) {
      // handle error
      return false;
    });

  return true;
}

function deleteSocialImageData(self, selectedItemId) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  if (!selectedItemId) {
    return false;
  }
  let data = JSON.stringify({
    id: selectedItemId,
  });

  axios
    .post(`${apiUrl}/api/social_image/delete/${selectedItemId}`, data, header)
    .then((response) => {
      if (response.data.status === "success") {
        return true;
      } else {
        return false;
      }
    })
    .catch(function(error) {
      return false;
    });

  return true;
}

function storeVideoData(
  userId,
  urlTypecheck,
  urlLink,
  urlTitle,
  videoFiles,
  videoList,
  self
) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      //'Content-Type': 'multipart/form-data',
      Authorization: authHeader(),
    },
  };
  if (urlTypecheck === "videoLink") {
    let data = JSON.stringify({
      urlLink: urlLink,
      videoType: urlTypecheck,
      urlTitle: urlTitle,
    });

    let newId = 0;
    axios
      .post(`${apiUrl}/api/video/upload`, data, header)
      .then((response) => {
        let videoDataInfo = response.data.data;
        
        const oldVideoLists = videoList;
        const newVideoLists = oldVideoLists.concat(videoDataInfo);

        console.log("response videoDataInfo: ", videoDataInfo);
        console.log("oldVideoLists: ", videoList);
        console.log("newVideoLists: ", newVideoLists);

        self.setState({
          videoLists: newVideoLists,
        });
        self.setState({ uploadingData: true });
        self.setState({ modalvideo: false, modalUrlVideo: false });
        self.setState({ urlLinkVideo: false });
        ToastsStore.success("Url upload is success!");

      })
      .catch(function(error) {
        console.log('error video link:: 255', error.respose);
        self.setState({ uploadingVideo: false });
        self.setState({ modalvideo: false, modalUrlVideo: false });
        self.setState({ urlLinkVideo: false });

        ToastsStore.error("Sorry, Problem on uploading Url!, ");
      });
    return newId;
  } else {
    const formDatas = new FormData();
    formDatas.append("videoType", "videoFile");
    for (const key of Object.keys(videoFiles)) {
      formDatas.append("videos[" + key + "]", videoFiles[key]);
    }
    let newId = 0;
    console.log('formDatas:::',formDatas);

    axios
      .post(`${apiUrl}/api/video/upload`, formDatas, header)
      .then((response) => {
        let videoDataInfo = response.data.data;
        console.log("response: ", videoDataInfo);
        let prevVideoLists = self.state.videoLists;
        let newVideoLists = prevVideoLists.concat(videoDataInfo);

        console.log("videoDataInfo: ", videoDataInfo);
        console.log("prevVideoLists: ", prevVideoLists);
        console.log("newVideoLists: ", newVideoLists);

        self.setState({
          videoLists: newVideoLists,
        });
        self.setState({ uploadingData: true });
        self.setState({ modalvideo: false });
        ToastsStore.success("Video upload is success!");
        setTimeout(function(){
          //window.location.reload();
        }, 1000)
        
        console.log("response: ", self.state.videoLists);
      })
      .catch(function(error) {
        console.log(error);
        self.setState({ uploadingVideo: false });
        self.setState({ modalvideo: false });
        ToastsStore.error("Sorry, Problem on uploading video!, ");
      });
    return newId;
  }
}

function getVideoData(userId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  self.setState({ loadingVideos: true });
  var videoData = [];
  var videoLinkData = [];

  //console.log(header);
  axios
    .get(`${apiUrl}/api/video/getAll`, header)
    .then((response) => {
      // console.log(response.data);

      response.data.map((videoitem) => {
        if (videoitem.urlcheck === "videoFile") {
          videoData.push({
            id: videoitem.id,
            user_id: videoitem.user_id,
            primary_user_id: videoitem.primary_user_id,
            filename: videoitem.filename,
            thumbnail_url: videoitem.thumbnail_url,
            date: videoitem.updated_at.split(" ")[0],
          });
          self.setState({ videoLists: videoData });
        } else if (videoitem.urlcheck === "videoLink") {
          videoLinkData.push({
            id: videoitem.id,
            filename: videoitem.filename,
            date: videoitem.updated_at.split(" ")[0],
          });
          self.setState({ linkVideoList: videoLinkData });
        }
      });

      self.setState({ loadingVideos: false });
    })
    .catch(function(error) {
      // handle error
      self.setState({ loadingVideos: false });
    });

  //console.log("dataForPerson " + dataForPerson);
}

function deleteVideoData(selectedItemId) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    id: selectedItemId,
  });

  console.log("selectedItemId........................: " + selectedItemId);

  axios
    .post(`${apiUrl}/api/video/delete/${selectedItemId}`, data, header)
    .then((response) => {
      return true;
    })
    .catch(function(error) {
      // handle error
      ToastsStore.error("Sorry, Problem on deleting video!");
      return false;
    });

  return true;
}

function storeLetterData(userId, sub, desc, listLetter, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    user_id: userId,
    subject: sub,
    description: desc,
    letter_from: "admin@thisheart.com",
    active: 0,
  });

  let newId = 0;
  let responseMsg = "";

  axios
    .post(`${apiUrl}/api/letter/store`, data, header)
    .then((response) => {
      newId = response.data.data.id;
      responseMsg = response.data.message;
      listLetter.push({ id: newId, subTxt: sub, desTxt: desc, active: 0 });

      self.setState({
        subject: "",
        description: "",
        editorState: EditorState.createEmpty(),
        errorDescription: "",
        errorSubject: "",
        loadingLetters: false,
        errorMessage: "",
        errorDescriptionEdit: "",
      });
      ToastsStore.success("Letter added successfully!");
    })
    .catch(function(error) {
      // handle error
      responseMsg =
        error.response && error.response.data
          ? error.response.data.message
          : "";
      self.setState({
        errorMessage: responseMsg,
        loading: false,
        loadingLetters: false,
        statusCode: error && error.response ? error.response.status : "",
      });
      if (
        error.response.data &&
        error.response.data.code === "exceeds-letters"
      ) {
        ToastsStore.error(responseMsg);
      } else {
        ToastsStore.error("Sorry, letter not added!");
      }
    });

  return newId;
}

function getLetterData(userId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  var letterData = [];
  axios
    .get(`${apiUrl}/api/letter/getById`, header)
    .then((response) => {
      response.data.map((item) =>
        letterData.push({
          id: item.id,
          user_id: item.user_id,
          primary_user_id: item.primary_user_id,
          subTxt: item.subject,
          desTxt: item.description,
          active: item.active,
        })
      );

      self.setState({ letterLists: letterData });
      self.setState({ letterListTemp: letterData });
      self.setState({ loadingLetter: true });
    })
    .catch(function(error) {
      // handle error
    });
}

function changeStatus(letter_id, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  var letterData = [];
  let letterStatus = {
    letter_id: letter_id,
  };

  axios
    .post(`${apiUrl}/api/letter/changeStatus`, letterStatus, header)
    .then((response) => {
      console.log("letter status api response ::", response);
      response.data.map((item) =>
        letterData.push({
          active: item.letterStatus,
        })
      );
      self.setState({ active: letterData });
    })
    .catch(function(error) {
      // handle error
    });
}

function updateLetterData(selectedItemId, sub, desc, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    subject: sub,
    description: desc,
  });

  let responseMsg = "";
  axios
    .post(`${apiUrl}/api/letter/updateBy/${selectedItemId}`, data, header)
    .then((response) => {
      ToastsStore.success("Letter has been updated successfully!");
      responseMsg = response.data.message;
      self.setState({
        errorMessage: responseMsg,
        loading: false,
        statusCode: response.status,
        errorSubjectEdit: "",
        errorDescriptionEdit: "",
      });
    })
    .catch(function(error) {
      return false;
    });
  return true;
}

function deleteLetterData(selectedItemId) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    id: selectedItemId,
  });

  axios
    .post(`${apiUrl}/api/letter/deleteBy/${selectedItemId}`, data, header)
    .then((response) => {
      ToastsStore.success("Letter has been deleted successfully!");
      return true;
    })
    .catch(function(error) {
      // handle error
      return false;
    });

  return true;
}

function storeRecordData(
  userId,
  urlTypecheck,
  title,
  audUrlTitle,
  recordFile,
  recordList,
  self
) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };
  if (urlTypecheck === "audioLink") {
    let data = JSON.stringify({
      urlLink: title,
      audioType: urlTypecheck,
      audUrlTitle: audUrlTitle,
    });

    let newId = 0;
    console.log("url checked:::::", data);
    axios
      .post(`${apiUrl}/api/record/upload`, data, header)
      .then((response) => {
        console.log(response);
        if (response.data && response.data.data) {
          let audioDataInfo = response.data.data;
          let prevRecordLists = self.state.recordLists;
          let newRecordLists = prevRecordLists.concat(audioDataInfo);

          self.setState({ recordLists: newRecordLists });
        }

        self.setState({ modalrecord: false });
        self.setState({ uploadingData: true, modalUrlAudio: false });
        self.setState({ urlLinkAudio: false });
        ToastsStore.success("Url upload is success!");

        console.log("response: ", self.state.linkRecordList);
      })
      .catch(function(error) {
        // handle error
        self.setState({ uploadingData: false, modalUrlAudio: false });
        self.setState({ urlLinkAudio: false });

        ToastsStore.error("Url isn't uploaded!,");
      });

    return newId;
  } else {
    const formData = new FormData();
    formData.append("audioType", "audioFile");

    for (const key of Object.keys(recordFile)) {
      formData.append("audios[" + key + "]", recordFile[key]);
    }

    let newId = 0;

    axios
      .post(`${apiUrl}/api/record/upload`, formData, header)
      .then((response) => {
        console.log(response.data);
        let audioDataInfo = response.data.data;
        let prevRecordLists = self.state.recordLists;
        let newRecordLists = prevRecordLists.concat(audioDataInfo);

        self.setState({ recordLists: newRecordLists });
        self.setState({ modalrecord: false });
        self.setState({ uploadingData: false });
        ToastsStore.success("Record upload is success!");

        console.log("response: ", self.state.recordLists);
      })
      .catch(function(error) {
        // handle error
        self.setState({ uploadingData: false });
        console.log(error);
        ToastsStore.error("Record isn't uploaded!,");
      });

    return newId;
  }
}

function getRecordData(userId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  self.setState({ loadingRecords: true });
  var recordData = [];
  axios
    .get(`${apiUrl}/api/record/getAll`, header)
    .then((response) => {
      response.data.map((recorditem) =>
        recordData.push({
          id: recorditem.id,
          user_id: recorditem.id,
          primary_user_id: recorditem.primary_user_id,
          filename: recorditem.filename,
          title: recorditem.title,
          updated_at: recorditem.updated_at,
        })
      );

      self.setState({ recordLists: recordData });
      self.setState({ loadingRecords: false });
    })
    .catch(function(error) {
      // handle error
      self.setState({ loadingRecords: false });
    });
}

function getAllMemoriesData(self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  self.setState({ loadingRecords: true });

  axios
    .get(`${apiUrl}/api/memories/getAllMemoriesData`, header)
    .then((response) => {
      let images = response.data.filter((img) => {
        if (img.filetype === "image") {
          return true;
        } else {
          return false;
        }
      });
      let records = response.data.filter((record) => {
        if (record.filetype === "record") {
          return true;
        } else {
          return false;
        }
      });
      let videos = response.data.filter((video) => {
        if (video.filetype === "video") {
          return true;
        } else {
          return false;
        }
      });
      let imageData = [];
      images.map((item) =>
        imageData.push({
          id: item.id,
          user_id: item.user_id,
          primary_user_id: item.primary_user_id,
          filename: item.filename,
          title: item.title,
          description: item.description,
          thumbnail_url: item.thumbnail_url,
          date: item.updated_at.split(" ")[0],
        })
      );
      // console.log("response", response);
      self.setState({ imageLists: imageData });
      self.setState({ loadingImages: false });

      let videoData = [];
      videos.map((videoitem) => {
        videoData.push({
          id: videoitem.id,
          user_id: videoitem.user_id,
          primary_user_id: videoitem.primary_user_id,
          title: videoitem.title,
          description: videoitem.description,
          urlcheck: videoitem.urlcheck,
          thumbnail_url: videoitem.thumbnail_url,
          filename: videoitem.filename,
          date: videoitem.updated_at.split(" ")[0],
        });
      });

      self.setState({ videoLists: videoData });
      self.setState({ loadingVideos: false });

      let recordData = [];
      records.map((recorditem) => {
        recordData.push({
          id: recorditem.id,
          user_id: recorditem.user_id,
          primary_user_id: recorditem.primary_user_id,
          filename: recorditem.filename,
          urlcheck: recorditem.urlcheck,
          thumbnail_url: recorditem.thumbnail_url,
          title: recorditem.title,
          updated_at: recorditem.updated_at,
        });
      });

      self.setState({ recordLists: recordData });
      self.setState({ loadingRecords: false });
    })
    .catch(function(error) {
      // handle error
      self.setState({ loadingRecords: false });
    });
}

function deleteRecordData(selectedItemId) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    id: selectedItemId,
  });

  axios
    .post(`${apiUrl}/api/record/delete/${selectedItemId}`, data, header)
    .then((response) => {
      ToastsStore.success("Audio has been deleted successfully!");
      return true;
    })
    .catch(function(error) {
      // handle error
      return false;
    });

  return true;
}

function searchAll(self, searchText) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    search_text: searchText,
  });

  console.log("service data: ", searchText);
  axios
    .post(`${apiUrl}/api/search/all`, data, header)
    .then((response) => {
      console.log("success on result: ", response);

      if (response.data.status === "success") {
        const memories = response.data.memories; //
        const accounts = response.data.accounts; //acc_name, acc_type
        const beneficiaries = response.data.beneficiaries; //first_name, email
        const medicals = response.data.medicals; //first_name, email
        const letters = response.data.letters; //first_name, email
        self.setState({
          allMemories: memories,
          allAccounts: accounts,
          allBeneficiaries: beneficiaries,
          allMedicals: medicals,
          allLetters: letters,
        });
        if (searchText) {
          searchByTextDesktop(self, "all");
        }
      }
      return true;
    })
    .catch(function(error) {
      // handle error
      return false;
    });

  return true;
}

function getContentDashboard(self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  axios
    .post(`${apiUrl}/api/content-dashboard`, {}, header)
    .then((response) => {
      if (response.data.status === "success") {
        const memories = response.data.memoriesImage; //
        const images = response.data.memoriesImage; //
        const videos = response.data.memoriesVideo; //
        const records = response.data.memoriesRecord; //
        const accounts = response.data.accounts; //
        
        let insurance_count = accounts.filter((acc) => {
          if (acc.acc_type === "insurance") {
            return true;
          } else {
            return false;
          }
        });  
        let financial_count = accounts.filter((acc) => {
          if (acc.acc_type === "financial") {
            return true;
          } else {
            return false;
          }
        });
        let legal_count = accounts.filter((acc) => {
          if (acc.acc_type === "legal") {
            return true;
          } else {
            return false;
          }
        });
      
        self.setState({
          allMemories: memories,
          allMemoriesImages: images,
          allMemoriesVideos: videos,
          allMemoriesRecords: records,
          insurance_count,
          financial_count,
          legal_count,
        });
      }
      return true;
    })
    .catch(function(error) {
      // handle error
      return false;
    });

  return true;
}

function searchByTextDesktop(self, search_type) {
  const allMemories = self.state.allMemories;
  const allAccounts = self.state.allAccounts;
  const allBeneficiaries = self.state.allBeneficiaries;
  const allMedicals = self.state.allMedicals;
  const allLetters = self.state.allLetters;
  const searchText = self.state.searchText;
  let searchList = [];

  if (allMemories.length > 0) {
    let searchMemories = allMemories.filter(
      (itemType) =>
        itemType.title.toLowerCase().includes(searchText.toLowerCase()) ||
        itemType.filetype.toLowerCase().includes(searchText.toLowerCase())
    );
    if (search_type === "memories" || search_type === "all") {
      searchMemories.map((value, inx) => {
        searchList.push({
          id: value.id,
          title: value.title,
          date: value.updated_at,
          type: "memories",
          content_type: value.filetype,
        });
        return false;
      });
    }
  }

  if (search_type === "accounts" || search_type === "all") {
    if (allAccounts.length > 0) {
      let searchAccounts = allAccounts.filter(
        (itemType) =>
          itemType.acc_type.toLowerCase().includes(searchText.toLowerCase()) ||
          itemType.acc_name.toLowerCase().includes(searchText.toLowerCase()) ||
          itemType.acc_description
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );

      searchAccounts.map((value, inx) => {
        searchList.push({
          id: value.id,
          title: value.acc_name + "-" + value.acc_type,
          date: value.updated_at,
          type: "accounts",
        });
        return false;
      });
    }
  }

  if (search_type === "beneficiaries" || search_type === "all") {
    if (allBeneficiaries.length > 0) {
      let searchBeneficiaries = allBeneficiaries.filter(
        (itemType) =>
          itemType.city.toLowerCase().includes(searchText.toLowerCase()) ||
          itemType.email.toLowerCase().includes(searchText.toLowerCase()) ||
          itemType.last_4_beneficiary
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          itemType.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
          itemType.mail_address
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          itemType.mail_address2
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          itemType.state.toLowerCase().includes(searchText.toLowerCase()) ||
          itemType.zip.toLowerCase().includes(searchText.toLowerCase()) ||
          itemType.first_name.toLowerCase().includes(searchText.toLowerCase())
      );

      searchBeneficiaries.map((value, inx) => {
        searchList.push({
          id: value.id,
          title: value.first_name + "-" + value.email,
          date: value.updated_at,
          type: "beneficiaries",
        });
        return false;
      });
    }
  }

  if (search_type === "medicals" || search_type === "all") {
    if (allMedicals.length > 0) {
      let searchMedicals = allMedicals.filter((itemType) =>
        itemType.diagnosis_name.toLowerCase().includes(searchText.toLowerCase())
      );

      searchMedicals.map((value, inx) => {
        searchList.push({
          id: value.id,
          title: value.diagnosis_name,
          date: value.updated_at,
          type: "medicals",
        });
        return false;
      });
    }
  }

  if (
    search_type === "letters" ||
    search_type === "all" ||
    search_type === "memories"
  ) {
    if (allLetters && allLetters.length > 0) {
      let searchLetters = allLetters.filter(
        (itemType) =>
          itemType.subject.toLowerCase().includes(searchText.toLowerCase()) ||
          itemType.description.toLowerCase().includes(searchText.toLowerCase())
      );

      searchLetters.map((value, inx) => {
        searchList.push({
          id: value.id,
          title: value.subject,
          date: value.updated_at,
          type: "letters",
          content_type: "letters",
        });
        return false;
      });
    }
  }

  // self.setState({
  //   listItem: searchList,
  //   listItemAll: searchList,
  // });

  let listOfSearch = searchList;
  const sort_type = self.state.sortType;
  if (sort_type === "by_name") {
    listOfSearch.sort((a, b) => {
      return a.title > b.title ? 1 : a.title === b.title ? 1 : -1;
    });
    self.setState({ listItem: listOfSearch, listItemAll: searchList });
    document.getElementById("chk_by_name").checked = true;
    document.getElementById("chk_by_type").checked = false;
    document.getElementById("chk_last_update").checked = false;
  }

  if (sort_type === "by_type") {
    listOfSearch.sort((a, b) => {
      return a.type > b.type ? 1 : a.type === b.type ? 1 : -1;
    });
    self.setState({ listItem: listOfSearch, listItemAll: searchList });
    document.getElementById("chk_by_name").checked = false;
    document.getElementById("chk_by_type").checked = true;
    document.getElementById("chk_last_update").checked = false;
  }
  if (sort_type === "last_update") {
    listOfSearch.sort((a, b) => {
      return a.date < b.date ? 1 : a.date === b.date ? 1 : -1;
    });
    self.setState({ listItem: listOfSearch, listItemAll: searchList });
    document.getElementById("chk_by_name").checked = false;
    document.getElementById("chk_by_type").checked = false;
    document.getElementById("chk_last_update").checked = true;
  }
}
