import React, { Component } from "react";
import { withRouter } from "react-router-dom"; // Ensure withRouter is imported
import "./styles/homeIndex.css";
import "./styles/memories.css";
import "./styles/featmemoriespage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logoLight from "../pages/images/thisheartweb/logolight.png";
import demoLetterIMg from "../pages/images/thisheartweb/letterdemo.png";
import IndexFooter from "./IndexFooter";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import LeftSidebar from "../components/LeftSidebar";
import { Form, FormGroup, Input, Label, Media } from "reactstrap";
import btnDownload from "../pages/images/download.png";
import zeroState from "../pages/images/memzero.png";
import apiService from "./../services/api.service";
import { memoriesService } from "../services/memories.service";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ContentState, EditorState, convertToRaw, convertFromHTML, Editor } from "draft-js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import deleteIcon from "./images/delete-icon.svg";
import eyeIcon from "./images/eye-icon.svg";
import titleLettersIcon from "./images/title-letters.svg";
import demoLettersImg from "./images/letter_view.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const apiUrl = apiService.apiUrl;
const docDefinition = {
  info: {
    title: "ThisHeart@Team",
    author: "ThisHeart",
    subject: "ThisHeart Letter",
    keywords: "keywords for document",
  },
  content: "",
};

class MemoriesLetters extends Component {
  constructor(props) {
    super(props);

    this.toggleLetterButton = this.toggleLetterButton.bind(this);
    this.toggleLetterViewButton = this.toggleLetterViewButton.bind(this);
    this.toggleLetterEditButton = this.toggleLetterEditButton.bind(this);
    this.toggleDeleteLetter = this.toggleDeleteLetter.bind(this);
    this.deleteLetter = this.deleteLetter.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this); // Ensure binding

    this.state = {
      letterList: [],
      letterListTemp: [],
      loadingLetterCounter: 0,
      activeLetterSearch: "",
      userType: "",
      primary_user_id: "",
      letterView: false,
      letterViewData: {},
      letterEdit: false,
      letterEditData: {},
      letterDelete: false,
      letterDeleteId: "",
      letterDownload: false,
      recordLists: [],
      currentView: 'letters',
      userObj: JSON.parse(localStorage.getItem("user")),
      userType: localStorage.getItem("userType"),
      activeTab: "5",
      user_id: "",
      beneficiary_id: "",
      primary_user_id: "",
      currentPage: 1,
      lettersPerPage: 12,
    };
  }

  componentDidMount() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }

    this.setState({
      user_id: this.state.userObj.data.user_id,
      beneficiary_id: "",
      primary_user_id: this.state.userObj.data.primary_user_id
    });
    const query = new URLSearchParams(this.props.location.search);
    const actions = query.get("actions");
    if (actions === "social") {
      this.setState({
        activeTab: "5",
      });
      this.toggleTab("5");
    }
    if (actions === "image") {
      this.setState({
        activeTab: "1",
      });
      this.toggleTab("1");
    }
    if (actions === "video") {
      this.setState({
        activeTab: "2",
      });
      this.toggleTab("2");
    }
    if (actions === "letters") {
      this.setState({
        activeTab: "4",
      });
      this.toggleTab("4");
    }
    if (actions === "record") {
      this.setState({
        activeTab: "5",
      });
      this.toggleTab("5");
    }
    memoriesService.getLetterData(userId, this);
    memoriesService.getAllMemoriesData(this);
  }

  toggleLetterEditButton() {
    this.setState((prevState) => ({
      editview: !prevState.editview,
      btnActive: false,
    }));
  }

  toggleDeleteLetter(deleteID) {
    this.setState((prevState) => ({
      letterDelete: !prevState.letterDelete,
      letterDeleteId: deleteID,
    }));
  }

  toggleLetterButton() {
    this.setState((prevState) => ({
      modalletter: !prevState.modalletter,
    }));
  }

  handleBackClick() {
    if (this.props.history.length > 1) {
      this.props.history.push('/memories/U2FsdGVkX18RZvEdBUui3jRae9OaZ4AvLdg7VsGbDkY%3D');
    } else {
        this.props.history.push('/memories/U2FsdGVkX18RZvEdBUui3jRae9OaZ4AvLdg7VsGbDkY%3D');
    }
  }

  deleteLetter = () => {
    const tempLetterID = this.state.letterDeleteId; // Use letterDeleteId instead of deleteID
    const getLetterLists = this.state.letterListTemp
    let successDel = memoriesService.deleteLetterData(tempLetterID);


    if (successDel) {
      const letterLists = getLetterLists.filter(
        (letter) => letter.id !== tempLetterID
      );
      this.setState({
        letterListTemp: letterLists,
        letterDelete: false,
        letterList: letterLists,
      });
      const { currentPage, lettersPerPage } = this.state;
      const totalLetters = letterLists.length;
      const totalPage = Math.ceil(totalLetters / lettersPerPage)
      if(currentPage<totalPage){
        
      }else{
        this.setState({currentPage:totalPage})
      }
      console.log('total Page:::, ', totalPage)
      
 
    } else {
      window.alert("Delete Failed");
    }
  }

  pdfGenerate = (content) => {
    docDefinition.content = content.subTxt + "\n\t" + content.desTxt.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");
    pdfMake.createPdf(docDefinition).download("ThisHeart - " + content.subTxt);
  };

  toggleLetterViewButton() {
    this.setState((prevState) => ({
      preview: !prevState.preview,
    }));
  }

  letterPopView = (e) => {
    this.setState({
      letterViewData: e,
    });
    this.toggleLetterViewButton();
  };

  letterPopEdit = (e, i) => {
    let desc = e.desTxt;
    this.setState({
      errorDescription: "",
      editIndex: i,
      editID: e.id,
      editSubject: e.subTxt,
      editDescription: desc,
      editorEditState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(desc))),
    });
    this.toggleLetterEditButton();
  };

  truncateText = (htmlContent, charLimit) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    const textContent = div.textContent || div.innerText || "";

    if (textContent.length > charLimit) {
      return `${textContent.slice(0, charLimit)}...`;
    } else {
      return textContent;
    }
  };

  setViewToAudios = () =>{
    return false
  }

  showLetters = () => {
    const letterListTemp = this.state.letterListTemp;
    const { lettersPerPage, currentPage } = this.state;
    const indexOfLastLetter = currentPage * lettersPerPage;
    const indexOfFirstLetter = indexOfLastLetter - lettersPerPage;
    const currentLetters = letterListTemp.slice(indexOfFirstLetter, indexOfLastLetter);
  
    return (
      <div className="cards-container">
        {currentLetters.length > 0 ? (
          currentLetters.map((letteritem, i) => (
            
            <div key={i} className="letter-card">
              <div>
                <div className="row">
                  <div className="col-2">
                    <img className="img-fluid" src={titleLettersIcon} alt="titleLettersIcon" />
                  </div>
                  <div className="col-9">
                    <h5 dangerouslySetInnerHTML={{ __html: this.truncateText(letteritem.subTxt, 32) }}/>
                  </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: this.truncateText(letteritem.subTxt, 100) }}/>
              </div>
              <div className="overlay">
                <button className="letter-button" onClick={() => this.letterPopView(letteritem)}>
                  <img src={eyeIcon} alt="View Letters" />
                </button>
                {(this.state.primary_user_id !== letteritem.user_id || this.state.user_id == letteritem.user_id ) && (
                  <>
                    <button className="letter-button" onClick={() => this.toggleDeleteLetter(letteritem.id)}>
                      <img src={deleteIcon} alt="Delete Letters" />
                    </button>
                  </>
                )}
            
              </div>
            </div>
          ))
        ) : (
          <div className="audio-card-placeholder" onClick={() => this.setViewToAudios()}>
            <div className="audio-thumbnail-placeholder">
              <img className="img-fluid" src={demoLettersImg} alt="demoLettersImg" />
            </div>
          </div>
        )}
      </div>
    );
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  }

  renderPagination = (totalLetters, lettersPerPage) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalLetters / lettersPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${this.state.currentPage === number ? 'active' : ''}`}>
              <button
                onClick={() => this.handlePageChange(number)}
                className="page-link"
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  }

  render() {
    const { letterListTemp, lettersPerPage, letteritem } = this.state;
    const totalLetters = letterListTemp.length;

    return (
      <div className="home-container" id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Letters</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="container-fluid mt-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6">
                  <h1>Letters</h1>
                </div>
                <div className="col-6">
                  <div className="d-flex col-lg-11 justify-content-end align-items-center go-back-padding-letter">
                    <button type="button" className="btn add-btn-mem mt-2 mr-3 shadow-sm" onClick={this.handleBackClick}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left mr-1" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                      </svg>
                      <span><span className="go-back-text">Go</span> Back </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {this.showLetters()}
            {totalLetters > lettersPerPage && this.renderPagination(totalLetters, lettersPerPage)}
          </div>
        </div>
        <div>
          <Modal isOpen={this.state.preview} className={`${this.props.className} modal-lg`}>
            <ModalHeader toggle={this.toggleLetterViewButton}>View Letter</ModalHeader>
            <ModalBody className="form-popup">
              <Form className="mem-letter">
                <Label>
                  <b> Subject: </b> <br />
                </Label>
                <p dangerouslySetInnerHTML={{ __html: this.state.letterViewData.subTxt }} />
                <br />
                <Label>
                  <b> Description: </b> <br />
                </Label>
                <p dangerouslySetInnerHTML={{ __html: this.state.letterViewData.desTxt }} />
              </Form>
              <Button style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }} type="button" onClick={this.toggleLetterViewButton}>
                Close
              </Button>
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={this.state.editview}
            toggle={this.toggleLetterEditButton}
            className={this.props.className}
            size="lg"
          >
            <ModalHeader toggle={this.toggleLetterEditButton}>
              Edit Letter
            </ModalHeader>
            <ModalBody className="form-popup">
              <Form
                method="post"
                onSubmit={this.saveChanges}
                className="form-container-letter"
              >
                <FormGroup className="required">
                  <Label className="letter-lbl control-label">
                    <b>Subject:</b>
                  </Label>
                  <Input
                    type="text"
                    name="subj"
                    id="subj"
                    required
                    value={this.state.editSubject}
                    onChange={this.onChangeSubject}
                  />
                  {this.state.errorSubjectEdit ? (
                    <div className="alert alert-danger">
                      {this.state.errorSubjectEdit}
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>

                <FormGroup className="required">
                  <Label className="view-label control-label">
                    {" "}
                    Description:{" "}
                  </Label>
                  <Editor
                    editorState={this.state.editorEditState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    name="desc"
                    id="desc"
                    required
                    onEditorStateChange={this.onChangeDescription}
                  />

                  {this.state.errorDescriptionEdit ? (
                    <div className="alert alert-danger">
                      {this.state.errorDescriptionEdit}
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>
                <Button
                  className="btn-right"
                  type="submit"
                  disabled={this.state.btnActive === false}
                  style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                >
                  Save changes
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={this.state.letterDelete} // Changed to this.state.letterDelete
            toggle={this.toggleDeleteLetter}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteLetter}>
              Memories Letter
            </ModalHeader>
            <ModalBody className="form-popup">
              <p>
                Are you sure you want to delete this letter? You won't be able
                to get it back after you do.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deleteLetter}
                style={{ borderRadius: "7px" }}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={this.toggleDeleteLetter}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(MemoriesLetters); // Ensure the component is wrapped with withRouter